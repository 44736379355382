/*
      ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ██╗
    ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗██║
   ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║██║
  ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║██║
 ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║███████╗
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
*/
body {
	background: url('../images/backgrounds/body-bg.png');
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

/*
     ██████╗ ██╗   ██╗████████╗████████╗ ██████╗ ███╗   ██╗███████╗
    ██╔══██╗██║   ██║╚══██╔══╝╚══██╔══╝██╔═══██╗████╗  ██║██╔════╝
   ██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔██╗ ██║███████╗
  ██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╗██║╚════██║
 ██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚████║███████║
╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

.btn {
	i[class*="fa-"]{
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
}

/*
      ██████╗██╗   ██╗███████╗████████╗ ██████╗ ███╗   ███╗    ███████╗███████╗██╗     ███████╗ ██████╗████████╗
    ██╔════╝██║   ██║██╔════╝╚══██╔══╝██╔═══██╗████╗ ████║    ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝
   ██║     ██║   ██║███████╗   ██║   ██║   ██║██╔████╔██║    ███████╗█████╗  ██║     █████╗  ██║        ██║
  ██║     ██║   ██║╚════██║   ██║   ██║   ██║██║╚██╔╝██║    ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║
 ╚██████╗╚██████╔╝███████║   ██║   ╚██████╔╝██║ ╚═╝ ██║    ███████║███████╗███████╗███████╗╚██████╗   ██║
 ╚═════╝ ╚═════╝ ╚══════╝   ╚═╝    ╚═════╝ ╚═╝     ╚═╝    ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝
*/

.custom-select {
	background-size: 0.875rem 0.875rem;
	background-position-x: right 0;
}

.custom-control {
	&-label {
		text-transform: capitalize;
		color: $input-color;
	}
}

/*
     ██╗███╗   ██╗██████╗ ██╗   ██╗████████╗███████╗
    ██║████╗  ██║██╔══██╗██║   ██║╚══██╔══╝██╔════╝
   ██║██╔██╗ ██║██████╔╝██║   ██║   ██║   ███████╗
  ██║██║╚██╗██║██╔═══╝ ██║   ██║   ██║   ╚════██║
 ██║██║ ╚████║██║     ╚██████╔╝   ██║   ███████║
╚═╝╚═╝  ╚═══╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝
*/

.input-group{
	&-append{
		.input-group-text {
			margin-left: 1px;
		}
	}
	&-prepend{
		.input-group-text {
			margin-right: 1px;
		}
	}
}
input.form-control,
input.form-float-control {
	&::placeholder{
		text-transform: capitalize;
	}
}
textarea.form-float-control {
	@extend .customized-scroll;
	max-height: 140px;
	overflow-y: scroll;
	overflow-x: hidden;
}

/*
      ██████╗  █████╗ ██████╗  ██████╗ ███████╗███████╗
     ██╔══██╗██╔══██╗██╔══██╗██╔════╝ ██╔════╝██╔════╝
    ██████╔╝███████║██║  ██║██║  ███╗█████╗  ███████╗
   ██╔══██╗██╔══██║██║  ██║██║   ██║██╔══╝  ╚════██║
  ██████╔╝██║  ██║██████╔╝╚██████╔╝███████╗███████║
 ╚═════╝ ╚═╝  ╚═╝╚═════╝  ╚═════╝ ╚══════╝╚══════╝
*/

.badge{
	font-weight: $font-weight-normal;
	&.badge-lg{
		font-size: 95%;
		font-weight: $font-weight-bold;
	}
	&.badge-sm{
		font-size: 50%;
		font-weight: $font-weight-light;
	}
}

/*
          ██╗ █████╗ ██████╗  █████╗ ██╗     ██╗      █████╗ ██╗  ██╗    ██████╗ ██╗     ██╗   ██╗ ██████╗ ██╗███╗   ██╗
         ██║██╔══██╗██╔══██╗██╔══██╗██║     ██║     ██╔══██╗╚██╗██╔╝    ██╔══██╗██║     ██║   ██║██╔════╝ ██║████╗  ██║
        ██║███████║██████╔╝███████║██║     ██║     ███████║ ╚███╔╝     ██████╔╝██║     ██║   ██║██║  ███╗██║██╔██╗ ██║
  ██   ██║██╔══██║██╔══██╗██╔══██║██║     ██║     ██╔══██║ ██╔██╗     ██╔═══╝ ██║     ██║   ██║██║   ██║██║██║╚██╗██║
 ╚█████╔╝██║  ██║██║  ██║██║  ██║███████╗███████╗██║  ██║██╔╝ ██╗    ██║     ███████╗╚██████╔╝╚██████╔╝██║██║ ╚████║
 ╚════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝    ╚═╝     ╚══════╝ ╚═════╝  ╚═════╝ ╚═╝╚═╝  ╚═══╝
*/

.jarallax {
	position: relative;
	z-index: 0;
	&-img {
		position: absolute;
		object-fit: cover;
		/* support for plugin https://github.com/bfred-it/object-fit-images */
		font-family: 'object-fit: cover;';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		opacity: 0.3;
	}
}

/*
      ██████╗  ██████╗  ██████╗  ██████╗ ██╗     ███████╗    ███╗   ███╗ █████╗ ██████╗
    ██╔════╝ ██╔═══██╗██╔═══██╗██╔════╝ ██║     ██╔════╝    ████╗ ████║██╔══██╗██╔══██╗
   ██║  ███╗██║   ██║██║   ██║██║  ███╗██║     █████╗      ██╔████╔██║███████║██████╔╝
  ██║   ██║██║   ██║██║   ██║██║   ██║██║     ██╔══╝      ██║╚██╔╝██║██╔══██║██╔═══╝
 ╚██████╔╝╚██████╔╝╚██████╔╝╚██████╔╝███████╗███████╗    ██║ ╚═╝ ██║██║  ██║██║
 ╚═════╝  ╚═════╝  ╚═════╝  ╚═════╝ ╚══════╝╚══════╝    ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝
*/

.pac{
	&-container {
		z-index: $zindex-fixed;
		@include border-radius($dropdown-border-radius);
		padding: 0 0 1rem 0;
		font-family: inherit !important;
		border-top: none !important;
		@include box-shadow($box-shadow-sm !important);
	}
	&-item{
		padding: 0 1rem;
	}
	&-logo{
		&:after{
			background-position-x: 1rem;
		}
	}
}


/*
     ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗
    ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝
   ██╔████╔██║██║   ██║██║  ██║███████║██║     ███████╗
  ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ╚════██║
 ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████║
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝
*/
.modal{
	&-body{
		.list-group{
			@extend .customized-scroll;
			max-height: 55vh;
			overflow-y: scroll;
		}
	}
	&-footer{
		button{
			flex: 1;
		}
	}
}

/*
     ████████╗ █████╗ ██████╗ ██╗     ███████╗███████╗
    ╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝██╔════╝
      ██║   ███████║██████╔╝██║     █████╗  ███████╗
     ██║   ██╔══██║██╔══██╗██║     ██╔══╝  ╚════██║
    ██║   ██║  ██║██████╔╝███████╗███████╗███████║
   ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝╚══════╝
*/
.table{
	&-responsive {
		@extend .customized-scroll;
		overflow-x: scroll;
	}
}


/*
      ████████╗ █████╗ ██████╗     ███╗   ██╗ █████╗ ██╗   ██╗███████╗
     ╚══██╔══╝██╔══██╗██╔══██╗    ████╗  ██║██╔══██╗██║   ██║██╔════╝
       ██║   ███████║██████╔╝    ██╔██╗ ██║███████║██║   ██║███████╗
      ██║   ██╔══██║██╔══██╗    ██║╚██╗██║██╔══██║╚██╗ ██╔╝╚════██║
     ██║   ██║  ██║██████╔╝    ██║ ╚████║██║  ██║ ╚████╔╝ ███████║
    ╚═╝   ╚═╝  ╚═╝╚═════╝     ╚═╝  ╚═══╝╚═╝  ╚═╝  ╚═══╝  ╚══════╝

*/
nav {
	margin-bottom: 1.5rem;
	.nav[role="tablist"] {
		background-color: transparent;
		width: fit-content;
		border: $border-width solid $primary;
		@include border-radius($card-border-radius);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@include media-breakpoint-only(md) {
			width: 100%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
		.nav-item{
			@include border-radius(0);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.25rem 1.25rem;
			font-family: inherit;
			font-size: $font-size-sm;
			color: $primary;
			text-transform: capitalize;
			background-color: transparent;

			&:first-of-type {
				@include border-left-radius($card-border-radius);
			}

			&:last-of-type {
				@include border-right-radius($card-border-radius);
			}
			&,
			&:hover,
			&.active {
				border: 0;
			}
			&:hover,
			&.active {
				background-color: $primary;
				color: $white;
			}
			@include media-breakpoint-only(md) {
				flex: 1;
			}

			@include media-breakpoint-down(xs) {
				flex: 1;
			}
		}
	}
}

.tab-content {
	.tab-pane {
		.card-footer {
			&:before {
				content: none !important;
			}
		}
	}
}


/*
      ███████╗██╗    ██╗██╗██████╗ ███████╗██████╗
     ██╔════╝██║    ██║██║██╔══██╗██╔════╝██╔══██╗
    ███████╗██║ █╗ ██║██║██████╔╝█████╗  ██████╔╝
   ╚════██║██║███╗██║██║██╔═══╝ ██╔══╝  ██╔══██╗
  ███████║╚███╔███╔╝██║██║     ███████╗██║  ██║
 ╚══════╝ ╚══╝╚══╝ ╚═╝╚═╝     ╚══════╝╚═╝  ╚═╝
*/
.related-slider {
	.swiper-pagination {
		bottom: 0;
	}
}


#systemPagesListing-content {
	.card{
		&-header{
			.dropdown, .dropleft{
				.dropdown-menu{
					transform: translate3d(-140px, 24px, 0) #{'/*rtl: translate3d(0, 24px, 0)*/'} !important;
				}
			}
		}
	}
}
.divIsHidden {
	display: none;
}
.inputFocused {
    z-index: 999 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: .875rem !important;
    color: rgba(0,0,0,0.4) !important;
    border: 0 !important;
    border-radius: 0 !important;
    width: calc(100% - 0.75em) !important;
    text-align: left !important;
    background-color: transparent !important;
}

.custom-lang-select {
    width: 100%;
    height: 44px;
    border-radius: 0.75rem;
    padding: 0px 16px;
    background-color: #EFF1F6;
    outline: none;
    border-color: #DFE0E1ff;
    -moz-appearance: none;
    -webkit-appearance: none;
	position: relative;
	color: #7D7D7E;

}

.custom-lang-select option {
	background-color: #EFF1F6;
	color: #7D7D7E;
    padding: .5rem;
}

.custom-lang-arrow {
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 16px;
	color: #7D7D7E;
}

.ar-item {
    display: none;
}

.stickyCard{
	position: sticky!important;
    z-index: 1020;
    top: 8rem;
}

.row .col-12 .card-package-vertical{
	height: 100%;
}

.swiper-slide iframe {
	margin: 0 auto;
    width: 80%!important;
    display: block;
}