#information-content{
	ul, ol{padding-left:1rem;}
	p, img{margin-bottom: 0.75rem;}
	p, ul li, ol li {
		font-size: $font-size-base;
		color: $dark;
		font-weight: $font-weight-normal;
		line-height: $line-height-lg;
	}
	h5{
		font-weight: $font-weight-bold;
		text-transform: capitalize;
		color: $black;
		font-size:1rem;
	}
	img{border: $border-width solid $black;}
}