@mixin badge-variant($bg) {
	color: color-yiq($bg);
	background-color: $bg;

	&[href] {
		@include hover-focus {
			color: color-yiq($bg);
			text-decoration: none;
			background-color: darken($bg, 10%);
		}
	}
}

@mixin badge-outline-variant($bg) {
	color: $bg;
	border: $border-width solid $bg;
	background-color: transparent;

	&[href] {
		@include hover-focus {
			color: $bg;
			text-decoration: none;
			border: $border-width solid darken($bg, 10%);
			background-color: transparent;
		}
	}
}
