/*
     ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗      ██████╗██╗      █████╗ ███████╗███████╗███████╗███████╗
    ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗    ██╔════╝██║     ██╔══██╗██╔════╝██╔════╝██╔════╝██╔════╝
   ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝    ██║     ██║     ███████║███████╗███████╗█████╗  ███████╗
  ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗    ██║     ██║     ██╔══██║╚════██║╚════██║██╔══╝  ╚════██║
 ██║  ██║███████╗███████╗██║     ███████╗██║  ██║    ╚██████╗███████╗██║  ██║███████║███████║███████╗███████║
╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝     ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝╚══════╝
*/

.rtl{
	text-align: right #{'/*rtl:left*/'};
	direction: rtl #{'/*rtl:ltr*/'};
	font-family: $font-family-ar;
}
.customized-scroll {
	&::-webkit-scrollbar {
		width: 0.75em;
		height: 0.75em;
		@include border-radius($border-radius-sm);
	}

	&::-webkit-scrollbar-track {@include box-shadow(none);}

	&::-webkit-scrollbar-thumb {
		background-color: rgba($border-color, 0.2);
		background-clip: padding-box;
		border: 3px solid transparent;
		@include border-radius($border-radius-sm);
	}

	&::-webkit-scrollbar-button {
		width: 0;
		height: 0;
		display: none;
	}

	&::-webkit-scrollbar-corner {background-color: transparent;}
}

.description-content {
	@extend .customized-scroll;
	max-height: 150px;
	overflow-y: scroll;
	p.card-text{
		white-space: pre-wrap;
		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}
}

.loading{
	position: relative;
	&:before, &:after{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		@include border-radius($card-border-radius);
	}
	&:before{
		content: '';
		background-color: $white;
		z-index: 1001;
	}

	&:after{
		content: '';
		z-index: 1002;
		background-image: url('../images/preloader.gif');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 30%;
	}
	&.card{
		&:before{ @include border-radius($card-border-radius) }
	}
	&.btn{
		background-color: $white !important;
		border-color: $gray-100 !important;
		pointer-events: none;
	}
}
