body {
	background-color: #FFFFFF;
	color: #000000;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 1.3;
	scrollbar-3dlight-color: #F0F0EE;
	scrollbar-arrow-color: #676662;
	scrollbar-base-color: #F0F0EE;
	scrollbar-darkshadow-color: #DDDDDD;
	scrollbar-face-color: #E0E0DD;
	scrollbar-highlight-color: #F0F0EE;
	scrollbar-shadow-color: #F0F0EE;
	scrollbar-track-color: #F5F5F5
}

td,
th {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 14px
}

.word-wrap {
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto
}

.mce-content-body .mce-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	vertical-align: top;
	background: transparent;
	text-decoration: none;
	color: black;
	font-family: Arial;
	font-size: 11px;
	text-shadow: none;
	float: none;
	position: static;
	width: auto;
	height: auto;
	white-space: nowrap;
	cursor: inherit;
	line-height: normal;
	font-weight: normal;
	text-align: left;
	-webkit-tap-highlight-color: transparent;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	direction: ltr;
	max-width: none
}

.mce-object {
	border: 1px dotted #3A3A3A;
	background: #D5D5D5 url(../images/icons/object.gif) no-repeat center
}

.mce-preview-object {
	display: inline-block;
	position: relative;
	margin: 0 2px 0 2px;
	line-height: 0;
	border: 1px solid gray
}

.mce-preview-object[data-mce-selected="2"] .mce-shim {
	display: none
}

.mce-preview-object .mce-shim {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7)
}

figure.align-left {
	float: left
}

figure.align-right {
	float: right
}

figure.image.align-center {
	display: table;
	margin-left: auto;
	margin-right: auto
}

figure.image {
	display: inline-block;
	border: 1px solid gray;
	margin: 0 2px 0 1px;
	background: #f5f2f0
}

figure.image img {
	margin: 8px 8px 0 8px
}

figure.image figcaption {
	margin: 6px 8px 6px 8px;
	text-align: center
}

.mce-toc {
	border: 1px solid gray
}

.mce-toc h2 {
	margin: 4px
}

.mce-toc li {
	list-style-type: none
}

.mce-pagebreak {
	cursor: default;
	display: block;
	border: 0;
	width: 100%;
	height: 5px;
	border: 1px dashed #666;
	margin-top: 15px;
	page-break-before: always
}

@media print {
	.mce-pagebreak {
		border: 0
	}
}

.mce-item-anchor {
	cursor: default;
	display: inline-block;
	-webkit-user-select: all;
	-webkit-user-modify: read-only;
	-moz-user-select: all;
	-moz-user-modify: read-only;
	user-select: all;
	user-modify: read-only;
	width: 9px !important;
	height: 9px !important;
	border: 1px dotted #3A3A3A;
	background: #D5D5D5 url(../images/icons/anchor.gif) no-repeat center
}

.mce-nbsp,
.mce-shy {
	background: #AAA
}

.mce-shy:after {
	content: '-'
}

.mce-match-marker {
	background: #AAA;
	color: #fff
}

.mce-match-marker-selected {
	background: #3399ff;
	color: #fff
}

.mce-spellchecker-word {
	border-bottom: 2px solid rgba(208, 2, 27, 0.5);
	cursor: default
}

.mce-spellchecker-grammar {
	border-bottom: 2px solid #008000;
	cursor: default
}

.mce-item-table,
.mce-item-table td,
.mce-item-table th,
.mce-item-table caption {
	border: 1px dashed #BBB
}

td[data-mce-selected],
th[data-mce-selected] {
	background-color: #2276d2 !important
}

.mce-edit-focus {
	outline: 1px dotted #333
}

.mce-content-body *[contentEditable=false] *[contentEditable=true]:focus {
	outline: 2px solid #2276d2
}

.mce-content-body *[contentEditable=false] *[contentEditable=true]:hover {
	outline: 2px solid #2276d2
}

.mce-content-body *[contentEditable=false][data-mce-selected] {
	outline: 2px solid #2276d2
}

.mce-content-body.mce-content-readonly *[contentEditable=true]:focus,
.mce-content-body.mce-content-readonly *[contentEditable=true]:hover {
	outline: none
}

.mce-content-body *[data-mce-selected="inline-boundary"] {
	background: #bfe6ff
}

.mce-content-body .mce-item-anchor[data-mce-selected] {
	background: #D5D5D5 url(../images/icons/anchor.gif) no-repeat center
}

.mce-content-body hr {
	cursor: default
}

.mce-content-body table {
	-webkit-nbsp-mode: normal
}

.ephox-snooker-resizer-bar {
	background-color: #2276d2;
	opacity: 0
}

.ephox-snooker-resizer-cols {
	cursor: col-resize
}

.ephox-snooker-resizer-rows {
	cursor: row-resize
}

.ephox-snooker-resizer-bar.ephox-snooker-resizer-bar-dragging {
	opacity: .2
}