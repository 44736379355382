.file-upload{
	&-preview, &-info{
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
	}
	&-preview{
		justify-content: space-between;
		position: relative;
		&:not(:last-of-type){
			margin-bottom: $grid-gutter-width/2;
		}
	}
	&-info{
		justify-content: flex-start;
		max-width: 75%;
	}
	&-img{
		@include border-radius($border-radius-sm/3);
		min-width: 40px;
		width: 40px;
		height: 40px;
		object-fit: cover;
		margin-right: 0.4375rem;
		background-color: transparent;
		align-self: flex-start;
	}
	&-attatch{
		min-width: 40px;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.4375rem;
		background-color: $light;
		@include border-radius($border-radius-sm/3);
		i {
			color: $primary;
			font-size: $font-size-base;
		}
	}
	&-name, &-remove span, &-ext {
		color: $danger;
		font-size: $font-size-sm;
	}
	&-name,
	&-ext{
		font-weight: $font-weight-light;
		text-transform: lowercase;
		text-align: left;
		margin-bottom: 0;
	}
	&-ext{
		min-width: 30%;
	}
	&-name {
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 100%;
		white-space: nowrap;
	}
	&-remove, &-view{
		max-width: 25%;
		span, a {
			cursor: pointer;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			text-align: right;
		}
	}
	&-label {
		font-weight: $font-weight-light;
		margin-bottom: 0;
		font-size: $font-size-base;
		color: $input-color;
		font-weight: $font-weight-normal;
		text-transform: capitalize;
	}

	@include media-breakpoint-down(md) {
		&-remove{
			max-width: 50%;
		}
		&-info{
			max-width: 50%;
		}
	}
}

.custom-file-input,
.custom-file-label {
	cursor: pointer
}

.custom-file-label {
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	padding-left: 1.875rem;
	padding-top: 0;
	padding-bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	z-index: 3;
	color: $danger;
}

.custom-file-label:first-of-type:before {
	@extend .material-icons;
	content: 'attachment';
	color: inherit;
	font-size: 1.5rem;
	position: absolute;
	left: 0
}

.custom-file-input {
	z-index: 2
}
