#servicesSingle-content{
	#service-working-hours {
		@extend .px-4;
		position: relative;
		.swiper-button-prev,
		.swiper-container-rtl .swiper-button-next,
		.swiper-button-next,
		.swiper-container-rtl .swiper-button-prev{
			width: 0.625rem;
			height: 0.875rem;
			margin: 0;
			background-size: 0.625rem 0.875rem;
			transform: translateY(-50%);
		}
		.swiper-button-prev,
		.swiper-container-rtl .swiper-button-next{
			left: 0;
		}
		.swiper-button-next,
		.swiper-container-rtl .swiper-button-prev{
			right: 0;
		}
	}
}