//*********************************  Material Inputs  *********************************//
.was-validated {
	.form-float-label-group {

		input.form-float-control,
		input.form-float-control,
		textarea.form-float-control,
		textarea.form-float-control,
		select.selectize-select {

			&:after,
			&:invalid {
				content: '';
				background: str-replace(url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve' width='512px' height='512px'%3E%3Cg%3E%3Cg%3E%3Cpath d='M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249 C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306 C514.019,27.23,514.019,14.135,505.943,6.058z' fill='#A71212'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636 c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z' fill='%23D80027'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A"), "#", "%23") right 0 bottom 1rem no-repeat !important;
				background-size: .6rem !important;
				border: 0;
				z-index: 1000;
				display: block;
				float: right;
				height: 100%;
				width: 100%;
				border-bottom: $border-width solid $danger;
			}

			&,
			.custom-select {

				&:after,
				&:valid {
					content: '';
					background: str-replace(url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 70 70' style='enable-background:new 0 0 70 70;' xml:space='preserve' width='512px' height='512px'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M26.474,70c-2.176,0-4.234-1.018-5.557-2.764L3.049,43.639C0.725,40.57,1.33,36.2,4.399,33.875 c3.074-2.326,7.441-1.717,9.766,1.35l11.752,15.518L55.474,3.285c2.035-3.265,6.332-4.264,9.604-2.232 c3.268,2.034,4.266,6.334,2.23,9.602l-34.916,56.06c-1.213,1.949-3.307,3.175-5.6,3.279C26.685,69.998,26.58,70,26.474,70z' fill='#0A9515'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A"), "#", "%23") right 0 bottom 1rem no-repeat !important;
					background-size: .6rem !important;
					border: 0;
					z-index: 1000;
					display: block;
					float: right;
					height: 100%;
					width: 100%;
					border-bottom: $border-width solid $success;
				}
			}
		}
	}
}

.custom-control-input.is-valid:not(:required):not(:checked)~.custom-control-label:before,
.was-validated .custom-control-input:not(:required):not(:checked):valid~.custom-control-label:before {
	background-color: transparent !important
}

.custom-control-input.is-valid:not(:required):checked~.custom-control-label:before,
.was-validated .custom-control-input:not(:required):checked:valid~.custom-control-label:before {
	background-color: $danger !important
}

.custom-control-input.is-valid:not(:required)~.custom-control-label,
.form-check-input.is-valid:not(:required)~.form-check-label,
.was-validated .custom-control-input:not(:required):valid~.custom-control-label,
.was-validated .form-check-input:not(:required):valid~.form-check-label {
	color: rgba(0, 0, 0, .6) !important
}


.form-float-label-group>input.form-float-control,
.form-float-label-group>label,
.form-float-label-group>textarea.form-float-control {
	padding: 1.5rem 0 .5rem;
	border: 0;
	border-bottom: $border-width solid $border-color;
	outline: 0 !important;
	background-color: transparent;
	@include border-radius(0);
	@include box-shadow(none !important);
	resize: none
}

.needs-validation .form-float-label-input.form-float-control.is-invalid:after,
.needs-validation .form-float-label-input.form-float-control.is-invalid:invalid,
.needs-validation .form-float-label-textarea.form-float-control .custom-select.is-invalid:after,
.needs-validation .form-float-label-textarea.form-float-control .custom-select.is-invalid:invalid,
.needs-validation .form-float-label-group input.form-float-control.is-invalid:after,
.needs-validation .form-float-label-group input.form-float-control.is-invalid:valid,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-invalid:after,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-invalid:valid,
.needs-validation .form-float-label-group input.form-float-control.is-valid:after,
.needs-validation .form-float-label-group input.form-float-control.is-valid:invalid,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-valid:after,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-valid:invalid,
.needs-validation .form-float-label-group input.form-float-control.is-valid:after,
.needs-validation .form-float-label-group input.form-float-control.is-valid:valid,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-valid:after,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-valid:valid{
	content: '';
	background-size: .6rem !important;
	border: 0;
	z-index: 1000;
	display: block;
	float: right;
	height: 100%;
	width: 100%;
}


.needs-validation .form-float-label-input.form-float-control.is-invalid:after,
.needs-validation .form-float-label-input.form-float-control.is-invalid:invalid,
.needs-validation .form-float-label-textarea.form-float-control .custom-select.is-invalid:after,
.needs-validation .form-float-label-textarea.form-float-control .custom-select.is-invalid:invalid,
.needs-validation .form-float-label-group input.form-float-control.is-invalid:after,
.needs-validation .form-float-label-group input.form-float-control.is-invalid:valid,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-invalid:after,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-invalid:valid {
	background: str-replace(url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve' width='512px' height='512px'%3E%3Cg%3E%3Cg%3E%3Cpath d='M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249 C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306 C514.019,27.23,514.019,14.135,505.943,6.058z' fill='#A71212'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636 c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z' fill='%23D80027'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A"), "#", "%23") right 0 bottom 1rem no-repeat !important;
	border-bottom: $border-width solid $danger !important;
}

.needs-validation .form-float-label-group input.form-float-control.is-valid:after,
.needs-validation .form-float-label-group input.form-float-control.is-valid:invalid,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-valid:after,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-valid:invalid,
.needs-validation .form-float-label-group input.form-float-control.is-valid:after,
.needs-validation .form-float-label-group input.form-float-control.is-valid:valid,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-valid:after,
.needs-validation .form-float-label-group textarea.form-float-control .custom-select.is-valid:valid {
	background: str-replace(url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 70 70' style='enable-background:new 0 0 70 70;' xml:space='preserve' width='512px' height='512px'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M26.474,70c-2.176,0-4.234-1.018-5.557-2.764L3.049,43.639C0.725,40.57,1.33,36.2,4.399,33.875 c3.074-2.326,7.441-1.717,9.766,1.35l11.752,15.518L55.474,3.285c2.035-3.265,6.332-4.264,9.604-2.232 c3.268,2.034,4.266,6.334,2.23,9.602l-34.916,56.06c-1.213,1.949-3.307,3.175-5.6,3.279C26.685,69.998,26.58,70,26.474,70z' fill='#0A9515'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A"), "#", "%23") right 0 bottom 1rem no-repeat !important;
	border-bottom: $border-width solid $success !important;
}

.needs-validation .form-float-label-group textarea.form-float-control.is-invalid:after,
.needs-validation .form-float-label-group textarea.form-float-control.is-invalid:before,
.needs-validation .form-float-label-group textarea.form-float-control.is-invalid:invalid,
.needs-validation .form-float-label-group textarea.form-float-control.is-invalid:valid,
.needs-validation .form-float-label-group textarea.form-float-control.is-valid:after,
.needs-validation .form-float-label-group textarea.form-float-control.is-valid:before,
.needs-validation .form-float-label-group textarea.form-float-control.is-valid:invalid,
.needs-validation .form-float-label-group textarea.form-float-control.is-valid:valid {
	padding: 1.5rem 0 .9rem;
}

.form-float-label-group {position: relative; margin-bottom: 1.5rem}

.form-float-label-group .invalid-feedback,
.form-float-label-group .valid-feedback {
	margin-bottom: -11px;
	text-transform: lowercase;
	font-weight: $font-weight-normal;
	font-size: $font-size-sm;
}

.was-validated .form-float-label-group.rtl input.form-float-control:invalid,
.was-validated .form-float-label-group.rtl input.form-float-control:valid {
	padding-top: .8rem
}

.form-float-label-group>.form-float-control.inputSlotPicker,
.form-float-label-group>.form-float-control.inputTimePicker,
.form-float-label-group>.form-float-control.inputServiceTimePickerFrom,
.form-float-label-group>.form-float-control.inputServiceTimePickerTo,
.form-float-label-group>.form-float-control#formInput_daysOffPerYear,
.form-float-label-group select.custom-select {
	@include dropdown-arrow($gray-500);
	position: relative;
	width: 100%;
	border-bottom: $border-width solid $border-color;
	outline: 0 !important;
	@include border-radius(0);
	@include box-shadow(none !important);
	padding: 1.5rem 0 .5rem;
	height: 100%;
	background-position-y: 23px !important;
	// background-position-x: right !important;
	transition: $transition-base;
	// fix edge
	@supports (-ms-ime-align: auto) {
		//background:#393939!important ;
		
	}
}

// .form-float-label-group>input.form-float-control:disabled,
// .form-float-label-group>label:disabled,
// .form-float-label-group>textarea.form-float-control:disabled,
// .form-float-label-group>.form-float-control.inputSlotPicker:disabled,
// .form-float-label-group>.form-float-control.inputTimePicker:disabled,
// .form-float-label-group>.form-float-control.inputServiceTimePickerFrom:disabled,
// .form-float-label-group>.form-float-control.inputServiceTimePickerTo:disabled,
// .form-float-label-group select.custom-select:disabled{
// 	border-bottom-color: transparent !important;
// }

.form-float-label-group input.form-float-control#formInput_daysOffPerYear:not(:placeholder-shown) {
	background: none !important;
}

.form-float-label-group>.form-float-control.inputSlotPicker,
.form-float-label-group>.form-float-control.inputSlotPicker>option,
.form-float-label-group>.form-float-control.inputTimePicker,
.form-float-label-group>.form-float-control.inputTimePicker>option,
.form-float-label-group select.custom-select,
.form-float-label-group select.custom-select option {
	text-transform: none;
	color: rgba($input-color, .6)
}

.form-float-label-group select.custom-select option {
	// fix edge
	@supports (-ms-ime-align: auto) {	
		background: white;
		color : #393939;
		
		&:hover,&:focus,&:active {
			background: #0078D7;
			color : white;
		}
	}
}


// for ie browser only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.form-float-label-group select.custom-select option{
		background: white;
		color: black!important;
	}
	
}

.form-float-label-group select.custom-select {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		//background: rgba(0,0,0,0) !important;
		
	}
}

.form-float-label-group>.form-float-control.inputSlotPicker:before,
.form-float-label-group>.form-float-control.inputTimePicker:before,
.form-float-label-group select.custom-select:before {
	content: attr(data-label);
	font-size: .75rem;
	color: rgba($input-color, .4);
	display: block;
	padding-bottom: 5px;
	width: 100%;
	background-color: $white;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1090;
	text-transform: capitalize;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.form-float-label-group>label,
.form-float-label-group select.custom-select[value=""]:valid~label {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	margin-bottom: 0;
	line-height: 1.5;
	color: rgba($input-color, .6);
	cursor: text;
	border: 0 !important;
	@include border-radius(.25rem);
	-webkit-transition: all .1s ease-in-out;
	transition: all .1s ease-in-out;
	text-transform: capitalize;
	font-size: $font-size-base;
	padding-top: 1.2rem;
	font-weight: $font-weight-normal;
	text-align: left;
	font-family: inherit;
	background-color: transparent;
	@extend .text-truncate;
	i.material-icons{
		margin: 0 0.5rem;
		cursor: help;
	}
}

.form-float-label-group input.form-float-control.rtl ~ label,
.form-float-label-group textarea.form-float-control.rtl ~ label,
.form-float-label-group select.custom-select.rtl[value=""]:valid~label{
	text-align: right;
	font-family: $font-family-ar;
	left: initial;
	right: 0;
}

.form-float-label-group input.form-float-control::-webkit-input-placeholder,
.form-float-label-group textarea.form-float-control::-webkit-input-placeholder {
	color: transparent
}

.form-float-label-group input.form-float-control:-ms-input-placeholder,
.form-float-label-group textarea.form-float-control:-ms-input-placeholder {
	color: transparent
}

.form-float-label-group input.form-float-control::-ms-input-placeholder,
.form-float-label-group textarea.form-float-control::-ms-input-placeholder {
	color: transparent
}

.form-float-label-group input.form-float-control::placeholder,
.form-float-label-group textarea.form-float-control::placeholder {
	color: transparent
}

// fix edge 
@supports (-ms-ime-align: auto) {
	.form-float-label-group input.form-float-control::placeholder,
	.form-float-label-group textarea.form-float-control::placeholder {
	color: rgba(0,0,0,0)!important;
	}
}

.form-float-label-group input.form-float-control:not(:placeholder-shown),
.form-float-label-group textarea.form-float-control:not(:placeholder-shown) {
	padding: 1.5rem 0 .5rem
}

.form-float-label-group input.form-float-control:not(:placeholder-shown)~label,
.form-float-label-group textarea.form-float-control:not(:placeholder-shown)~label,
.form-float-label-group textarea.form-float-control:focus ~ label,
.form-float-label-group select.custom-select:not([value=""]):valid ~ label {
	z-index: $zindex-dropdown - 1;
	padding-top: 0;
	padding-bottom: 0;
	font-size: $font-size-sm;
	color: rgba($input-color, .4);
	border: 0;
	@include border-radius(0);
	background: $white;
	width: calc(100% - 0.75em); // width 100 - scroll bar width from .customized-scroll class
	text-align: left;
	background-color: transparent;
}

.form-float-label-group textarea.form-float-control:not(:placeholder-shown)~label,
.form-float-label-group textarea.form-float-control:focus ~label {
	background-color: $white !important;
	width: calc(100% - 0.75em); // width 100 - scroll bar width from .customized-scroll class
}

.form-float-label-group input.form-float-control.rtl:not(:placeholder-shown)~label,
.form-float-label-group textarea.form-float-control.rtl:not(:placeholder-shown)~label,
.form-float-label-group textarea.form-float-control.rtl:focus ~ label,
.form-float-label-group select.custom-select.rtl:not([value=""]):valid~label {
	text-align: right;
}

@media (max-width:991.98px) {

	.form-float-label-group input.form-float-control:not(:placeholder-shown)~label,
	.form-float-label-group textarea.form-float-control:not(:placeholder-shown)~label,
	.form-float-label-group textarea.form-float-control:focus ~label {
		font-size: $font-size-sm;
	}
}

@supports (-ms-ime-align:auto) {
	/*
	.form-float-label-group>label {
		display: none
	}
	*/
	.form-float-label-group input.form-float-control::-ms-input-placeholder,
	.form-float-label-group textarea.form-float-control::-ms-input-placeholder {
		color: rgba($black, .6)
	}
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
	/*
	.form-float-label-group>label {
		display: none
	}
	*/
	.form-float-label-group input:-ms-input-placeholder,
	.form-float-label-group textarea:-ms-input-placeholder {
		color: rgba($black, .7)
	}
}

.tag-secondary {
	background-color: transparent;
	color: rgba($black, .6) !important;
	border: $border-width solid $border-color
}

.custom-file-input:lang(ar)~.custom-file-label:after,
.custom-file-input:lang(en)~.custom-file-label:after {
	content: none
}

.custom-file-input.is-invalid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
	@include box-shadow(none !important);
	@include border-radius(0);
	border-color: transparent !important
}

#profile-info>div:not(:last-of-type),
.card-social-icon:not(:last-of-type) {
	border-right: $border-width solid $border-color
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0
}

input[type=number] {
	-moz-appearance: textfield
}


//*********************************  Overwriting bootstrap forms  *********************************//
.form-row>[class*=col-] {
	@include media-breakpoint-up(lg) {
		padding-right: $grid-gutter-width/2;
		padding-left: $grid-gutter-width/2;
	}
}

.selectize-control.selectize-select {
	@supports (-ms-ime-align: auto) {
		& { -ms-overflow-style: none!important; }
		
	}
	position: relative;

	&:after {
		@include dropdown-arrow($input-color);
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
}

.navbar-search .selectize-input:after,
.navbar-search .selectize-control.selectize-select:after,
.home-main .selectize-control.selectize-select:after {
	content: none;
}


//*********************************  Material Switch  *********************************//
// https://bootsnipp.com/snippets/featured/material-design-switch
.material-switch {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&-input {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 50%;

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	&-title {
		font-family: inherit;
		font-size: $font-size-base;
		text-align: left;
		text-transform: capitalize;
		font-weight: $font-weight-light;
		color: rgba(0, 0, 0, .6);
	}

	&-checkbox {
		&>input[type="checkbox"] {
			display: none;

			&:checked+label:before {
				background: transparent;
				opacity: 0.5;
			}

			&:checked+label:after {
				background: transparent;
				left: 20px;
			}
		}

		&>label {
			cursor: pointer;
			height: 0px;
			position: relative;
			width: 40px;

			&:before {
				background: $black;
				@include box-shadow(inset 0px 0px 10px rgba($black, 0.5));
				@include border-radius(8px);
				content: '';
				height: 16px;
				margin-top: -8px;
				position: absolute;
				opacity: 0.3;
				transition: all 0.4s ease-in-out;
				width: 40px;
			}

			&:after {
				background: $white;
				@include border-radius(16px);
				@include box-shadow(0px 0px 5px rgba($black, 0.3));
				content: '';
				height: 24px;
				left: -4px;
				margin-top: -8px;
				position: absolute;
				top: -4px;
				transition: $transition-base;
				width: 24px;
			}
		}
	}
}


.form-float-label-group .selectize-select ~ label {
	background-color: rgba(0,0,0,0)!important;
}

.form-float-label-group .selectize-select ~ label.label_Bg_Color {
	background-color: #fff!important;
}


// fix edge
@supports (-ms-ime-align: auto) {
	input.form-float-control,
		input.form-float-control,
		textarea.form-float-control,
		textarea.form-float-control,
		select.selectize-select { -ms-overflow-style: none!important; } 
	
}