.selectize-control.plugin-drag_drop.multi>.selectize-input>div.ui-sortable-placeholder {
	visibility: visible !important;
	background: #f2f2f2 !important;
	background: rgba($black, 0.06) !important;
	border: 0 none !important;
	@include box-shadow(inset 0 0 12px 4px $white);
}

.selectize-control.plugin-drag_drop .ui-sortable-placeholder:after {
	content: '!';
	visibility: hidden;
}

.selectize-control.plugin-drag_drop .ui-sortable-helper {
	@include box-shadow(0 2px 5px rgba($black, 0.2));
}

.selectize-dropdown-header {
	position: relative;
	padding: 5px 8px;
	border-bottom: $border-width solid $border-color;
	background: #f8f8f8;
	@include border-radius(3px 3px 0 0);
}

.selectize-dropdown-header-close {
	position: absolute;
	right: 8px;
	top: 50%;
	color: $dark;
	opacity: 0.4;
	margin-top: -12px;
	line-height: 20px;
	font-size: $font-size-lg !important;
}

.selectize-dropdown-header-close:hover {
	color: $black;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup {
	border-right: $border-width solid $border-color;
	border-top: 0 none;
	float: left;
	box-sizing: border-box;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
	border-right: 0 none;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
	display: none;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
	border-top: 0 none;
}

.selectize-control.plugin-remove_button [data-value] {
	position: relative;
	padding-right: 24px !important;
}

.selectize-control.plugin-remove_button [data-value] .remove {
	z-index: 1;
	/* fixes ie bug (see #392) */
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 17px;
	text-align: center;
	font-weight: $font-weight-bold;
	font-size: $font-size-sm;
	color: inherit;
	text-decoration: none;
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 5px 2px 5px;
	border-left: 0;
	@include border-radius(0 2px 2px 0);
	box-sizing: border-box;
}
.selectize-control.plugin-remove_button [data-value] .remove:hover {
	background: rgba($black, 0.05);
}

.selectize-control.plugin-remove_button [data-value].active .remove {
	border-left-color: $primary-dark;
}

.selectize-control.plugin-remove_button .disabled [data-value] .remove:hover {
	background: none;
}

.selectize-control.plugin-remove_button .disabled [data-value] .remove {
	border-left-color: $border-color;
}

.selectize-control.plugin-remove_button .remove-single {
	position: absolute;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
	font-size: $font-size-lg;
}

.selectize-control {
	position: relative;
	// z-index: $zindex-dropdown;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
	color: $input-color;
	font-family: inherit;
	font-size: $font-size-base;
	line-height: $line-height-sm;
	-webkit-font-smoothing: inherit;
}

.selectize-input,
.selectize-control.single .selectize-input.input-active {
	background: $white;
	cursor: text;
	display: inline-block;
}

.selectize-input {
	border: 0;
	border-bottom: $border-width solid $border-color;
	padding: $input-btn-padding-y*3 2em $input-btn-padding-y 0;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	position: relative;
	z-index: 1;
	box-sizing: border-box;
	@include box-shadow(none);
	@include border-radius(0);
	max-height: 60px;
	overflow-y: scroll;
	overflow-x: hidden;
	@extend .customized-scroll;

	@include media-breakpoint-down(sm) {
		max-height: 200px;
	}
}

.selectize-control.form-control:not(.form-float-control){
	padding: 0;
	@include border-radius($input-border-radius !important);
	.selectize-input{
		border: 0;
		@extend .form-control;
		background-color: transparent;
	}
	&.multi{
		.selectize-input{
			&.has-items {
				max-height: initial;
				@extend .p-1;
				&:before {
					content: none;
				}
			}
		}
	}
}

.selectize-control.multi .selectize-input.has-items {
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	padding-top: 22px;
	// &:before{
	// 	content: '';
	// 	font-size: $font-size-sm;
	// 	color: $gray-400;
	// 	display: block;
	// 	padding-bottom: 5px;
	// 	width: 95%;
	// 	background-color: $white;
	// 	position: sticky;
	// 	top: 0;
	// 	left: 0;
	// 	z-index: 1090;
	// 	text-transform: capitalize;
	// 	transition: $transition-base;
	// }
}

.selectize-input.full {
	background-color: transparent;
}

.selectize-input.disabled,
.selectize-input.disabled * {
	cursor: default !important;
}

.selectize-input.focus {
	@include box-shadow(none);
}

.selectize-input.dropdown-active {
	@include border-radius(3px 3px 0 0);
}

.selectize-input>* {
	vertical-align: baseline;
	display: -moz-inline-stack;
	display: inline-block;
	zoom: 1;
	*display: inline;
}

.selectize-control.multi .selectize-input>div {
	cursor: pointer;
	margin: 0 3px 3px 0;
	padding: 2px 8px;
	background: transparent;
	color: $white;
	border: $border-width solid $border-color;
}

.selectize-control.multi .selectize-input>div.active {
	background: transparent;
	color: $input-color;
	border: $border-width solid $border-color;
}

.selectize-control.multi .selectize-input.disabled>div,
.selectize-control.multi .selectize-input.disabled>div.active {
	color: $white;
	background: #d2d2d2;
	border: $border-width solid $border-color;
}

.selectize-input>input {
	display: inline-block !important;
	padding: 0 !important;
	min-height: 0 !important;
	max-height: none !important;
	max-width: 100% !important;
	margin: 0 1px !important;
	text-indent: 0 !important;
	border: 0 none !important;
	background: none !important;
	line-height: inherit !important;
	-webkit-user-select: auto !important;
	@include box-shadow(none !important);
}

.selectize-input>input::-ms-clear {
	display: none;
}

.selectize-input>input:focus {
	outline: none !important;
}

.selectize-input:after {
	content: ' ';
	display: block;
	clear: left;
}

// .selectize-input.dropdown-active:before {
// 	content: ' ';
// 	display: block;
// 	position: absolute;
// 	background: #f0f0f0;
// 	height: 1px;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// }

.selectize-dropdown {
	position: absolute;
	z-index: $zindex-sticky;
	border: $border-width solid $border-color;
	background: $white;
	margin: -1px 0 0 0;
	box-sizing: border-box;
	@include box-shadow($box-shadow-sm);
	@include border-radius($dropdown-border-radius);
	max-height: 150px;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 0 !important;
	@extend .customized-scroll;
}

.selectize-dropdown [data-selectable] {
	cursor: pointer;
	overflow: hidden;
}

.selectize-dropdown [data-selectable] .highlight {
	background: rgba($primary, 0.2);
	@include border-radius($border-radius-sm/2);
}

.selectize-dropdown .option,
.selectize-dropdown .optgroup-header {
	padding: 5px 8px;
}

.selectize-dropdown .option,
.selectize-dropdown [data-disabled],
.selectize-dropdown [data-disabled] [data-selectable].option {
	cursor: inherit;
	opacity: 0.5;
}

.selectize-dropdown [data-selectable].option {
	opacity: 1;
	cursor: pointer;
}

.selectize-dropdown .optgroup:first-child .optgroup-header {
	border-top: 0 none;
}

.selectize-dropdown .optgroup-header {
	color: $dark;
	background: $white;
	cursor: default;
}

.selectize-dropdown .active {
	background-color: $primary;
	color: $white;
}

.selectize-dropdown .active.create {
	color: $dark;
}

.selectize-dropdown .create {
	color: rgba($dark, 0.5);
}

.selectize-dropdown-content {
	// overflow-y: auto;
	// overflow-x: hidden;
	// max-height: 200px;
	// -webkit-overflow-scrolling: touch;
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
	cursor: pointer;
}

.selectize-control.single .selectize-input.input-active,
.selectize-control.single .selectize-input.input-active input {
	cursor: text;
}

.selectize-control.form-control.form-float-control.single .selectize-input {
	// content: ' ';
	display: block;
	position: absolute;
	// top: 50%;
	@include dropdown-arrow($border-color);
	position: relative;
	width: 100%;
	border-bottom: $border-width solid $border-color;
	outline: none !important;
	background-color: transparent;
	@include border-radius(0);
	@include box-shadow(none !important);
	height: 100%;
	background-position-y: center !important;
	background-position-x: right !important;
}
.selectize-control.form-control.single:not(.form-float-control) .selectize-input  {
	display: block;
	position: absolute;
	position: relative;
	background: none;
	width: 100%;
	border-bottom: 0;
	outline: none !important;
	background-color: transparent;
	@include border-radius($input-border-radius);
	@include box-shadow(none !important);
	height: 100%;
	background-position-y: center !important;
	background-position-x: right !important;
}

.selectize-control.single .selectize-input.dropdown-active:after {
	margin-top: -4px;
	border-width: 0 5px 5px 5px;
	border-color: transparent transparent $border-color transparent;
}

.selectize-control.rtl.single .selectize-input:after {
	left: 15px #{'/*rtl:ignore*/'};
	right: auto #{'/*rtl:ignore*/'};
}

.selectize-control.rtl .selectize-input{
	text-align: right #{'/*rtl:ignore*/'};
	direction: rtl #{'/*rtl:ignore*/'};
}

.selectize-control.rtl .selectize-input>input {
	margin: 0 4px 0 -2px #{'/*rtl:ignore*/'} !important;
}

.selectize-control .selectize-input.disabled {
	opacity: 0.5;
	background-color: #fafafa;
}

// .selectize-control.multi .selectize-input.has-items {
// 	padding-left: 5px;
// 	padding-right: 5px;
// }

.selectize-control.multi .selectize-input.disabled [data-value] {
	color: #999;
	text-shadow: none;
	background: none;
	@include box-shadow(none);
}

.selectize-control.multi .selectize-input.disabled [data-value],
.selectize-control.multi .selectize-input.disabled [data-value] .remove {
	border-color: $border-color;
}

.selectize-control.multi .selectize-input.disabled [data-value] .remove {
	background: none;
}

.selectize-control.multi .selectize-input [data-value] {
	text-shadow: none;
	@include border-radius($border-radius-lg);
	background-color: transparent;
	background-image: transparent;
	background-repeat: repeat-x;
	@include box-shadow(none);
	overflow: hidden;
	color: $secondary;
}

.selectize-control.multi .selectize-input [data-value].active {
	background-color: transparent;
	background-image: transparent;
	background-repeat: repeat-x;
	color: $secondary;
}

.selectize-control.single .selectize-input {
	@include box-shadow(none);
	background-color: transparent;
	// background-image: linear-gradient(to bottom, #fefefe, #f2f2f2);
	background-repeat: repeat-x;
}

.selectize-control.single .selectize-input,
.selectize-dropdown.single {
	border-color: $border-color;
}
// .selectize-dropdown.single{
// 	padding-right: 5px !important;
// }

.selectize-dropdown .optgroup-header {
	padding-top: 7px;
	font-weight: $font-weight-bold;
	font-size: $font-size-sm;
}

.selectize-dropdown .optgroup {
	border-top: $border-width solid $border-color;
}

.selectize-dropdown .optgroup:first-child {
	border-top: 0 none;
}

.selectize-control.form-control.form-float-control {
	background-color: transparent;
	@include border-radius(0);
	border: 0px;
	padding: 0px;
}
.selectize-input{
	// width: 100% !important;
	input{
		// width: inherit !important;
		&::placeholder{
			font-family: inherit;
			text-transform: capitalize;
			color: $input-color;
			font-weight: $font-weight-normal;
			font-size: $font-size-base * 0.95;
		}
	}

}
label[for="formInput_categoryTags-selectized"] {
	z-index: 10;
}

.selectize-control .selectize-input:root #formInput_categoryTags-selectized:root ~label[for="formInput_categoryTags-selectized"] {
	background: $green !important;
}
