// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


 /*
       ██████╗ ██████╗ ██╗      ██████╗ ██████╗     ███████╗██╗   ██╗███████╗████████╗███████╗███╗   ███╗
     ██╔════╝██╔═══██╗██║     ██╔═══██╗██╔══██╗    ██╔════╝╚██╗ ██╔╝██╔════╝╚══██╔══╝██╔════╝████╗ ████║
    ██║     ██║   ██║██║     ██║   ██║██████╔╝    ███████╗ ╚████╔╝ ███████╗   ██║   █████╗  ██╔████╔██║
   ██║     ██║   ██║██║     ██║   ██║██╔══██╗    ╚════██║  ╚██╔╝  ╚════██║   ██║   ██╔══╝  ██║╚██╔╝██║
  ╚██████╗╚██████╔╝███████╗╚██████╔╝██║  ██║    ███████║   ██║   ███████║   ██║   ███████╗██║ ╚═╝ ██║
  ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═╝    ╚══════╝   ╚═╝   ╚══════╝   ╚═╝   ╚══════╝╚═╝     ╚═╝
  all theme color variables
 */

$lighten-percentage: 10%;
$darken-percentage : 20%;
$white      : #fff !default;
$black      : #000 !default;
$white-light: #F5F5F5 !default;
$gray       : #bebebe !default;
$gray-dark  : darken($gray, $darken-percentage) !default;
$gray-100   : rgba($black, 0.1) !default;
$gray-200   : rgba($black, 0.2) !default;
$gray-300   : rgba($black, 0.3) !default;
$gray-400   : rgba($black, 0.4) !default;
$gray-500   : rgba($black, 0.5) !default;
$gray-600   : rgba($black, 0.6) !default;
$gray-700   : rgba($black, 0.7) !default;
$gray-800   : rgba($black, 0.8) !default;
$gray-900   : rgba($black, 0.9) !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge((
	"100": $gray-100,
	"200": $gray-200,
	"300": $gray-300,
	"400": $gray-400,
	"500": $gray-500,
	"600": $gray-600,
	"700": $gray-700,
	"800": $gray-800,
    "900": $gray-900
),$grays);

$blue              : #43A8DE !default;
$blue-light        : lighten($blue, $lighten-percentage) !default;
$blue-dark         : darken($blue, $darken-percentage) !default;
$indigo            : #6610f2 !default;
$indigo-light      : lighten($indigo, $lighten-percentage) !default;
$indigo-dark       : darken($indigo, $darken-percentage) !default;
$purple            : #6f42c1 !default;
$purple-light      : lighten($purple, $lighten-percentage) !default;
$purple-dark       : darken($purple, $darken-percentage) !default;
$pink              : #e83e8c !default;
$pink-light        : lighten($pink, $lighten-percentage) !default;
$pink-dark         : darken($pink, $darken-percentage) !default;
$red               : #DE4343 !default;
$red-light         : lighten($red, $lighten-percentage) !default;
$red-dark          : darken($red, $darken-percentage) !default;
$orange            : #FAA634 !default;
$orange-light      : lighten($orange, $lighten-percentage) !default;
$orange-dark       : darken($orange, $darken-percentage) !default;
$yellow            : rgb(187, 187, 5) !default;
$yellow-light      : lighten($yellow, $lighten-percentage) !default;
$yellow-dark       : darken($yellow, $darken-percentage) !default;
$yellow-stars      : #F0CD09 !default;
$yellow-stars-light: lighten($yellow-stars, $lighten-percentage) !default;
$yellow-stars-dark : darken($yellow-stars, $darken-percentage) !default;
$green             : #0A9515 !default;
$green-light       : lighten($green, $lighten-percentage) !default;
$green-dark        : darken($green, $darken-percentage) !default;
$teal              : #20c997 !default;
$teal-light        : lighten($teal, $lighten-percentage) !default;
$teal-dark         : darken($teal, $darken-percentage) !default;
$cyan              : #24DAE2 !default;
$cyan-light        : lighten($cyan, $lighten-percentage) !default;
$cyan-dark         : darken($cyan, $darken-percentage) !default;
$brown             : #362C2C !default;
$brown-light       : lighten($brown, $lighten-percentage) !default;
$brown-dark        : darken($brown, $darken-percentage) !default;

// social
$facebook       : #3b5998;
$facebook-light : lighten($facebook, $lighten-percentage);
$facebook-dark  : darken($facebook, $darken-percentage);
$twitter        : #1DA1F2;
$twitter-light  : lighten($twitter, $lighten-percentage);
$twitter-dark   : darken($twitter, $darken-percentage);
$google         : #ea4335;
$google-light   : lighten($google, $lighten-percentage);
$google-dark    : darken($google, $darken-percentage);
$instagram      : #517fa4;
$instagram-light: lighten($instagram, $lighten-percentage);
$instagram-dark : darken($instagram, $darken-percentage);
$linked-in      : #0077b5;
$linked-in-light: lighten($linked-in, $lighten-percentage);
$linked-in-dark : darken($linked-in, $darken-percentage);

// bar rating
// Bars
$orange-color: $blue;
$green-color: $green;
$blue-color: $blue;
// Stars
$star-default: $gray-200;
$star-active: $blue;
$star-selected: $blue;


$colors: (
	"blue"              : $blue,
	"blue-light"        : $blue-light,
	"blue-dark"         : $blue-dark,
	"indigo"            : $indigo,
	"indigo-light"      : $indigo-light,
	"indigo-dark"       : $indigo-dark,
	"purple"            : $purple,
	"purple-light"      : $purple-light,
	"purple-dark"       : $purple-dark,
	"pink"              : $pink,
	"pink-light"        : $pink-light,
	"pink-dark"         : $pink-dark,
	"red"               : $red,
	"red-light"         : $red-light,
	"red-dark"          : $red-dark,
	"orange"            : $orange,
	"orange-light"      : $orange-light,
	"orange-dark"       : $orange-dark,
	"yellow"            : $yellow,
	"yellow-light"      : $yellow-light,
	"yellow-dark"       : $yellow-dark,
	"yellow-stars"      : $yellow-stars,
	"yellow-stars-light": $yellow-stars-light,
	"yellow-stars-dark" : $yellow-stars-dark,
	"green"             : $green,
	"green-light"       : $green-light,
	"green-dark"        : $green-dark,
	"teal"              : $teal,
	"teal-light"        : $teal-light,
	"teal-dark"         : $teal-dark,
	"cyan"              : $cyan,
	"cyan-light"        : $cyan-light,
	"cyan-dark"         : $cyan-dark,
	"brown"             : $brown,
	"brown-light"       : $brown-light,
	"brown-dark"        : $brown-dark,
	"facebook"          : $facebook,
	"facebook-light"    : $facebook-light,
	"facebook-dark"     : $facebook-dark,
	"twitter"           : $twitter,
	"twitter-light"     : $twitter-light,
	"twitter-dark"      : $twitter-dark,
	"google"            : $google,
	"google-light"      : $google-light,
	"google-dark"       : $google-dark,
	"instagram"         : $instagram,
	"instagram-light"   : $instagram-light,
	"instagram-dark"    : $instagram-dark,
	"linked-in"         : $linked-in,
	"linked-in-light"   : $linked-in-light,
	"linked-in-dark"    : $linked-in-dark,
	"white"             : $white,
	"gray"              : darken($gray, $darken-percentage),
	"gray-dark"         : darken($gray, $darken-percentage)
) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge((
	"blue"     : $blue,
	"indigo"   : $indigo,
	"purple"   : $purple,
	"pink"     : $pink,
	"red"      : $red,
	"orange"   : $orange,
	"yellow"   : $yellow,
	"green"    : $green,
	"teal"     : $teal,
	"cyan"     : $cyan,
	"white"    : $white,
	"gray"     : $gray-600,
	"gray-dark": $gray-800
), $colors);

$primary        : $blue !default;
$primary-light  : lighten($primary, $lighten-percentage) !default;
$primary-dark   : #123D53 !default;
$secondary      : $gray-600 !default;
$secondary-light: lighten($secondary, $lighten-percentage) !default;
$secondary-dark : darken($secondary, $darken-percentage) !default;
$success        : $green !default;
$success-light  : lighten($success, $lighten-percentage) !default;
$success-dark   : darken($success, $darken-percentage) !default;
$info           : $cyan !default;
$info-light     : lighten($info, $lighten-percentage) !default;
$info-dark      : darken($info, $darken-percentage) !default;
$warning        : $yellow !default;
$warning-light  : lighten($warning, $lighten-percentage) !default;
$warning-dark   : darken($warning, $darken-percentage) !default;
$danger         : $red !default;
$danger-light   : lighten($danger, $lighten-percentage) !default;
$danger-dark    : darken($danger, $darken-percentage) !default;
$light          : $gray-100 !default;
$dark           : #2C2C2C !default;

$theme-colors: (
	"primary"        : $primary,
	"primary-light"  : $primary-light,
	"primary-dark"   : $primary-dark,
	"secondary"      : $secondary,
	"secondary-light": $secondary-light,
	"secondary-dark" : $secondary-dark,
	"gray"           : $secondary,
	"gray-light"     : $secondary-light,
	"gray-dark"      : $secondary-dark,
	"success"        : $success,
	"success-light"  : $success-light,
	"success-dark"   : $success-dark,
	"info"           : $info,
	"info-light"     : $info-light,
	"info-dark"      : $info-dark,
	"warning"        : $warning,
	"warning-light"  : $warning-light,
	"warning-dark"   : $warning-dark,
	"green"          : $success,
	"green-light"    : $success-light,
	"green-dark"     : $success-dark,
	"yellow"         : $yellow,
	"yellow-light"   : $yellow-light,
	"yellow-dark"    : $yellow-dark,
	"blue"           : $blue,
	"blue-light"     : $blue-light,
	"blue-dark"      : $blue-dark,
	"indigo"         : $indigo,
	"indigo-light"   : $indigo-light,
	"indigo-dark"    : $indigo-dark,
	"purple"         : $purple,
	"purple-light"   : $purple-light,
	"purple-dark"    : $purple-dark,
	"pink"           : $pink,
	"pink-light"     : $pink-light,
	"pink-dark"      : $pink-dark,
	"red"            : $red,
	"red-light"      : $red-light,
	"red-dark"       : $red-dark,
	"danger"         : $danger,
	"danger-light"   : $danger-light,
	"danger-dark"    : $danger-dark,
	"teal"           : $teal,
	"teal-light"     : $teal-light,
	"teal-dark"      : $teal-dark,
	"brown"          : $brown,
	"brown-light"    : $brown-light,
	"brown-dark"     : $brown-dark,
	"facebook"       : $facebook,
	"facebook-light" : $facebook-light,
	"facebook-dark"  : $facebook-dark,
	"twitter"        : $twitter,
	"twitter-light"  : $twitter-light,
	"twitter-dark"   : $twitter-dark,
	"google"         : $google,
	"google-light"   : $google-light,
	"google-dark"    : $google-dark,
	"instagram"      : $instagram,
	"instagram-light": $instagram-light,
	"instagram-dark" : $instagram-dark,
	"linked-in"      : $linked-in,
	"linked-in-light": $linked-in-light,
	"linked-in-dark" : $linked-in-dark,
	"light"          : $light,
	"dark"           : $dark,
	"black"          : $black,
	"white"          : $white
) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge((
	"primary"  : $primary,
	"secondary": $secondary,
	"success"  : $success,
	"info"     : $info,
	"warning"  : $warning,
	"danger"   : $danger,
	"light"    : $light,
	"dark"     : $dark
),$theme-colors);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 1% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 170 !default;
// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark : $gray-900 !default;
$yiq-text-light: $white !default;




/*
      ██████╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
    ██╔═══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
   ██║   ██║██████╔╝   ██║   ██║██║   ██║██╔██╗ ██║███████╗
  ██║   ██║██╔═══╝    ██║   ██║██║   ██║██║╚██╗██║╚════██║
 ╚██████╔╝██║        ██║   ██║╚██████╔╝██║ ╚████║███████║
 ╚═════╝ ╚═╝        ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
 Quickly modify global styling by enabling or disabling optional features.
*/
$enable-caret            : true !default;
$enable-rounded          : true !default;
$enable-shadows          : false !default; // TODO make all cards inherit shadow from this variable, using box-shadow() mixin.
$enable-gradients        : false !default; // TODO make all background colors inherit gradients color from gradient-bg() mixin.
$enable-transitions      : true !default;
$enable-hover-media-query: false !default;  // Deprecated, no longer affects any compiled CSS
$enable-grid-classes     : true !default;
$enable-print-styles     : true !default;
$enable-debug            : false !default;




/*
     ███████╗██████╗  █████╗  ██████╗██╗███╗   ██╗ ██████╗
    ██╔════╝██╔══██╗██╔══██╗██╔════╝██║████╗  ██║██╔════╝
   ███████╗██████╔╝███████║██║     ██║██╔██╗ ██║██║  ███╗
  ╚════██║██╔═══╝ ██╔══██║██║     ██║██║╚██╗██║██║   ██║
 ███████║██║     ██║  ██║╚██████╗██║██║ ╚████║╚██████╔╝
╚══════╝╚═╝     ╚═╝  ╚═╝ ╚═════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 Control the default styling of most Bootstrap elements by modifying these variables. Mostly focused on spacing.
 You can add more entries to the $spacers map, should you need more variation.
*/

$spacer : 1rem !default;
$spacers: (
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	6: ($spacer * 5),
	7: ($spacer * 8),
	gutter: 2rem
) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3)
),$spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
	0 : 0,
	5   : 5%,
	10  : 10%,
	15  : 15%,
	20  : 20%,
	25  : 25%,
	30  : 30%,
	35  : 35%,
	40  : 40%,
	45  : 45%,
	50  : 50%,
	55  : 55%,
	60  : 60%,
	65  : 65%,
	70  : 70%,
	75  : 75%,
	80  : 80%,
	85  : 85%,
	90  : 90%,
	95  : 95%,
	100 : 100%,
	auto: auto
) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge((
	25  : 25%,
	50  : 50%,
	75  : 75%,
	100 : 100%,
	auto: auto
), $sizes);




/*
     ██████╗  ██████╗ ██████╗ ██╗   ██╗
    ██╔══██╗██╔═══██╗██╔══██╗╚██╗ ██╔╝
   ██████╔╝██║   ██║██║  ██║ ╚████╔╝
  ██╔══██╗██║   ██║██║  ██║  ╚██╔╝
 ██████╔╝╚██████╔╝██████╔╝   ██║
╚═════╝  ╚═════╝ ╚═════╝    ╚═╝
 Settings for the `<body>` element.
*/

$body-bg   : $white-light !default;
$body-color: $black !default;




/*
     ██╗     ██╗███╗   ██╗██╗  ██╗███████╗
    ██║     ██║████╗  ██║██║ ██╔╝██╔════╝
   ██║     ██║██╔██╗ ██║█████╔╝ ███████╗
  ██║     ██║██║╚██╗██║██╔═██╗ ╚════██║
 ███████╗██║██║ ╚████║██║  ██╗███████║
╚══════╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚══════╝
 Style anchor elements.
*/

$link-color           : theme-color("primary") !default;
$link-decoration      : none !default;
$link-hover-color     : darken($link-color, $darken-percentage) !default;
$link-hover-decoration: none !default;




/*
     ██████╗  █████╗ ██████╗  █████╗  ██████╗ ██████╗  █████╗ ██████╗ ██╗  ██╗███████╗
    ██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝ ██╔══██╗██╔══██╗██╔══██╗██║  ██║██╔════╝
   ██████╔╝███████║██████╔╝███████║██║  ███╗██████╔╝███████║██████╔╝███████║███████╗
  ██╔═══╝ ██╔══██║██╔══██╗██╔══██║██║   ██║██╔══██╗██╔══██║██╔═══╝ ██╔══██║╚════██║
 ██║     ██║  ██║██║  ██║██║  ██║╚██████╔╝██║  ██║██║  ██║██║     ██║  ██║███████║
╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝  ╚═╝╚══════╝
 Style p element.
*/

$paragraph-margin-bottom: 0 !default;




/*
     ██████╗ ██████╗ ██╗██████╗     ██████╗ ██████╗ ███████╗ █████╗ ██╗  ██╗██████╗  ██████╗ ██╗███╗   ██╗████████╗███████╗
    ██╔════╝ ██╔══██╗██║██╔══██╗    ██╔══██╗██╔══██╗██╔════╝██╔══██╗██║ ██╔╝██╔══██╗██╔═══██╗██║████╗  ██║╚══██╔══╝██╔════╝
   ██║  ███╗██████╔╝██║██║  ██║    ██████╔╝██████╔╝█████╗  ███████║█████╔╝ ██████╔╝██║   ██║██║██╔██╗ ██║   ██║   ███████╗
  ██║   ██║██╔══██╗██║██║  ██║    ██╔══██╗██╔══██╗██╔══╝  ██╔══██║██╔═██╗ ██╔═══╝ ██║   ██║██║██║╚██╗██║   ██║   ╚════██║
 ╚██████╔╝██║  ██║██║██████╔╝    ██████╔╝██║  ██║███████╗██║  ██║██║  ██╗██║     ╚██████╔╝██║██║ ╚████║   ██║   ███████║
 ╚═════╝ ╚═╝  ╚═╝╚═╝╚═════╝     ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝      ╚═════╝ ╚═╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝
 Define the minimum dimensions at which your layout will change,
 adapting to different screen sizes, for use in media queries.
*/

$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1280px) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);




/*
     ██████╗ ██████╗ ██╗██████╗      ██████╗ ██████╗ ███╗   ██╗████████╗ █████╗ ██╗███╗   ██╗███████╗██████╗ ███████╗
    ██╔════╝ ██╔══██╗██║██╔══██╗    ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██║████╗  ██║██╔════╝██╔══██╗██╔════╝
   ██║  ███╗██████╔╝██║██║  ██║    ██║     ██║   ██║██╔██╗ ██║   ██║   ███████║██║██╔██╗ ██║█████╗  ██████╔╝███████╗
  ██║   ██║██╔══██╗██║██║  ██║    ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══██║██║██║╚██╗██║██╔══╝  ██╔══██╗╚════██║
 ╚██████╔╝██║  ██║██║██████╔╝    ╚██████╗╚██████╔╝██║ ╚████║   ██║   ██║  ██║██║██║ ╚████║███████╗██║  ██║███████║
 ╚═════╝ ╚═╝  ╚═╝╚═╝╚═════╝      ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚══════╝
 Define the maximum width of `.container` for different screen sizes.
*/

$container-max-widths: (sm: 740px, md: 960px, lg: 1100px, xl: 1250px) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");




/*
      ██████╗ ██████╗ ██╗██████╗      ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗███████╗
    ██╔════╝ ██╔══██╗██║██╔══██╗    ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║██╔════╝
   ██║  ███╗██████╔╝██║██║  ██║    ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║███████╗
  ██║   ██║██╔══██╗██║██║  ██║    ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║╚════██║
 ╚██████╔╝██║  ██║██║██████╔╝    ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║███████║
 ╚═════╝ ╚═╝  ╚═╝╚═╝╚═════╝      ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝╚══════╝
 Set the number of columns and specify the width of the gutters.
*/

$grid-columns     : 12 !default;
$grid-gutter-width: 32px !default;




/*
      ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗███████╗
    ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝
   ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║   ███████╗
  ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║   ╚════██║
 ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║   ███████║
 ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝
 Define common padding and border radius sizes and more.
*/

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius     : 1rem !default;               // 24px
$border-radius-sm  : 0.75rem !default;            // 8px
$border-radius-lg  : 1.5rem !default;             // 40px
$card-border-radius: $border-radius-sm !default;  // 12px

$box-shadow-sm: 0 0.25rem 1rem rgba($black, .075) !default;
$box-shadow   : 0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

$component-active-color: $white !default;
$component-active-bg   : theme-color("primary") !default;

$caret-width: .3em !default;

$transition-base       : all .2s ease-in-out !default;
$transition-base-slow  : all .5s ease-in-out !default;
$transition-base-slower: all 1s ease-in-out !default;
$transition-fade       : opacity .15s linear !default;
$transition-collapse   : height .35s ease !default;





/*
     ███████╗ ██████╗ ███╗   ██╗████████╗███████╗
    ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔════╝
   █████╗  ██║   ██║██╔██╗ ██║   ██║   ███████╗
  ██╔══╝  ██║   ██║██║╚██╗██║   ██║   ╚════██║
 ██║     ╚██████╔╝██║ ╚████║   ██║   ███████║
╚═╝      ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝
 Font, line-height, and color for body text, headings, and more.
*/

// stylelint-disable value-keyword-case
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace : SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-en        : 'Roboto', sans-serif;
$font-family-ar        : 'Cairo';
$font-family-base      : $font-family-en !default;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default;                      // Assumes the browser default, typically `16px`, convert px to rem 'https://www.ninjaunits.com/converters/pixels/pixels-rem/'
$font-size-lg  : ($font-size-base * 1.25) !default;  // 20px
$font-size-sm  : ($font-size-base * .875) !default;  // 14px

$font-weight-lighter: 200 !default;
$font-weight-light  : 300 !default;
$font-weight-normal : 400 !default;
$font-weight-bold   : 700 !default;
$font-weight-bolder : 900 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2.0625 !default;  // 33px
$h2-font-size: $font-size-base * 1.875 !default;   // 30px
$h3-font-size: $font-size-base * 1.5625 !default;  // 25px
$h4-font-size: $font-size-base * 1.375 !default;   // 22px
$h5-font-size: $font-size-base * 1.25 !default;    // 20px
$h6-font-size: $font-size-base !default;           // 16px

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family  : inherit !default;
$headings-font-weight  : 500 !default;
$headings-line-height  : 1.2 !default;
$headings-color        : inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight    : 300 !default;
$display2-weight    : 300 !default;
$display3-weight    : 300 !default;
$display4-weight    : 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size  : ($font-size-base * 1.25) !default;
$lead-font-weight: 300 !default;

$small-font-size: 85% !default;

$text-muted: $gray !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-font-size  : ($font-size-base * 1.25) !default;

$hr-border-color: rgba($border-color, .1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;
$mark-margin: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow        : inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: .5rem !default;

$mark-bg: $yellow-light !default;

$hr-margin-y: $spacer !default;





/*
     ████████╗ █████╗ ██████╗ ██╗     ███████╗███████╗
    ╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝██╔════╝
      ██║   ███████║██████╔╝██║     █████╗  ███████╗
     ██║   ██╔══██║██╔══██╗██║     ██╔══╝  ╚════██║
    ██║   ██║  ██║██████╔╝███████╗███████╗███████║
   ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝╚══════╝
 Customizes the `.table` component with basic values, each used across all table variations.
*/

$table-cell-padding   : .75rem !default;
$table-cell-padding-sm: .3rem !default;

$table-bg       : transparent !default;
$table-accent-bg: rgba($black, .05) !default;
$table-hover-bg : rgba($black, .075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: rgba($border-color, 0.1) !default;

$table-head-bg   : $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-bg          : $gray-900 !default;
$table-dark-accent-bg   : rgba($white, .05) !default;
$table-dark-hover-bg    : rgba($white, .075) !default;
$table-dark-border-color: lighten($gray-900, 7.5%) !default;
$table-dark-color       : $body-bg !default;

$table-striped-order: odd !default;

$table-caption-color: $text-muted !default;




/*
     ██████╗ ██╗   ██╗████████╗████████╗ ██████╗ ███╗   ██╗███████╗        ██╗    ███████╗ ██████╗ ██████╗ ███╗   ███╗███████╗
    ██╔══██╗██║   ██║╚══██╔══╝╚══██╔══╝██╔═══██╗████╗  ██║██╔════╝       ██╔╝    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║██╔════╝
   ██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔██╗ ██║███████╗      ██╔╝     █████╗  ██║   ██║██████╔╝██╔████╔██║███████╗
  ██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╗██║╚════██║     ██╔╝      ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║╚════██║
 ██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚████║███████║    ██╔╝       ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║███████║
╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═══╝╚══════╝    ╚═╝        ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝
 Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
*/

$input-btn-padding-y  : 0.5rem !default;
$input-btn-padding-x  : 0.5rem !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width     : .2rem !default;
$input-btn-focus-color     : rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow: none;

$input-btn-padding-y-sm  : 0.3rem !default;
$input-btn-padding-x-sm  : .25rem !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg  : 1.125rem !default;
$input-btn-padding-x-lg  : 1rem !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: $border-width !default;




/*
     ██████╗ ██╗   ██╗████████╗████████╗ ██████╗ ███╗   ██╗███████╗
    ██╔══██╗██║   ██║╚══██╔══╝╚══██╔══╝██╔═══██╗████╗  ██║██╔════╝
   ██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔██╗ ██║███████╗
  ██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╗██║╚════██║
 ██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚████║███████║
╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═══╝╚══════╝
 For each of Bootstrap's buttons, define text, background, and border color.
*/

$btn-padding-y  : $input-btn-padding-y !default;
$btn-padding-x  : $input-btn-padding-x !default;
$btn-line-height: $input-btn-line-height !default;

$btn-padding-y-sm  : $input-btn-padding-y-sm !default;
$btn-padding-x-sm  : $input-btn-padding-x-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg  : $input-btn-padding-y-lg !default;
$btn-padding-x-lg  : $input-btn-padding-x-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight      : $font-weight-bold !default;
$btn-box-shadow       : none !default;
$btn-focus-width      : $input-btn-focus-width !default;
$btn-focus-box-shadow : none;
$btn-disabled-opacity : .65 !default;
$btn-active-box-shadow: none;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius   : $border-radius-sm !default;
$btn-border-radius-lg: $border-radius !default;
$btn-border-radius-sm: $border-radius-sm/2 !default;

$btn-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !default;




/*
     ██╗    ██╗██╗██████╗  ██████╗ ███████╗████████╗███████╗
    ██║    ██║██║██╔══██╗██╔════╝ ██╔════╝╚══██╔══╝██╔════╝
   ██║ █╗ ██║██║██║  ██║██║  ███╗█████╗     ██║   ███████╗
  ██║███╗██║██║██║  ██║██║   ██║██╔══╝     ██║   ╚════██║
 ╚███╔███╔╝██║██████╔╝╚██████╔╝███████╗   ██║   ███████║
 ╚══╝╚══╝ ╚═╝╚═════╝  ╚═════╝ ╚══════╝   ╚═╝   ╚══════╝

*/
$widget-padding-y  : $btn-padding-y !default;
$widget-padding-x  : $btn-padding-x !default;
$widget-line-height: $btn-line-height !default;

$widget-padding-y-sm  : $btn-padding-y-sm  !default;
$widget-padding-x-sm  : $btn-padding-x-sm  !default;
$widget-line-height-sm: $btn-line-height-sm  !default;

$widget-padding-y-lg  : $btn-padding-y-lg  !default;
$widget-padding-x-lg  : $btn-padding-x-lg  !default;
$widget-line-height-lg: $btn-line-height-lg  !default;

$widget-border-width: $btn-border-width  !default;

$widget-font-weight      : $btn-font-weight  !default;
$widget-box-shadow       : $btn-box-shadow  !default;
$widget-focus-width      : $btn-focus-width  !default;
$widget-focus-box-shadow : $btn-focus-box-shadow  !default;
$widget-disabled-opacity : $btn-disabled-opacity  !default;
$widget-active-box-shadow: $btn-active-box-shadow  !default;

$widget-link-disabled-color: $btn-link-disabled-color  !default;

$widget-block-spacing-y: $btn-block-spacing-y !default;

// Allows for customizing button radius independently from global border radius
$widget-border-radius   : $btn-border-radius  !default;
$widget-border-radius-lg: $btn-border-radius-lg  !default;
$widget-border-radius-sm: $btn-border-radius-sm  !default;

$widget-transition: $btn-transition  !default;




/*
     ███████╗ ██████╗ ██████╗ ███╗   ███╗███████╗
    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║██╔════╝
   █████╗  ██║   ██║██████╔╝██╔████╔██║███████╗
  ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║╚════██║
 ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║███████║
╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝
*/

$label-margin-bottom: .5rem !default;

$input-padding-y  : $input-btn-padding-y !default;
$input-padding-x  : $input-btn-padding-x !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm  : $input-btn-padding-y-sm !default;
$input-padding-x-sm  : $input-btn-padding-x-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg  : $input-btn-padding-y-lg !default;
$input-padding-x-lg  : $input-btn-padding-x-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg         : $gray-100 !default;
$input-disabled-bg: $gray-200 !default;

$input-color       : $gray-600 !default;
$input-border-color: $input-bg !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow  : none !default;

$input-border-radius   : 0.625rem !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg          : $input-bg !default;
$input-focus-border-color: $input-border-color !default;
$input-focus-color       : $input-color !default;
$input-focus-width       : $input-btn-focus-width !default;
$input-focus-box-shadow  : none !default;

$input-placeholder-color: $input-color !default;
$input-plaintext-color  : $input-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: ($font-size-base * $input-btn-line-height)+($input-btn-padding-y * 2) !default;
$input-height      : calc(#{$input-height-inner}) !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm)+($input-btn-padding-y-sm * 2) !default;
$input-height-sm      : calc(#{$input-height-inner-sm}) !default;

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg)+($input-btn-padding-y-lg * 2) !default;
$input-height-lg      : calc(#{$input-height-inner-lg}) !default;

$input-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-text-margin-top: .25rem !default;

$form-check-input-gutter  : 1.25rem !default;
$form-check-input-margin-y: .3rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x      : .75rem !default;
$form-check-inline-input-margin-x: .3125rem !default;

$form-group-margin-bottom: 1rem !default;

$input-group-addon-color       : $input-color !default;
$input-group-addon-bg          : $gray-100 !default;
$input-group-addon-border-color: transparent !default;

$custom-forms-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !default;

$custom-control-gutter  : 1.5rem !default;
$custom-control-spacer-x: 1rem !default;

$custom-control-indicator-size      : 1.2rem !default;
$custom-control-indicator-bg        : $gray-300 !default;
$custom-control-indicator-bg-size   : 50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-label-disabled-color : $gray-600 !default;

$custom-control-indicator-checked-color      : $component-active-color !default;
$custom-control-indicator-checked-bg         : $blue !default;
$custom-control-indicator-checked-disabled-bg: rgba(theme-color("primary"), .5) !default;
$custom-control-indicator-checked-box-shadow : none !default;

$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg,
$input-btn-focus-box-shadow !default;

$custom-control-indicator-active-color     : $component-active-color !default;
$custom-control-indicator-active-bg        : lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: $border-radius-sm/3 !default;
$custom-checkbox-indicator-icon-checked : str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg        : $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color     : $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate      : str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-y        : .375rem !default;
$custom-select-padding-x        : .75rem !default;
$custom-select-height           : $input-height !default;
$custom-select-indicator-padding: 1rem !default;                    // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height      : $input-btn-line-height !default;
$custom-select-color            : $input-color !default;
$custom-select-disabled-color   : $gray-600 !default;
$custom-select-bg               : $white !default;
$custom-select-disabled-bg      : $gray-200 !default;
$custom-select-bg-size          : 8px 10px !default;                // In pixels because image dimensions
$custom-select-indicator-color  : $gray-800 !default;
// $custom-select-indicator        : str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-indicator : str-replace(url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px'%3E%3Cg%3E%3Cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='#{$gray}'/%3E%3C/g%3E%3C/svg%3E%0A"), "#", "%50") !default;
$custom-select-border-width : $input-btn-border-width !default;
$custom-select-border-color : $input-border-color !default;
$custom-select-border-radius: $btn-border-radius !default;
$custom-select-box-shadow   : none !default;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-width       : $input-btn-focus-width !default;
$custom-select-focus-box-shadow  : none !default;

$custom-select-font-size-sm: 80% !default;
$custom-select-height-sm   : $input-height-sm !default;

$custom-select-font-size-lg: 125% !default;
$custom-select-height-lg   : $input-height-lg !default;

$custom-range-track-width        : 100% !default;
$custom-range-track-height       : .5rem !default;
$custom-range-track-cursor       : pointer !default;
$custom-range-track-bg           : $gray-300 !default;
$custom-range-track-border-radius: 1rem !default;
$custom-range-track-box-shadow   : inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-range-thumb-width           : 1rem !default;
$custom-range-thumb-height          : $custom-range-thumb-width !default;
$custom-range-thumb-bg              : $component-active-bg !default;
$custom-range-thumb-border          : 0 !default;
$custom-range-thumb-border-radius   : 1rem !default;
$custom-range-thumb-box-shadow      : 0 .1rem .25rem rgba($black, .1) !default;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
$input-btn-focus-box-shadow !default;
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%) !default;

$custom-file-height            : $input-height !default;
$custom-file-height-inner      : $input-height-inner !default;
$custom-file-focus-border-color: $input-focus-border-color !default;
$custom-file-focus-box-shadow  : $input-btn-focus-box-shadow !default;
$custom-file-disabled-bg       : $input-disabled-bg !default;

$custom-file-padding-y    : $input-btn-padding-y !default;
$custom-file-padding-x    : $input-btn-padding-x !default;
$custom-file-line-height  : $input-btn-line-height !default;
$custom-file-color        : $primary !default;
$custom-file-bg           : transparent !default;
$custom-file-font-weight  : $font-weight-bold !default;
$custom-file-border-width : 0 !default;
$custom-file-border-color : $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow   : $input-box-shadow !default;
$custom-file-button-color : $custom-file-color !default;
$custom-file-button-bg    : $input-group-addon-bg !default;
$custom-file-text         : (
	en: "Browse",
	ar: "تصفح الملفات"
) !default;





/*
     ███████╗ ██████╗ ██████╗ ███╗   ███╗    ██╗   ██╗ █████╗ ██╗     ██╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║    ██║   ██║██╔══██╗██║     ██║██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
   █████╗  ██║   ██║██████╔╝██╔████╔██║    ██║   ██║███████║██║     ██║██║  ██║███████║   ██║   ██║██║   ██║██╔██╗ ██║
  ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║    ╚██╗ ██╔╝██╔══██║██║     ██║██║  ██║██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
 ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║     ╚████╔╝ ██║  ██║███████╗██║██████╔╝██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝      ╚═══╝  ╚═╝  ╚═╝╚══════╝╚═╝╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
*/

$form-feedback-margin-top   : $form-text-margin-top !default;
$form-feedback-font-size    : $font-size-base * 0.75 !default;
$form-feedback-valid-color  : theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;





/*
     ██████╗ ██████╗  ██████╗ ██████╗ ██████╗  ██████╗ ██╗    ██╗███╗   ██╗███████╗
    ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔══██╗██╔═══██╗██║    ██║████╗  ██║██╔════╝
   ██║  ██║██████╔╝██║   ██║██████╔╝██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║███████╗
  ██║  ██║██╔══██╗██║   ██║██╔═══╝ ██║  ██║██║   ██║██║███╗██║██║╚██╗██║╚════██║
 ██████╔╝██║  ██║╚██████╔╝██║     ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║███████║
╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝╚══════╝
 Dropdown menu container and contents.
*/

$dropdown-min-width    : 10rem !default;
$dropdown-padding-y    : 0 !default;
$dropdown-spacer       : .125rem !default;
$dropdown-bg           : $white !default;
$dropdown-border-color : transparent !default;
$dropdown-border-radius: 0 !default;
$dropdown-border-width : 0 !default;
$dropdown-divider-bg   : $gray-200 !default;
$dropdown-box-shadow   : $box-shadow-sm !default;

$dropdown-link-color      : $black !default;
$dropdown-link-hover-color: $black !default;
$dropdown-link-hover-bg   : $light !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg   : $component-active-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y    : 0.9rem !default;
$dropdown-item-padding-x    : 1.5rem !default;
$dropdown-item-border-color : $light !default;
$dropdown-item-border-width : $border-width !default;
$dropdown-item-border-radius: 0 !default;


$dropdown-header-color: $gray-600 !default;





/*
     ███████╗     ██╗███╗   ██╗██████╗ ███████╗██╗  ██╗
    ╚══███╔╝     ██║████╗  ██║██╔══██╗██╔════╝╚██╗██╔╝
     ███╔╝█████╗██║██╔██╗ ██║██║  ██║█████╗   ╚███╔╝
   ███╔╝ ╚════╝██║██║╚██╗██║██║  ██║██╔══╝   ██╔██╗
 ███████╗     ██║██║ ╚████║██████╔╝███████╗██╔╝ ██╗
╚══════╝     ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝
 Warning: Avoid customizing these values. They're used for a bird's eye view
 of components dependent on the z-axis and are designed to all work together.
*/

$zindex-dropdown       : 1000 !default;
$zindex-sticky         : 1020 !default;
$zindex-fixed          : 1030 !default;
$zindex-modal-backdrop : 1040 !default;
$zindex-modal          : 1050 !default;
$zindex-popover        : 1060 !default;
$zindex-tooltip        : 1070 !default;
$zindex-alerts-wrapper : 1080 !default;
$zindex-overlay-content: 1090 !default;
$zindex-overlay-menu   : 1100 !default;
$zindex-overlay-close  : 1110 !default;





/*
     ███╗   ██╗ █████╗ ██╗   ██╗███████╗
    ████╗  ██║██╔══██╗██║   ██║██╔════╝
   ██╔██╗ ██║███████║██║   ██║███████╗
  ██║╚██╗██║██╔══██║╚██╗ ██╔╝╚════██║
 ██║ ╚████║██║  ██║ ╚████╔╝ ███████║
╚═╝  ╚═══╝╚═╝  ╚═╝  ╚═══╝  ╚══════╝
*/

$nav-link-padding-y     : .5rem !default;
$nav-link-padding-x     : 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color            : $gray-300 !default;
$nav-tabs-border-width            : $border-width !default;
$nav-tabs-border-radius           : $border-radius !default;
$nav-tabs-link-hover-border-color : $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color       : $gray-700 !default;
$nav-tabs-link-active-bg          : $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius    : $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg   : $component-active-bg !default;

$nav-divider-color   : $gray-200 !default;
$nav-divider-margin-y: ($spacer / 2) !default;





/*
     ███╗   ██╗ █████╗ ██╗   ██╗██████╗  █████╗ ██████╗
    ████╗  ██║██╔══██╗██║   ██║██╔══██╗██╔══██╗██╔══██╗
   ██╔██╗ ██║███████║██║   ██║██████╔╝███████║██████╔╝
  ██║╚██╗██║██╔══██║╚██╗ ██╔╝██╔══██╗██╔══██║██╔══██╗
 ██║ ╚████║██║  ██║ ╚████╔╝ ██████╔╝██║  ██║██║  ██║
╚═╝  ╚═══╝╚═╝  ╚═╝  ╚═══╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝
*/

$navbar-padding-y: ($spacer / 1) !default;
$navbar-padding-x: $spacer !default;

$navbar-nav-link-padding-x: .5rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height       : ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-height   : $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y    : .25rem !default;
$navbar-toggler-padding-x    : .75rem !default;
$navbar-toggler-font-size    : $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-dark-color               : rgba($white, .5) !default;
$navbar-dark-hover-color         : rgba($white, .75) !default;
$navbar-dark-active-color        : $white !default;
$navbar-dark-disabled-color      : rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg     : str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color: rgba($white, .1) !default;

$navbar-light-color               : rgba($black, .5) !default;
$navbar-light-hover-color         : rgba($black, .7) !default;
$navbar-light-active-color        : rgba($black, .9) !default;
$navbar-light-disabled-color      : rgba($black, .3) !default;
$navbar-light-toggler-icon-bg     : str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;





/*
     ██████╗  █████╗  ██████╗ ██╗███╗   ██╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
    ██╔══██╗██╔══██╗██╔════╝ ██║████╗  ██║██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
   ██████╔╝███████║██║  ███╗██║██╔██╗ ██║███████║   ██║   ██║██║   ██║██╔██╗ ██║
  ██╔═══╝ ██╔══██║██║   ██║██║██║╚██╗██║██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
 ██║     ██║  ██║╚██████╔╝██║██║ ╚████║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
*/

$pagination-padding-y   : .5rem !default;
$pagination-padding-x   : .75rem !default;
$pagination-padding-y-sm: .25rem !default;
$pagination-padding-x-sm: .5rem !default;
$pagination-padding-y-lg: .75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height : 1.25 !default;

$pagination-color       : $link-color !default;
$pagination-bg          : $white !default;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline   : 0 !default;

$pagination-hover-color       : $link-hover-color !default;
$pagination-hover-bg          : $white !default;
$pagination-hover-border-color: transparent !default;

$pagination-active-color       : $gray-600 !default;
$pagination-active-bg          : $white !default;
$pagination-active-border-color: transparent !default;

$pagination-disabled-color       : $gray-600 !default;
$pagination-disabled-bg          : $white !default;
$pagination-disabled-border-color: transparent !default;





/*
          ██╗██╗   ██╗███╗   ███╗██████╗  ██████╗ ████████╗██████╗  ██████╗ ███╗   ██╗
         ██║██║   ██║████╗ ████║██╔══██╗██╔═══██╗╚══██╔══╝██╔══██╗██╔═══██╗████╗  ██║
        ██║██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   ██████╔╝██║   ██║██╔██╗ ██║
  ██   ██║██║   ██║██║╚██╔╝██║██╔══██╗██║   ██║   ██║   ██╔══██╗██║   ██║██║╚██╗██║
 ╚█████╔╝╚██████╔╝██║ ╚═╝ ██║██████╔╝╚██████╔╝   ██║   ██║  ██║╚██████╔╝██║ ╚████║
 ╚════╝  ╚═════╝ ╚═╝     ╚═╝╚═════╝  ╚═════╝    ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
*/

$jumbotron-padding: 2rem !default;
$jumbotron-bg     : $gray-200 !default;





/*
      ██████╗ █████╗ ██████╗ ██████╗ ███████╗
    ██╔════╝██╔══██╗██╔══██╗██╔══██╗██╔════╝
   ██║     ███████║██████╔╝██║  ██║███████╗
  ██║     ██╔══██║██╔══██╗██║  ██║╚════██║
 ╚██████╗██║  ██║██║  ██║██████╔╝███████║
 ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝
*/

$card-spacer-y           : 1.0625rem !default;
$card-spacer-x           : 1.25rem !default;
$card-border-width       : 0 !default;
$card-border-radius      : $card-border-radius !default;
$card-border-color       : transparent !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg             : inherit !default;
$card-bg                 : $white !default;
$card-box-shadow         : $box-shadow-sm;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: ($grid-gutter-width / 2) !default;
$card-deck-margin : $card-group-margin !default;

$card-columns-count : 3 !default;
$card-columns-gap   : 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;





/*
     ████████╗ ██████╗  ██████╗ ██╗  ████████╗██╗██████╗ ███████╗
    ╚══██╔══╝██╔═══██╗██╔═══██╗██║  ╚══██╔══╝██║██╔══██╗██╔════╝
      ██║   ██║   ██║██║   ██║██║     ██║   ██║██████╔╝███████╗
     ██║   ██║   ██║██║   ██║██║     ██║   ██║██╔═══╝ ╚════██║
    ██║   ╚██████╔╝╚██████╔╝███████╗██║   ██║██║     ███████║
   ╚═╝    ╚═════╝  ╚═════╝ ╚══════╝╚═╝   ╚═╝╚═╝     ╚══════╝
*/

$tooltip-font-size    : $font-size-sm*0.75 !default;
$tooltip-max-width    : auto !default;
$tooltip-color        : $black !default;
$tooltip-bg           : lighten($gray, $lighten-percentage) !default;
$tooltip-border-radius: $border-radius-sm/2 !default;
$tooltip-opacity      : 1 !default;
$tooltip-padding-y    : .25rem !default;
$tooltip-padding-x    : .5rem !default;
$tooltip-margin       : 0 !default;
$tooltip-box-shadow   : $box-shadow-sm;

$tooltip-arrow-width : .8rem !default;
$tooltip-arrow-height: .4rem !default;
$tooltip-arrow-color : $tooltip-bg !default;





/*
     ██████╗  ██████╗ ██████╗  ██████╗ ██╗   ██╗███████╗██████╗ ███████╗
    ██╔══██╗██╔═══██╗██╔══██╗██╔═══██╗██║   ██║██╔════╝██╔══██╗██╔════╝
   ██████╔╝██║   ██║██████╔╝██║   ██║██║   ██║█████╗  ██████╔╝███████╗
  ██╔═══╝ ██║   ██║██╔═══╝ ██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██╗╚════██║
 ██║     ╚██████╔╝██║     ╚██████╔╝ ╚████╔╝ ███████╗██║  ██║███████║
╚═╝      ╚═════╝ ╚═╝      ╚═════╝   ╚═══╝  ╚══════╝╚═╝  ╚═╝╚══════╝
*/

$popover-font-size    : $font-size-sm !default;
$popover-bg           : $white !default;
$popover-max-width    : 276px !default;
$popover-border-width : $border-width !default;
$popover-border-color : rgba($black, .2) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-box-shadow   : 0 .25rem .5rem rgba($black, .2) !default;

$popover-header-bg       : darken($popover-bg, 3%) !default;
$popover-header-color    : $headings-color !default;
$popover-header-padding-y: .5rem !default;
$popover-header-padding-x: .75rem !default;

$popover-body-color    : $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width : 1rem !default;
$popover-arrow-height: .5rem !default;
$popover-arrow-color : $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;





/*
     ██████╗  █████╗ ██████╗  ██████╗ ███████╗███████╗
    ██╔══██╗██╔══██╗██╔══██╗██╔════╝ ██╔════╝██╔════╝
   ██████╔╝███████║██║  ██║██║  ███╗█████╗  ███████╗
  ██╔══██╗██╔══██║██║  ██║██║   ██║██╔══╝  ╚════██║
 ██████╔╝██║  ██║██████╔╝╚██████╔╝███████╗███████║
╚═════╝ ╚═╝  ╚═╝╚═════╝  ╚═════╝ ╚══════╝╚══════╝
*/

$badge-font-size    : 85% !default;
$badge-font-weight  : $font-weight-normal !default;
$badge-padding-y    : 0.375rem !default;
$badge-padding-x    : 0.6875rem !default;
$badge-border-radius: $border-radius !default;

$badge-pill-padding-x: .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;





/*
     ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗
    ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝
   ██╔████╔██║██║   ██║██║  ██║███████║██║     ███████╗
  ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ╚════██║
 ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████║
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝
*/

// Padding applied to the modal body
$modal-inner-padding: 1.5rem 2rem !default;

$modal-dialog-margin        : .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;
$modal-title-font-size: 1.5625rem !default;
$modal-title-color: $black !default;
$modal-title-font-weight: $font-weight-bold !default;

$modal-content-bg              : $white !default;
$modal-content-border-color    : transparent !default;
$modal-content-border-width    : 0 !default;
$modal-content-border-radius   : $border-radius-sm !default;
$modal-content-box-shadow-xs   : $box-shadow-lg !default;
$modal-content-box-shadow-sm-up: $box-shadow !default;

$modal-backdrop-bg        : $black !default;
$modal-backdrop-opacity   : .75 !default;
$modal-header-border-color: $modal-content-border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding     : $modal-inner-padding !default;


$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 400px !default;

$modal-transition: transform .3s ease-out !default;





/*
      █████╗ ██╗     ███████╗██████╗ ████████╗███████╗
    ██╔══██╗██║     ██╔════╝██╔══██╗╚══██╔══╝██╔════╝
   ███████║██║     █████╗  ██████╔╝   ██║   ███████╗
  ██╔══██║██║     ██╔══╝  ██╔══██╗   ██║   ╚════██║
 ██║  ██║███████╗███████╗██║  ██║   ██║   ███████║
╚═╝  ╚═╝╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
 Define alert colors, border radius, and padding.
*/

$alert-padding-y       : .75rem !default;
$alert-padding-x       : 1.25rem !default;
$alert-margin-bottom   : 1rem !default;
$alert-border-radius   : $border-radius-sm/2 !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width    : $border-width !default;

$alert-bg-level    : -20 !default;
$alert-border-level: -20 !default;
$alert-color-level : 6 !default;





/*
     ██████╗ ██████╗  ██████╗  ██████╗ ██████╗ ███████╗███████╗███████╗    ██████╗  █████╗ ██████╗ ███████╗
    ██╔══██╗██╔══██╗██╔═══██╗██╔════╝ ██╔══██╗██╔════╝██╔════╝██╔════╝    ██╔══██╗██╔══██╗██╔══██╗██╔════╝
   ██████╔╝██████╔╝██║   ██║██║  ███╗██████╔╝█████╗  ███████╗███████╗    ██████╔╝███████║██████╔╝███████╗
  ██╔═══╝ ██╔══██╗██║   ██║██║   ██║██╔══██╗██╔══╝  ╚════██║╚════██║    ██╔══██╗██╔══██║██╔══██╗╚════██║
 ██║     ██║  ██║╚██████╔╝╚██████╔╝██║  ██║███████╗███████║███████║    ██████╔╝██║  ██║██║  ██║███████║
╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
*/

$progress-height              : 1rem !default;
$progress-font-size           : ($font-size-base * .75) !default;
$progress-bg                  : rgba($star-default, 0.1) !default;
$progress-border-radius       : 0 !default;
$progress-box-shadow          : none !default;
$progress-bar-color           : $white !default;
$progress-bar-bg              : $star-active !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition      : width .6s ease !default;




/*
     ██╗     ██╗███████╗████████╗     ██████╗ ██████╗  ██████╗ ██╗   ██╗██████╗
    ██║     ██║██╔════╝╚══██╔══╝    ██╔════╝ ██╔══██╗██╔═══██╗██║   ██║██╔══██╗
   ██║     ██║███████╗   ██║       ██║  ███╗██████╔╝██║   ██║██║   ██║██████╔╝
  ██║     ██║╚════██║   ██║       ██║   ██║██╔══██╗██║   ██║██║   ██║██╔═══╝
 ███████╗██║███████║   ██║       ╚██████╔╝██║  ██║╚██████╔╝╚██████╔╝██║
╚══════╝╚═╝╚══════╝   ╚═╝        ╚═════╝ ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝
*/

$list-group-bg           : $white !default;
$list-group-border-color : rgba($black, .125) !default;
$list-group-border-width : $border-width !default;
$list-group-border-radius: 0 !default;

$list-group-item-padding-y: .75rem !default;
$list-group-item-padding-x: 0 !default;

$list-group-hover-bg           : darken($dark, 5%) !default;
$list-group-active-color       : $white !default;
$list-group-active-bg          : darken($dark, 5%) !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg   : $list-group-bg !default;

$list-group-action-color      : $white !default;
$list-group-action-hover-color: $white !default;

$list-group-action-active-color: $white !default;
$list-group-action-active-bg   : $gray-200 !default;





/*
     ██╗███╗   ███╗ █████╗  ██████╗ ███████╗    ████████╗██╗  ██╗██╗   ██╗███╗   ███╗██████╗ ███╗   ██╗ █████╗ ██╗██╗     ███████╗
    ██║████╗ ████║██╔══██╗██╔════╝ ██╔════╝    ╚══██╔══╝██║  ██║██║   ██║████╗ ████║██╔══██╗████╗  ██║██╔══██╗██║██║     ██╔════╝
   ██║██╔████╔██║███████║██║  ███╗█████╗         ██║   ███████║██║   ██║██╔████╔██║██████╔╝██╔██╗ ██║███████║██║██║     ███████╗
  ██║██║╚██╔╝██║██╔══██║██║   ██║██╔══╝         ██║   ██╔══██║██║   ██║██║╚██╔╝██║██╔══██╗██║╚██╗██║██╔══██║██║██║     ╚════██║
 ██║██║ ╚═╝ ██║██║  ██║╚██████╔╝███████╗       ██║   ██║  ██║╚██████╔╝██║ ╚═╝ ██║██████╔╝██║ ╚████║██║  ██║██║███████╗███████║
╚═╝╚═╝     ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝       ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═╝╚═╝╚══════╝╚══════╝
*/

$thumbnail-padding      : .25rem !default;
$thumbnail-bg           : inherit !default;
$thumbnail-border-width : $border-width !default;
$thumbnail-border-color : $gray-300 !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow   : 0 1px 2px rgba($black, .075) !default;





/*
     ███████╗██╗ ██████╗ ██╗   ██╗██████╗ ███████╗███████╗
    ██╔════╝██║██╔════╝ ██║   ██║██╔══██╗██╔════╝██╔════╝
   █████╗  ██║██║  ███╗██║   ██║██████╔╝█████╗  ███████╗
  ██╔══╝  ██║██║   ██║██║   ██║██╔══██╗██╔══╝  ╚════██║
 ██║     ██║╚██████╔╝╚██████╔╝██║  ██║███████╗███████║
╚═╝     ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝
*/

$figure-caption-font-size: 90% !default;
$figure-caption-color    : $gray-600 !default;





/*
     ██████╗ ██████╗ ███████╗ █████╗ ██████╗  ██████╗██████╗ ██╗   ██╗███╗   ███╗██████╗ ███████╗
    ██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗██║   ██║████╗ ████║██╔══██╗██╔════╝
   ██████╔╝██████╔╝█████╗  ███████║██║  ██║██║     ██████╔╝██║   ██║██╔████╔██║██████╔╝███████╗
  ██╔══██╗██╔══██╗██╔══╝  ██╔══██║██║  ██║██║     ██╔══██╗██║   ██║██║╚██╔╝██║██╔══██╗╚════██║
 ██████╔╝██║  ██║███████╗██║  ██║██████╔╝╚██████╗██║  ██║╚██████╔╝██║ ╚═╝ ██║██████╔╝███████║
╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝  ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚═════╝ ╚══════╝
*/

$breadcrumb-padding-y   : .75rem !default;
$breadcrumb-padding-x   : 0 !default;
$breadcrumb-item-padding: 1.5rem !default;

$breadcrumb-margin-bottom: 1rem !default;

$breadcrumb-bg           : transparent !default;
$breadcrumb-divider-color: $primary !default;
$breadcrumb-active-color : $black !default;
$breadcrumb-divider      : quote(">") !default;

$breadcrumb-border-radius: 0 !default;





/*
      ██████╗ █████╗ ██████╗  ██████╗ ██╗   ██╗███████╗███████╗██╗
    ██╔════╝██╔══██╗██╔══██╗██╔═══██╗██║   ██║██╔════╝██╔════╝██║
   ██║     ███████║██████╔╝██║   ██║██║   ██║███████╗█████╗  ██║
  ██║     ██╔══██║██╔══██╗██║   ██║██║   ██║╚════██║██╔══╝  ██║
 ╚██████╗██║  ██║██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████╗
 ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝
*/

$carousel-control-color  : $white !default;
$carousel-control-width  : 15% !default;
$carousel-control-opacity: .5 !default;

$carousel-indicator-width    : 30px !default;
$carousel-indicator-height   : 3px !default;
$carousel-indicator-spacer   : 3px !default;
$carousel-indicator-active-bg: $white !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;

$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$carousel-transition: transform .6s ease !default;  // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)





/*
      ██████╗██╗      ██████╗ ███████╗███████╗
    ██╔════╝██║     ██╔═══██╗██╔════╝██╔════╝
   ██║     ██║     ██║   ██║███████╗█████╗
  ██║     ██║     ██║   ██║╚════██║██╔══╝
 ╚██████╗███████╗╚██████╔╝███████║███████╗
 ╚═════╝╚══════╝ ╚═════╝ ╚══════╝╚══════╝
*/

$close-font-size  : $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color      : $black !default;
$close-text-shadow: 0 1px 0 $white !default;




/*
      ██████╗ ██████╗ ██████╗ ███████╗
    ██╔════╝██╔═══██╗██╔══██╗██╔════╝
   ██║     ██║   ██║██║  ██║█████╗
  ██║     ██║   ██║██║  ██║██╔══╝
 ╚██████╗╚██████╔╝██████╔╝███████╗
 ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝
*/

$code-font-size: 87.5% !default;
$code-color    : $pink !default;

$kbd-padding-y: .2rem !default;
$kbd-padding-x: .4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color    : $white !default;
$kbd-bg       : $gray-900 !default;

$pre-color                : $gray-900 !default;
$pre-scrollable-max-height: 340px !default;





/*
     ██████╗ ██████╗ ██╗███╗   ██╗████████╗██╗███╗   ██╗ ██████╗
    ██╔══██╗██╔══██╗██║████╗  ██║╚══██╔══╝██║████╗  ██║██╔════╝
   ██████╔╝██████╔╝██║██╔██╗ ██║   ██║   ██║██╔██╗ ██║██║  ███╗
  ██╔═══╝ ██╔══██╗██║██║╚██╗██║   ██║   ██║██║╚██╗██║██║   ██║
 ██║     ██║  ██║██║██║ ╚████║   ██║   ██║██║ ╚████║╚██████╔╝
╚═╝     ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝   ╚═╝   ╚═╝╚═╝  ╚═══╝ ╚═════╝
*/
$print-page-size     : a3 !default;
$print-body-min-width: map-get($grid-breakpoints, "lg") !default;


/*
     ██╗███╗   ██╗████████╗██╗   ████████╗███████╗██╗      ██╗███╗   ██╗██████╗ ██╗   ██╗████████╗
    ██║████╗  ██║╚══██╔══╝██║   ╚══██╔══╝██╔════╝██║      ██║████╗  ██║██╔══██╗██║   ██║╚══██╔══╝
   ██║██╔██╗ ██║   ██║   ██║█████╗██║   █████╗  ██║█████╗██║██╔██╗ ██║██████╔╝██║   ██║   ██║
  ██║██║╚██╗██║   ██║   ██║╚════╝██║   ██╔══╝  ██║╚════╝██║██║╚██╗██║██╔═══╝ ██║   ██║   ██║
 ██║██║ ╚████║   ██║   ███████╗ ██║   ███████╗███████╗ ██║██║ ╚████║██║     ╚██████╔╝   ██║
╚═╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝ ╚═╝   ╚══════╝╚══════╝ ╚═╝╚═╝  ╚═══╝╚═╝      ╚═════╝    ╚═╝
*/

// NOTE: by using !default on all variables, we're saying only declare the variable if it doesn't
// already exist, which allows devs to declare these variables themselves and assign them any value
// they want before importing this file

// rgba is needed for the selected flag hover state to blend in with
// the border-highlighting some browsers give the input on focus
$hoverColor: rgba($black, 0.05) !default;
$greyText: $input-color !default;
$greyBorder: $input-border-color !default;

$flagHeight: 15px !default;
$flagWidth: 20px !default;
$flagPadding: 8px !default;
// this border width is used for the popup and divider, but it is also
// assumed to be the border width of the input, which we do not control
$borderWidth: $border-width !default;

$arrowHeight: 4px !default;
$arrowWidth: 6px !default;
$triangleBorder: 3px !default;
$arrowPadding: 6px !default;
$arrowColor: $greyText !default;

$inputPadding: 6px !default;
$selectedFlagWidth: $flagWidth+(2 * $flagPadding) !default;
$selectedFlagArrowWidth: $flagWidth+$flagPadding+$arrowWidth+(2 * $arrowPadding) !default;
$selectedFlagDialCodeWidth: $selectedFlagWidth+$flagPadding !default;
$selectedFlagArrowDialCodeWidth: $selectedFlagArrowWidth+$flagPadding !default;

// image related variables
$flagsImagePath: "../images/" !default;
$flagsImageName: "flags" !default;
$flagsImageExtension: "png" !default;

// enough space for them to click off to close
$mobilePopupMargin: 30px !default;