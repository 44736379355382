//*********************************  Breadcrumb  *********************************//
nav[aria-label="breadcrumb"] {
	.breadcrumb {
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;
		width: 100%;

		&-item {
			text-transform: capitalize;
			font-weight: $font-weight-normal;
			font-size: $font-size-sm;
			text-align: left;
			position: relative;
			white-space: nowrap;

			&:not(:first-of-type):not(:nth-of-type(2)) {
				@extend .text-truncate;
			}

			&:not(:first-of-type) {
				&:before {
					content: "";
					vertical-align: .255em;
					background: str-replace(url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px'%3E%3Cg%3E%3Cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='#{$primary}'/%3E%3C/g%3E%3C/svg%3E%0A"), "#", "%23") center center no-repeat !important;
					background-size: 0.7rem !important;
					border: 0;
					display: block;
					width: 23px;
					height: 10px;
					position: absolute;
					left: 0;
					top: 5px;
					transform: rotate(-90deg);
				}
			}
		}
	}
}

div[class*="card"]{
	nav[aria-label="breadcrumb"] {
		max-width: 75%;
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
		.breadcrumb {
			margin: 0;
			padding: 0;
			width: 100%;
			&-item {
				font-weight: $font-weight-bold;
				font-size: $font-size-lg;
				&:not(:first-of-type):not(:nth-of-type(2)){@extend .text-truncate;}
				&:not(:first-of-type) {
					&:before {
						background-size: 0.9rem !important;
						width: 28px;
						height: 24px;
						@include media-breakpoint-down(md){
							width: 20px;
							height: 18px;
						}
					}
				}
				&.active{
					@extend .text-truncate;
				}
			}
		}
	}
}