.mce-container,
.mce-container *,
.mce-widget,
.mce-widget *,
.mce-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	vertical-align: top;
	background: transparent;
	text-decoration: none;
	color: #595959;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	text-shadow: none;
	float: none;
	position: static;
	width: auto;
	height: auto;
	white-space: nowrap;
	cursor: inherit;
	-webkit-tap-highlight-color: transparent;
	line-height: normal;
	font-weight: normal;
	text-align: left;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	direction: ltr;
	max-width: none
}

.mce-widget button {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}

.mce-container *[unselectable] {
	-moz-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none
}

.word-wrap {
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto
}

.mce-fade {
	opacity: 0;
	-webkit-transition: opacity .15s linear;
	transition: opacity .15s linear
}

.mce-fade.mce-in {
	opacity: 1
}

.mce-tinymce {
	visibility: inherit !important;
	position: relative
}

.mce-fullscreen {
	border: 0;
	padding: 0;
	margin: 0;
	overflow: hidden;
	height: 100%;
	z-index: 100
}

div.mce-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: auto
}

.mce-tinymce {
	display: block;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
}

.mce-statusbar>.mce-container-body {
	display: flex;
	padding-right: 16px
}

.mce-statusbar>.mce-container-body .mce-path {
	flex: 1
}

.mce-wordcount {
	font-size: inherit;
	text-transform: uppercase;
	padding: 8px 0
}

div.mce-edit-area {
	background: #FFF;
	filter: none
}

.mce-statusbar {
	position: relative
}

.mce-statusbar .mce-container-body {
	position: relative;
	font-size: 11px
}

.mce-fullscreen .mce-resizehandle {
	display: none
}

.mce-statusbar .mce-flow-layout-item {
	margin: 0
}

.mce-charmap {
	border-collapse: collapse
}

.mce-charmap td {
	cursor: default;
	border: 1px solid #c5c5c5;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	vertical-align: middle;
	padding: 2px
}

.mce-charmap td div {
	text-align: center
}

.mce-charmap td:hover {
	background: white
}

.mce-grid td.mce-grid-cell div {
	border: 1px solid #c5c5c5;
	width: 15px;
	height: 15px;
	margin: 0;
	cursor: pointer
}

.mce-grid td.mce-grid-cell div:focus {
	border-color: #91bbe9
}

.mce-grid td.mce-grid-cell div[disabled] {
	cursor: not-allowed
}

.mce-grid {
	border-spacing: 2px;
	border-collapse: separate
}

.mce-grid a {
	display: block;
	border: 1px solid transparent
}

.mce-grid a:hover,
.mce-grid a:focus {
	border-color: #91bbe9
}

.mce-grid-border {
	margin: 0 4px 0 4px
}

.mce-grid-border a {
	border-color: #c5c5c5;
	width: 13px;
	height: 13px
}

.mce-grid-border a:hover,
.mce-grid-border a.mce-active {
	border-color: #91bbe9;
	background: #bdd6f2
}

.mce-text-center {
	text-align: center
}

div.mce-tinymce-inline {
	width: 100%
}

.mce-colorbtn-trans div {
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	font-size: 20px;
	line-height: 16px;
	color: #8b8b8b
}

.mce-monospace {
	font-family: "Courier New", Courier, monospace
}

.mce-toolbar-grp .mce-flow-layout-item {
	margin-bottom: 0
}

.mce-container b {
	font-weight: bold
}

.mce-container p {
	margin-bottom: 5px
}

.mce-container a {
	cursor: pointer;
	color: #2276d2
}

.mce-container a:hover {
	text-decoration: underline
}

.mce-container ul {
	margin-left: 15px
}

.mce-container .mce-table-striped {
	border-collapse: collapse;
	margin: 10px
}

.mce-container .mce-table-striped thead>tr {
	background-color: #fafafa
}

.mce-container .mce-table-striped thead>tr th {
	font-weight: bold
}

.mce-container .mce-table-striped td,
.mce-container .mce-table-striped th {
	padding: 5px
}

.mce-container .mce-table-striped tr:nth-child(even) {
	background-color: #fafafa
}

.mce-container .mce-table-striped tbody>tr:hover {
	background-color: #e1e1e1
}

.mce-branding {
	font-size: inherit;
	text-transform: uppercase;
	white-space: pre;
	padding: 8px 0
}

.mce-branding a {
	font-size: inherit;
	color: inherit
}

.mce-top-part {
	position: relative
}

.mce-top-part:before {
	content: '';
	position: absolute;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none
}

.mce-rtl .mce-wordcount {
	left: 0;
	right: auto
}

.mce-rtl .mce-statusbar>.mce-container-body>*:last-child {
	padding-right: 0;
	padding-left: 10px
}

.mce-rtl .mce-path {
	text-align: right;
	padding-right: 16px
}

.mce-croprect-container {
	position: absolute;
	top: 0;
	left: 0
}

.mce-croprect-handle {
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	border: 2px solid white
}

.mce-croprect-handle-nw {
	border-width: 2px 0 0 2px;
	margin: -2px 0 0 -2px;
	cursor: nw-resize;
	top: 100px;
	left: 100px
}

.mce-croprect-handle-ne {
	border-width: 2px 2px 0 0;
	margin: -2px 0 0 -20px;
	cursor: ne-resize;
	top: 100px;
	left: 200px
}

.mce-croprect-handle-sw {
	border-width: 0 0 2px 2px;
	margin: -20px 2px 0 -2px;
	cursor: sw-resize;
	top: 200px;
	left: 100px
}

.mce-croprect-handle-se {
	border-width: 0 2px 2px 0;
	margin: -20px 0 0 -20px;
	cursor: se-resize;
	top: 200px;
	left: 200px
}

.mce-croprect-handle-move {
	position: absolute;
	cursor: move;
	border: 0
}

.mce-croprect-block {
	opacity: .5;
	filter: alpha(opacity=50);
	zoom: 1;
	position: absolute;
	background: black
}

.mce-croprect-handle:focus {
	border-color: #2276d2
}

.mce-croprect-handle-move:focus {
	outline: 1px solid #2276d2
}

.mce-imagepanel {
	overflow: auto;
	background: black
}

.mce-imagepanel-bg {
	position: absolute;
	background: url('data:image/gif;base64,R0lGODdhDAAMAIABAMzMzP///ywAAAAADAAMAAACFoQfqYeabNyDMkBQb81Uat85nxguUAEAOw==')
}

.mce-imagepanel img {
	position: absolute
}

.mce-imagetool.mce-btn .mce-ico {
	display: block;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	font-size: 20px;
	padding: 5px
}

.mce-arrow-up {
	margin-top: 12px
}

.mce-arrow-down {
	margin-top: -12px
}

.mce-arrow:before,
.mce-arrow:after {
	position: absolute;
	left: 50%;
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-color: transparent;
	content: ""
}

.mce-arrow.mce-arrow-up:before {
	top: -9px;
	border-bottom-color: #c5c5c5;
	border-width: 0 9px 9px;
	margin-left: -9px
}

.mce-arrow.mce-arrow-down:before {
	bottom: -9px;
	border-top-color: #c5c5c5;
	border-width: 9px 9px 0;
	margin-left: -9px
}

.mce-arrow.mce-arrow-up:after {
	top: -8px;
	border-bottom-color: #fff;
	border-width: 0 8px 8px;
	margin-left: -8px
}

.mce-arrow.mce-arrow-down:after {
	bottom: -8px;
	border-top-color: #fff;
	border-width: 8px 8px 0;
	margin-left: -8px
}

.mce-arrow.mce-arrow-left:before,
.mce-arrow.mce-arrow-left:after {
	margin: 0
}

.mce-arrow.mce-arrow-left:before {
	left: 8px
}

.mce-arrow.mce-arrow-left:after {
	left: 9px
}

.mce-arrow.mce-arrow-right:before,
.mce-arrow.mce-arrow-right:after {
	left: auto;
	margin: 0
}

.mce-arrow.mce-arrow-right:before {
	right: 8px
}

.mce-arrow.mce-arrow-right:after {
	right: 9px
}

.mce-arrow.mce-arrow-center.mce-arrow.mce-arrow-left:before {
	left: -9px;
	top: 50%;
	border-right-color: #c5c5c5;
	border-width: 9px 9px 9px 0;
	margin-top: -9px
}

.mce-arrow.mce-arrow-center.mce-arrow.mce-arrow-left:after {
	left: -8px;
	top: 50%;
	border-right-color: #fff;
	border-width: 8px 8px 8px 0;
	margin-top: -8px
}

.mce-arrow.mce-arrow-center.mce-arrow.mce-arrow-left {
	margin-left: 12px
}

.mce-arrow.mce-arrow-center.mce-arrow.mce-arrow-right:before {
	right: -9px;
	top: 50%;
	border-left-color: #c5c5c5;
	border-width: 9px 0 9px 9px;
	margin-top: -9px
}

.mce-arrow.mce-arrow-center.mce-arrow.mce-arrow-right:after {
	right: -8px;
	top: 50%;
	border-left-color: #fff;
	border-width: 8px 0 8px 8px;
	margin-top: -8px
}

.mce-arrow.mce-arrow-center.mce-arrow.mce-arrow-right {
	margin-left: -14px
}

.mce-edit-aria-container>.mce-container-body {
	display: flex
}

.mce-edit-aria-container>.mce-container-body .mce-edit-area {
	flex: 1
}

.mce-edit-aria-container>.mce-container-body .mce-sidebar>.mce-container-body {
	display: flex;
	align-items: stretch;
	height: 100%
}

.mce-edit-aria-container>.mce-container-body .mce-sidebar-panel {
	min-width: 250px;
	max-width: 250px;
	position: relative
}

.mce-edit-aria-container>.mce-container-body .mce-sidebar-panel>.mce-container-body {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: auto;
	top: 0;
	left: 0
}

.mce-sidebar-toolbar {
	border: 0 solid #c5c5c5;
	border-left-width: 1px
}

.mce-sidebar-toolbar .mce-btn {
	border-left: 0;
	border-right: 0
}

.mce-sidebar-toolbar .mce-btn.mce-active,
.mce-sidebar-toolbar .mce-btn.mce-active:hover {
	background-color: #555c66
}

.mce-sidebar-toolbar .mce-btn.mce-active button,
.mce-sidebar-toolbar .mce-btn.mce-active:hover button,
.mce-sidebar-toolbar .mce-btn.mce-active button i,
.mce-sidebar-toolbar .mce-btn.mce-active:hover button i {
	color: white;
	text-shadow: 1px 1px none
}

.mce-sidebar-panel {
	border: 0 solid #c5c5c5;
	border-left-width: 1px
}

.mce-container,
.mce-container-body {
	display: block
}

.mce-autoscroll {
	overflow: hidden
}

.mce-scrollbar {
	position: absolute;
	width: 7px;
	height: 100%;
	top: 2px;
	right: 2px;
	opacity: .4;
	filter: alpha(opacity=40);
	zoom: 1
}

.mce-scrollbar-h {
	top: auto;
	right: auto;
	left: 2px;
	bottom: 2px;
	width: 100%;
	height: 7px
}

.mce-scrollbar-thumb {
	position: absolute;
	background-color: #000;
	border: 1px solid #888;
	border-color: rgba(85, 85, 85, 0.6);
	width: 5px;
	height: 100%
}

.mce-scrollbar-h .mce-scrollbar-thumb {
	width: 100%;
	height: 5px
}

.mce-scrollbar:hover,
.mce-scrollbar.mce-active {
	background-color: #AAA;
	opacity: .6;
	filter: alpha(opacity=60);
	zoom: 1
}

.mce-scroll {
	position: relative
}

.mce-panel {
	border: 0 solid #f3f3f3;
	border: 0 solid #c5c5c5;
	background-color: #fff
}

.mce-floatpanel {
	position: absolute;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
}

.mce-floatpanel.mce-fixed {
	position: fixed
}

.mce-floatpanel .mce-arrow,
.mce-floatpanel .mce-arrow:after {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid
}

.mce-floatpanel .mce-arrow {
	border-width: 11px
}

.mce-floatpanel .mce-arrow:after {
	border-width: 10px;
	content: ""
}

.mce-floatpanel.mce-popover {
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	background: transparent;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	top: 0;
	left: 0;
	background: #FFF;
	border: 1px solid #c5c5c5;
	border: 1px solid rgba(0, 0, 0, 0.25)
}

.mce-floatpanel.mce-popover.mce-bottom {
	margin-top: 10px;
	*margin-top: 0
}

.mce-floatpanel.mce-popover.mce-bottom>.mce-arrow {
	left: 50%;
	margin-left: -11px;
	border-top-width: 0;
	border-bottom-color: #c5c5c5;
	border-bottom-color: rgba(0, 0, 0, 0.25);
	top: -11px
}

.mce-floatpanel.mce-popover.mce-bottom>.mce-arrow:after {
	top: 1px;
	margin-left: -10px;
	border-top-width: 0;
	border-bottom-color: #FFF
}

.mce-floatpanel.mce-popover.mce-top {
	margin-top: -10px;
	*margin-top: 0
}

.mce-floatpanel.mce-popover.mce-top>.mce-arrow {
	left: 50%;
	margin-left: -11px;
	border-bottom-width: 0;
	border-top-color: #c5c5c5;
	top: auto;
	bottom: -11px
}

.mce-floatpanel.mce-popover.mce-top>.mce-arrow:after {
	bottom: 1px;
	margin-left: -10px;
	border-bottom-width: 0;
	border-top-color: #FFF
}

.mce-floatpanel.mce-popover.mce-bottom.mce-start,
.mce-floatpanel.mce-popover.mce-top.mce-start {
	margin-left: -22px
}

.mce-floatpanel.mce-popover.mce-bottom.mce-start>.mce-arrow,
.mce-floatpanel.mce-popover.mce-top.mce-start>.mce-arrow {
	left: 20px
}

.mce-floatpanel.mce-popover.mce-bottom.mce-end,
.mce-floatpanel.mce-popover.mce-top.mce-end {
	margin-left: 22px
}

.mce-floatpanel.mce-popover.mce-bottom.mce-end>.mce-arrow,
.mce-floatpanel.mce-popover.mce-top.mce-end>.mce-arrow {
	right: 10px;
	left: auto
}

.mce-fullscreen {
	border: 0;
	padding: 0;
	margin: 0;
	overflow: hidden;
	height: 100%
}

div.mce-fullscreen {
	position: fixed;
	top: 0;
	left: 0
}

#mce-modal-block {
	opacity: 0;
	filter: alpha(opacity=0);
	zoom: 1;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #FFF
}

#mce-modal-block.mce-in {
	opacity: .5;
	filter: alpha(opacity=50);
	zoom: 1
}

.mce-window-move {
	cursor: move
}

.mce-window {
	-webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	background: transparent;
	background: #FFF;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	transform: scale(.1);
	transition: transform 100ms ease-in, opacity 150ms ease-in
}

.mce-window.mce-in {
	transform: scale(1);
	opacity: 1
}

.mce-window-head {
	padding: 9px 15px;
	border-bottom: 1px solid #c5c5c5;
	position: relative
}

.mce-window-head .mce-close {
	position: absolute;
	right: 0;
	top: 0;
	height: 38px;
	width: 38px;
	text-align: center;
	cursor: pointer
}

.mce-window-head .mce-close i {
	color: #9b9b9b
}

.mce-close:hover i {
	color: #bdbdbd
}

.mce-window-head .mce-title {
	line-height: 20px;
	font-size: 20px;
	font-weight: bold;
	text-rendering: optimizelegibility;
	padding-right: 20px
}

.mce-window .mce-container-body {
	display: block
}

.mce-foot {
	display: block;
	background-color: #FFF;
	border-top: 1px solid #c5c5c5
}

.mce-window-head .mce-dragh {
	position: absolute;
	top: 0;
	left: 0;
	cursor: move;
	width: 90%;
	height: 100%
}

.mce-window iframe {
	width: 100%;
	height: 100%
}

.mce-window-body .mce-listbox {
	border-color: #e2e4e7
}

.mce-window .mce-btn:hover {
	border-color: #c5c5c5
}

.mce-window .mce-btn:focus {
	border-color: #2276d2
}

.mce-window-body .mce-btn,
.mce-foot .mce-btn {
	border-color: #c5c5c5
}

.mce-foot .mce-btn.mce-primary {
	border-color: transparent
}

.mce-rtl .mce-window-head .mce-close {
	position: absolute;
	right: auto;
	left: 0
}

.mce-rtl .mce-window-head .mce-dragh {
	left: auto;
	right: 0
}

.mce-rtl .mce-window-head .mce-title {
	direction: rtl;
	text-align: right;
	padding-right: 0;
	padding-left: 20px
}

.mce-tooltip {
	position: absolute;
	padding: 5px;
	opacity: .8;
	filter: alpha(opacity=80);
	zoom: 1;
	margin-top: 1px
}

.mce-tooltip-inner {
	font-size: 11px;
	background-color: #000;
	color: white;
	max-width: 200px;
	padding: 5px 8px 4px 8px;
	text-align: center;
	white-space: normal
}

.mce-tooltip-inner {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.mce-tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	line-height: 0;
	border: 5px dashed #000
}

.mce-tooltip-arrow-n {
	border-bottom-color: #000
}

.mce-tooltip-arrow-s {
	border-top-color: #000
}

.mce-tooltip-arrow-e {
	border-left-color: #000
}

.mce-tooltip-arrow-w {
	border-right-color: #000
}

.mce-tooltip-nw,
.mce-tooltip-sw {
	margin-left: -14px
}

.mce-tooltip-ne,
.mce-tooltip-se {
	margin-left: 14px
}

.mce-tooltip-n .mce-tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -5px;
	border-bottom-style: solid;
	border-top: none;
	border-left-color: transparent;
	border-right-color: transparent
}

.mce-tooltip-nw .mce-tooltip-arrow {
	top: 0;
	left: 10px;
	border-bottom-style: solid;
	border-top: none;
	border-left-color: transparent;
	border-right-color: transparent
}

.mce-tooltip-ne .mce-tooltip-arrow {
	top: 0;
	right: 10px;
	border-bottom-style: solid;
	border-top: none;
	border-left-color: transparent;
	border-right-color: transparent
}

.mce-tooltip-s .mce-tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-top-style: solid;
	border-bottom: none;
	border-left-color: transparent;
	border-right-color: transparent
}

.mce-tooltip-sw .mce-tooltip-arrow {
	bottom: 0;
	left: 10px;
	border-top-style: solid;
	border-bottom: none;
	border-left-color: transparent;
	border-right-color: transparent
}

.mce-tooltip-se .mce-tooltip-arrow {
	bottom: 0;
	right: 10px;
	border-top-style: solid;
	border-bottom: none;
	border-left-color: transparent;
	border-right-color: transparent
}

.mce-tooltip-e .mce-tooltip-arrow {
	right: 0;
	top: 50%;
	margin-top: -5px;
	border-left-style: solid;
	border-right: none;
	border-top-color: transparent;
	border-bottom-color: transparent
}

.mce-tooltip-w .mce-tooltip-arrow {
	left: 0;
	top: 50%;
	margin-top: -5px;
	border-right-style: solid;
	border-left: none;
	border-top-color: transparent;
	border-bottom-color: transparent
}

.mce-progress {
	display: inline-block;
	position: relative;
	height: 20px
}

.mce-progress .mce-bar-container {
	display: inline-block;
	width: 100px;
	height: 100%;
	margin-right: 8px;
	border: 1px solid #ccc;
	overflow: hidden
}

.mce-progress .mce-text {
	display: inline-block;
	margin-top: auto;
	margin-bottom: auto;
	font-size: 14px;
	width: 40px;
	color: #595959
}

.mce-bar {
	display: block;
	width: 0;
	height: 100%;
	background-color: #dfdfdf;
	-webkit-transition: width .2s ease;
	transition: width .2s ease
}

.mce-notification {
	position: absolute;
	background-color: #fff;
	padding: 5px;
	margin-top: 5px;
	border-width: 1px;
	border-style: solid;
	border-color: #c5c5c5;
	transition: transform 100ms ease-in, opacity 150ms ease-in;
	opacity: 0;
	box-sizing: border-box
}

.mce-notification.mce-in {
	opacity: 1
}

.mce-notification-success {
	background-color: #dff0d8;
	border-color: #d6e9c6
}

.mce-notification-info {
	background-color: #d9edf7;
	border-color: #779ECB
}

.mce-notification-warning {
	background-color: #fcf8e3;
	border-color: #faebcc
}

.mce-notification-error {
	background-color: #f2dede;
	border-color: #ebccd1
}

.mce-notification.mce-has-close {
	padding-right: 15px
}

.mce-notification .mce-ico {
	margin-top: 5px
}

.mce-notification-inner {
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	display: inline-block;
	font-size: 14px;
	margin: 5px 8px 4px 8px;
	text-align: center;
	white-space: normal;
	color: #31708f
}

.mce-notification-inner a {
	text-decoration: underline;
	cursor: pointer
}

.mce-notification .mce-progress {
	margin-right: 8px
}

.mce-notification .mce-progress .mce-text {
	margin-top: 5px
}

.mce-notification *,
.mce-notification .mce-progress .mce-text {
	color: #595959
}

.mce-notification .mce-progress .mce-bar-container {
	border-color: #c5c5c5
}

.mce-notification .mce-progress .mce-bar-container .mce-bar {
	background-color: #595959
}

.mce-notification-success *,
.mce-notification-success .mce-progress .mce-text {
	color: #3c763d
}

.mce-notification-success .mce-progress .mce-bar-container {
	border-color: #d6e9c6
}

.mce-notification-success .mce-progress .mce-bar-container .mce-bar {
	background-color: #3c763d
}

.mce-notification-info *,
.mce-notification-info .mce-progress .mce-text {
	color: #31708f
}

.mce-notification-info .mce-progress .mce-bar-container {
	border-color: #779ECB
}

.mce-notification-info .mce-progress .mce-bar-container .mce-bar {
	background-color: #31708f
}

.mce-notification-warning *,
.mce-notification-warning .mce-progress .mce-text {
	color: #8a6d3b
}

.mce-notification-warning .mce-progress .mce-bar-container {
	border-color: #faebcc
}

.mce-notification-warning .mce-progress .mce-bar-container .mce-bar {
	background-color: #8a6d3b
}

.mce-notification-error *,
.mce-notification-error .mce-progress .mce-text {
	color: #a94442
}

.mce-notification-error .mce-progress .mce-bar-container {
	border-color: #ebccd1
}

.mce-notification-error .mce-progress .mce-bar-container .mce-bar {
	background-color: #a94442
}

.mce-notification .mce-close {
	position: absolute;
	top: 6px;
	right: 8px;
	font-size: 20px;
	font-weight: bold;
	line-height: 20px;
	color: #9b9b9b;
	cursor: pointer
}

.mce-abs-layout {
	position: relative
}

html .mce-abs-layout-item,
.mce-abs-end {
	position: absolute
}

.mce-abs-end {
	width: 1px;
	height: 1px
}

.mce-container-body.mce-abs-layout {
	overflow: hidden
}

.mce-btn {
	border: 1px solid #b3b3b3;
	border-color: transparent transparent transparent transparent;
	position: relative;
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
	background: white;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.mce-btn:hover,
.mce-btn:active {
	background: white;
	color: #595959;
	border-color: #e2e4e7
}

.mce-btn:focus {
	background: white;
	color: #595959;
	border-color: #e2e4e7
}

.mce-btn.mce-disabled button,
.mce-btn.mce-disabled:hover button {
	cursor: default;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	opacity: .4;
	filter: alpha(opacity=40);
	zoom: 1
}

.mce-btn.mce-active,
.mce-btn.mce-active:hover,
.mce-btn.mce-active:focus,
.mce-btn.mce-active:active {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	background: #555c66;
	color: white;
	border-color: transparent
}

.mce-btn.mce-active button,
.mce-btn.mce-active:hover button,
.mce-btn.mce-active i,
.mce-btn.mce-active:hover i {
	color: white
}

.mce-btn:hover .mce-caret {
	border-top-color: #b5bcc2
}

.mce-btn.mce-active .mce-caret,
.mce-btn.mce-active:hover .mce-caret {
	border-top-color: white
}

.mce-btn button {
	padding: 4px 6px;
	font-size: 14px;
	line-height: 20px;
	*line-height: 16px;
	cursor: pointer;
	color: #595959;
	text-align: center;
	overflow: visible;
	-webkit-appearance: none
}

.mce-btn button::-moz-focus-inner {
	border: 0;
	padding: 0
}

.mce-btn i {
	text-shadow: 1px 1px none
}

.mce-primary.mce-btn-has-text {
	min-width: 50px
}

.mce-primary {
	color: white;
	border: 1px solid transparent;
	border-color: transparent;
	background-color: #2276d2
}

.mce-primary:hover,
.mce-primary:focus {
	background-color: #1e6abc;
	border-color: transparent
}

.mce-primary.mce-disabled button,
.mce-primary.mce-disabled:hover button {
	cursor: default;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	opacity: .4;
	filter: alpha(opacity=40);
	zoom: 1
}

.mce-primary.mce-active,
.mce-primary.mce-active:hover,
.mce-primary:not(.mce-disabled):active {
	background-color: #1e6abc;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.mce-primary button,
.mce-primary button i {
	color: white;
	text-shadow: 1px 1px none
}

.mce-btn .mce-txt {
	font-size: inherit;
	line-height: inherit;
	color: inherit
}

.mce-btn-large button {
	padding: 9px 14px;
	font-size: 16px;
	line-height: normal
}

.mce-btn-large i {
	margin-top: 2px
}

.mce-btn-small button {
	padding: 1px 5px;
	font-size: 12px;
	*padding-bottom: 2px
}

.mce-btn-small i {
	line-height: 20px;
	vertical-align: top;
	*line-height: 18px
}

.mce-btn .mce-caret {
	margin-top: 8px;
	margin-left: 0
}

.mce-btn-small .mce-caret {
	margin-top: 8px;
	margin-left: 0
}

.mce-caret {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	width: 0;
	height: 0;
	vertical-align: top;
	border-top: 4px solid #b5bcc2;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: ""
}

.mce-disabled .mce-caret {
	border-top-color: #aaa
}

.mce-caret.mce-up {
	border-bottom: 4px solid #b5bcc2;
	border-top: 0
}

.mce-btn-flat {
	border: 0;
	background: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	filter: none
}

.mce-btn-flat:hover,
.mce-btn-flat.mce-active,
.mce-btn-flat:focus,
.mce-btn-flat:active {
	border: 0;
	background: #e6e6e6;
	filter: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.mce-btn-has-text .mce-ico {
	padding-right: 5px
}

.mce-rtl .mce-btn button {
	direction: rtl
}

.mce-toolbar .mce-btn-group {
	margin: 0;
	padding: 2px 0
}

.mce-btn-group .mce-btn {
	border-width: 1px;
	margin: 0;
	margin-left: 2px
}

.mce-btn-group:not(:first-child) {
	border-left: 1px solid #d9d9d9;
	padding-left: 0;
	margin-left: 2px
}

.mce-btn-group {
	margin-left: 2px
}

.mce-btn-group .mce-btn.mce-flow-layout-item {
	margin: 0
}

.mce-rtl .mce-btn-group .mce-btn {
	margin-left: 0;
	margin-right: 2px
}

.mce-rtl .mce-btn-group .mce-first {
	margin-right: 0
}

.mce-rtl .mce-btn-group:not(:first-child) {
	border-left: none;
	border-right: 1px solid #d9d9d9;
	padding-right: 4px;
	margin-right: 4px
}

.mce-checkbox {
	cursor: pointer
}

i.mce-i-checkbox {
	margin: 0 3px 0 0;
	border: 1px solid #c5c5c5;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	background-color: white;
	text-indent: -10em;
	overflow: hidden
}

.mce-checked i.mce-i-checkbox {
	color: #595959;
	font-size: 16px;
	line-height: 16px;
	text-indent: 0
}

.mce-checkbox:focus i.mce-i-checkbox,
.mce-checkbox.mce-focus i.mce-i-checkbox {
	border: 1px solid #2276d2;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.mce-checkbox.mce-disabled .mce-label,
.mce-checkbox.mce-disabled i.mce-i-checkbox {
	color: #bdbdbd
}

.mce-checkbox .mce-label {
	vertical-align: middle
}

.mce-rtl .mce-checkbox {
	direction: rtl;
	text-align: right
}

.mce-rtl i.mce-i-checkbox {
	margin: 0 0 0 3px
}

.mce-combobox {
	position: relative;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	*height: 32px
}

.mce-combobox input {
	border: 1px solid #c5c5c5;
	border-right-color: #c5c5c5;
	height: 28px
}

.mce-combobox.mce-disabled input {
	color: #bdbdbd
}

.mce-combobox .mce-btn {
	border: 1px solid #c5c5c5;
	border-left: 0;
	margin: 0
}

.mce-combobox button {
	padding-right: 8px;
	padding-left: 8px
}

.mce-combobox.mce-disabled .mce-btn button {
	cursor: default;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	opacity: .4;
	filter: alpha(opacity=40);
	zoom: 1
}

.mce-combobox .mce-status {
	position: absolute;
	right: 2px;
	top: 50%;
	line-height: 16px;
	margin-top: -8px;
	font-size: 12px;
	width: 15px;
	height: 15px;
	text-align: center;
	cursor: pointer
}

.mce-combobox.mce-has-status input {
	padding-right: 20px
}

.mce-combobox.mce-has-open .mce-status {
	right: 37px
}

.mce-combobox .mce-status.mce-i-warning {
	color: #c09853
}

.mce-combobox .mce-status.mce-i-checkmark {
	color: #468847
}

.mce-menu.mce-combobox-menu {
	border-top: 0;
	margin-top: 0;
	max-height: 200px
}

.mce-menu.mce-combobox-menu .mce-menu-item {
	padding: 4px 6px 4px 4px;
	font-size: 11px
}

.mce-menu.mce-combobox-menu .mce-menu-item-sep {
	padding: 0
}

.mce-menu.mce-combobox-menu .mce-text,
.mce-menu.mce-combobox-menu .mce-text b {
	font-size: 11px
}

.mce-menu.mce-combobox-menu .mce-menu-item-link,
.mce-menu.mce-combobox-menu .mce-menu-item-link b {
	font-size: 11px
}

.mce-colorbox i {
	border: 1px solid #c5c5c5;
	width: 14px;
	height: 14px
}

.mce-colorbutton .mce-ico {
	position: relative
}

.mce-colorbutton-grid {
	margin: 4px
}

.mce-colorbutton .mce-preview {
	padding-right: 3px;
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -17px;
	margin-top: 7px;
	background: gray;
	width: 13px;
	height: 2px;
	overflow: hidden
}

.mce-colorbutton.mce-btn-small .mce-preview {
	margin-left: -16px;
	padding-right: 0;
	width: 16px
}

.mce-rtl .mce-colorbutton {
	direction: rtl
}

.mce-rtl .mce-colorbutton .mce-preview {
	margin-left: 0;
	padding-right: 0;
	padding-left: 3px
}

.mce-rtl .mce-colorbutton.mce-btn-small .mce-preview {
	margin-left: 0;
	padding-right: 0;
	padding-left: 2px
}

.mce-rtl .mce-colorbutton .mce-open {
	padding-left: 4px;
	padding-right: 4px;
	border-left: 0
}

.mce-colorpicker {
	position: relative;
	width: 250px;
	height: 220px
}

.mce-colorpicker-sv {
	position: absolute;
	top: 0;
	left: 0;
	width: 90%;
	height: 100%;
	border: 1px solid #c5c5c5;
	cursor: crosshair;
	overflow: hidden
}

.mce-colorpicker-h-chunk {
	width: 100%
}

.mce-colorpicker-overlay1,
.mce-colorpicker-overlay2 {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0
}

.mce-colorpicker-overlay1 {
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#ffffff', endColorstr='#00ffffff');
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr='#ffffff', endColorstr='#00ffffff')";
	background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0))
}

.mce-colorpicker-overlay2 {
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#00000000', endColorstr='#000000');
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#00000000', endColorstr='#000000')";
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000)
}

.mce-colorpicker-selector1 {
	background: none;
	position: absolute;
	width: 12px;
	height: 12px;
	margin: -8px 0 0 -8px;
	border: 1px solid black;
	border-radius: 50%
}

.mce-colorpicker-selector2 {
	position: absolute;
	width: 10px;
	height: 10px;
	border: 1px solid white;
	border-radius: 50%
}

.mce-colorpicker-h {
	position: absolute;
	top: 0;
	right: 0;
	width: 6.5%;
	height: 100%;
	border: 1px solid #c5c5c5;
	cursor: crosshair
}

.mce-colorpicker-h-marker {
	margin-top: -4px;
	position: absolute;
	top: 0;
	left: -1px;
	width: 100%;
	border: 1px solid black;
	background: white;
	height: 4px;
	z-index: 100
}

.mce-path {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	padding: 8px;
	white-space: normal;
	font-size: inherit
}

.mce-path .mce-txt {
	display: inline-block;
	padding-right: 3px
}

.mce-path .mce-path-body {
	display: inline-block
}

.mce-path-item {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	cursor: pointer;
	color: #595959;
	font-size: inherit;
	text-transform: uppercase
}

.mce-path-item:hover {
	text-decoration: underline
}

.mce-path-item:focus {
	background: #555c66;
	color: white
}

.mce-path .mce-divider {
	display: inline;
	font-size: inherit
}

.mce-disabled .mce-path-item {
	color: #aaa
}

.mce-rtl .mce-path {
	direction: rtl
}

.mce-fieldset {
	border: 0 solid #9E9E9E
}

.mce-fieldset>.mce-container-body {
	margin-top: -15px
}

.mce-fieldset-title {
	margin-left: 5px;
	padding: 0 5px 0 5px
}

.mce-fit-layout {
	display: inline-block;
	*display: inline;
	*zoom: 1
}

.mce-fit-layout-item {
	position: absolute
}

.mce-flow-layout-item {
	display: inline-block;
	*display: inline;
	*zoom: 1
}

.mce-flow-layout-item {
	margin: 2px 0 2px 2px
}

.mce-flow-layout-item.mce-last {
	margin-right: 2px
}

.mce-flow-layout {
	white-space: normal
}

.mce-tinymce-inline .mce-flow-layout {
	white-space: nowrap
}

.mce-rtl .mce-flow-layout {
	text-align: right;
	direction: rtl
}

.mce-rtl .mce-flow-layout-item {
	margin: 2px 2px 2px 0
}

.mce-rtl .mce-flow-layout-item.mce-last {
	margin-left: 2px
}

.mce-iframe {
	border: 0 solid #c5c5c5;
	width: 100%;
	height: 100%
}

.mce-infobox {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
	overflow: hidden;
	border: 1px solid red
}

.mce-infobox div {
	display: block;
	margin: 5px
}

.mce-infobox div button {
	position: absolute;
	top: 50%;
	right: 4px;
	cursor: pointer;
	margin-top: -8px;
	display: none
}

.mce-infobox div button:focus {
	outline: 2px solid #e2e4e7
}

.mce-infobox.mce-has-help div {
	margin-right: 25px
}

.mce-infobox.mce-has-help button {
	display: block
}

.mce-infobox.mce-success {
	background: #dff0d8;
	border-color: #d6e9c6
}

.mce-infobox.mce-success div {
	color: #3c763d
}

.mce-infobox.mce-warning {
	background: #fcf8e3;
	border-color: #faebcc
}

.mce-infobox.mce-warning div {
	color: #8a6d3b
}

.mce-infobox.mce-error {
	background: #f2dede;
	border-color: #ebccd1
}

.mce-infobox.mce-error div {
	color: #a94442
}

.mce-rtl .mce-infobox div {
	text-align: right;
	direction: rtl
}

.mce-label {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
	overflow: hidden
}

.mce-label.mce-autoscroll {
	overflow: auto
}

.mce-label.mce-disabled {
	color: #aaa
}

.mce-label.mce-multiline {
	white-space: pre-wrap
}

.mce-label.mce-success {
	color: #468847
}

.mce-label.mce-warning {
	color: #c09853
}

.mce-label.mce-error {
	color: #b94a48
}

.mce-rtl .mce-label {
	text-align: right;
	direction: rtl
}

.mce-menubar {
	border: 1px solid #e2e4e7
}

.mce-menubar .mce-menubtn {
	border-color: transparent;
	background: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	filter: none
}

.mce-menubar .mce-menubtn button span {
	color: #595959
}

.mce-menubar .mce-caret {
	border-top-color: #b5bcc2
}

.mce-menubar .mce-active .mce-caret,
.mce-menubar .mce-menubtn:hover .mce-caret {
	border-top-color: #b5bcc2
}

.mce-menubar .mce-menubtn:hover,
.mce-menubar .mce-menubtn.mce-active,
.mce-menubar .mce-menubtn:focus {
	border-color: #e2e4e7;
	background: white;
	filter: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.mce-menubar .mce-menubtn.mce-active {
	border-bottom: none;
	z-index: 65537
}

div.mce-menubtn.mce-opened {
	border-bottom-color: white;
	z-index: 65537
}

div.mce-menubtn.mce-opened.mce-opened-under {
	z-index: 0
}

.mce-menubtn button {
	color: #595959
}

.mce-menubtn.mce-btn-small span {
	font-size: 12px
}

.mce-menubtn.mce-fixed-width span {
	display: inline-block;
	overflow-x: hidden;
	text-overflow: ellipsis;
	width: 90px
}

.mce-menubtn.mce-fixed-width.mce-btn-small span {
	width: 70px
}

.mce-menubtn .mce-caret {
	*margin-top: 6px
}

.mce-rtl .mce-menubtn button {
	direction: rtl;
	text-align: right
}

.mce-rtl .mce-menubtn.mce-fixed-width span {
	direction: rtl;
	text-align: right
}

.mce-menu-item {
	display: block;
	padding: 6px 4px 6px 4px;
	clear: both;
	font-weight: normal;
	line-height: 20px;
	color: #595959;
	white-space: nowrap;
	cursor: pointer;
	line-height: normal;
	border-left: 4px solid transparent;
	margin-bottom: 1px
}

.mce-menu-item .mce-text,
.mce-menu-item .mce-text b {
	line-height: 1;
	vertical-align: initial
}

.mce-menu-item .mce-caret {
	margin-top: 4px;
	margin-right: 6px;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	border-left: 4px solid #595959
}

.mce-menu-item .mce-menu-shortcut {
	display: inline-block;
	padding: 0 10px 0 20px;
	color: #aaa
}

.mce-menu-item .mce-ico {
	padding-right: 4px
}

.mce-menu-item:hover,
.mce-menu-item:focus {
	background: #ededee
}

.mce-menu-item:hover .mce-menu-shortcut,
.mce-menu-item:focus .mce-menu-shortcut {
	color: #aaa
}

.mce-menu-item:hover .mce-text,
.mce-menu-item:focus .mce-text,
.mce-menu-item:hover .mce-ico,
.mce-menu-item:focus .mce-ico {
	color: #595959
}

.mce-menu-item.mce-selected {
	background: #ededee
}

.mce-menu-item.mce-selected .mce-text,
.mce-menu-item.mce-selected .mce-ico {
	color: #595959
}

.mce-menu-item.mce-active.mce-menu-item-normal {
	background: #555c66
}

.mce-menu-item.mce-active.mce-menu-item-normal .mce-text,
.mce-menu-item.mce-active.mce-menu-item-normal .mce-ico {
	color: white
}

.mce-menu-item.mce-active.mce-menu-item-checkbox .mce-ico {
	visibility: visible
}

.mce-menu-item.mce-disabled,
.mce-menu-item.mce-disabled:hover {
	background: white
}

.mce-menu-item.mce-disabled:focus,
.mce-menu-item.mce-disabled:hover:focus {
	background: #ededee
}

.mce-menu-item.mce-disabled .mce-text,
.mce-menu-item.mce-disabled:hover .mce-text,
.mce-menu-item.mce-disabled .mce-ico,
.mce-menu-item.mce-disabled:hover .mce-ico {
	color: #aaa
}

.mce-menu-item.mce-menu-item-preview.mce-active {
	border-left: 5px solid #555c66;
	background: white
}

.mce-menu-item.mce-menu-item-preview.mce-active .mce-text,
.mce-menu-item.mce-menu-item-preview.mce-active .mce-ico {
	color: #595959
}

.mce-menu-item.mce-menu-item-preview.mce-active:hover {
	background: #ededee
}

.mce-menu-item-link {
	color: #093;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.mce-menu-item-link b {
	color: #093
}

.mce-menu-item-ellipsis {
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden
}

.mce-menu-item:hover *,
.mce-menu-item.mce-selected *,
.mce-menu-item:focus * {
	color: #595959
}

div.mce-menu .mce-menu-item-sep,
.mce-menu-item-sep:hover {
	border: 0;
	padding: 0;
	height: 1px;
	margin: 9px 1px;
	overflow: hidden;
	background: transparent;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	cursor: default;
	filter: none
}

div.mce-menu .mce-menu-item b {
	font-weight: bold
}

.mce-menu-item-indent-1 {
	padding-left: 20px
}

.mce-menu-item-indent-2 {
	padding-left: 35px
}

.mce-menu-item-indent-2 {
	padding-left: 35px
}

.mce-menu-item-indent-3 {
	padding-left: 40px
}

.mce-menu-item-indent-4 {
	padding-left: 45px
}

.mce-menu-item-indent-5 {
	padding-left: 50px
}

.mce-menu-item-indent-6 {
	padding-left: 55px
}

.mce-menu.mce-rtl {
	direction: rtl
}

.mce-rtl .mce-menu-item {
	text-align: right;
	direction: rtl;
	padding: 6px 12px 6px 15px
}

.mce-rtl .mce-menu-item .mce-caret {
	margin-left: 6px;
	margin-right: 0;
	border-right: 4px solid #595959;
	border-left: 0
}

.mce-rtl .mce-menu-item.mce-selected .mce-caret,
.mce-rtl .mce-menu-item:focus .mce-caret,
.mce-rtl .mce-menu-item:hover .mce-caret {
	border-left-color: transparent;
	border-right-color: #595959
}

.mce-rtl .mce-menu-item .mce-ico {
	padding-right: 0;
	padding-left: 4px
}

.mce-throbber {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: .6;
	filter: alpha(opacity=60);
	zoom: 1;
	background: #fff url('../images/icons/loader.gif') no-repeat center center
}

.mce-throbber-inline {
	position: static;
	height: 50px
}

.mce-menu .mce-throbber-inline {
	height: 25px;
	background-size: contain
}

.mce-menu {
	position: absolute;
	left: 0;
	top: 0;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	background: transparent;
	z-index: 1000;
	padding: 5px 0 5px 0;
	margin: -1px 0 0;
	min-width: 180px;
	background: white;
	border: 1px solid #c5c9cf;
	border: 1px solid #e2e4e7;
	z-index: 1002;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	max-height: 500px;
	overflow: auto;
	overflow-x: hidden
}

.mce-menu.mce-animate {
	opacity: .01;
	transform: rotateY(10deg) rotateX(-10deg);
	transform-origin: left top
}

.mce-menu.mce-menu-align .mce-menu-shortcut,
.mce-menu.mce-menu-align .mce-caret {
	position: absolute;
	right: 0
}

.mce-menu i {
	display: none
}

.mce-menu-has-icons i {
	display: inline-block
}

.mce-menu.mce-in.mce-animate {
	opacity: 1;
	transform: rotateY(0) rotateX(0);
	transition: opacity .075s ease, transform .1s ease
}

.mce-menu-sub-tr-tl {
	margin: -6px 0 0 -1px
}

.mce-menu-sub-br-bl {
	margin: 6px 0 0 -1px
}

.mce-menu-sub-tl-tr {
	margin: -6px 0 0 1px
}

.mce-menu-sub-bl-br {
	margin: 6px 0 0 1px
}

.mce-rtl .mce-menu-item .mce-ico {
	padding-right: 0;
	padding-left: 4px
}

.mce-rtl.mce-menu-align .mce-caret,
.mce-rtl .mce-menu-shortcut {
	right: auto;
	left: 0
}

.mce-listbox button {
	text-align: left;
	padding-right: 20px;
	position: relative
}

.mce-listbox .mce-caret {
	position: absolute;
	margin-top: -2px;
	right: 8px;
	top: 50%
}

.mce-rtl .mce-listbox .mce-caret {
	right: auto;
	left: 8px
}

.mce-rtl .mce-listbox button {
	padding-right: 10px;
	padding-left: 20px
}

.mce-container-body .mce-resizehandle {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 16px;
	height: 16px;
	visibility: visible;
	cursor: s-resize;
	margin: 0
}

.mce-container-body .mce-resizehandle-both {
	cursor: se-resize
}

i.mce-i-resize {
	color: #595959
}

.mce-selectbox {
	background: #fff;
	border: 1px solid #c5c5c5
}

.mce-slider {
	border: 1px solid #c5c5c5;
	background: #fff;
	width: 100px;
	height: 10px;
	position: relative;
	display: block
}

.mce-slider.mce-vertical {
	width: 10px;
	height: 100px
}

.mce-slider-handle {
	border: 1px solid #c5c5c5;
	background: #e6e6e6;
	display: block;
	width: 13px;
	height: 13px;
	position: absolute;
	top: 0;
	left: 0;
	margin-left: -1px;
	margin-top: -2px
}

.mce-slider-handle:focus {
	border-color: #2276d2
}

.mce-spacer {
	visibility: hidden
}

.mce-splitbtn:hover .mce-open {
	border-left: 1px solid #e2e4e7
}

.mce-splitbtn .mce-open {
	border-left: 1px solid transparent;
	padding-right: 4px;
	padding-left: 4px
}

.mce-splitbtn .mce-open:focus {
	border-left: 1px solid #e2e4e7
}

.mce-splitbtn .mce-open:hover,
.mce-splitbtn .mce-open:active {
	border-left: 1px solid #e2e4e7
}

.mce-splitbtn.mce-active:hover .mce-open {
	border-left: 1px solid white
}

.mce-splitbtn.mce-opened {
	border-color: #e2e4e7
}

.mce-splitbtn.mce-btn-small .mce-open {
	padding: 0 3px 0 3px
}

.mce-rtl .mce-splitbtn {
	direction: rtl;
	text-align: right
}

.mce-rtl .mce-splitbtn button {
	padding-right: 4px;
	padding-left: 4px
}

.mce-rtl .mce-splitbtn .mce-open {
	border-left: 0
}

.mce-stack-layout-item {
	display: block
}

.mce-tabs {
	display: block;
	border-bottom: 1px solid #c5c5c5
}

.mce-tabs,
.mce-tabs+.mce-container-body {
	background: #fff
}

.mce-tab {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	border: 1px solid #c5c5c5;
	border-width: 0 1px 0 0;
	background: #fff;
	padding: 8px 15px;
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
	height: 13px;
	cursor: pointer
}

.mce-tab:hover {
	background: #FDFDFD
}

.mce-tab.mce-active {
	background: #FDFDFD;
	border-bottom-color: transparent;
	margin-bottom: -1px;
	height: 14px
}

.mce-tab:focus {
	color: #2276d2
}

.mce-rtl .mce-tabs {
	text-align: right;
	direction: rtl
}

.mce-rtl .mce-tab {
	border-width: 0 0 0 1px
}

.mce-textbox {
	background: #fff;
	border: 1px solid #c5c5c5;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	display: inline-block;
	-webkit-transition: border linear .2s, box-shadow linear .2s;
	transition: border linear .2s, box-shadow linear .2s;
	height: 28px;
	resize: none;
	padding: 0 4px 0 4px;
	white-space: pre-wrap;
	*white-space: pre;
	color: #595959
}

.mce-textbox:focus,
.mce-textbox.mce-focus {
	border-color: #2276d2;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.mce-placeholder .mce-textbox {
	color: #aaa
}

.mce-textbox.mce-multiline {
	padding: 4px;
	height: auto
}

.mce-textbox.mce-disabled {
	color: #bdbdbd
}

.mce-rtl .mce-textbox {
	text-align: right;
	direction: rtl
}

.mce-dropzone {
	border: 3px dashed gray;
	text-align: center
}

.mce-dropzone span {
	text-transform: uppercase;
	display: inline-block;
	vertical-align: middle
}

.mce-dropzone:after {
	content: "";
	height: 100%;
	display: inline-block;
	vertical-align: middle
}

.mce-dropzone.mce-disabled {
	opacity: .4;
	filter: alpha(opacity=40);
	zoom: 1
}

.mce-dropzone.mce-disabled.mce-dragenter {
	cursor: not-allowed
}

.mce-browsebutton {
	position: relative;
	overflow: hidden
}

.mce-browsebutton button {
	position: relative;
	z-index: 1
}

.mce-browsebutton input {
	opacity: 0;
	filter: alpha(opacity=0);
	zoom: 1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0
}

@font-face {
	font-family: 'tinymce';
	src: url('../fonts/tinymce.eot');
	src: url('../fonts/tinymce.eot?#iefix') format('embedded-opentype'), url('../fonts/tinymce.woff') format('woff'), url('../fonts/tinymce.ttf') format('truetype'), url('../fonts/tinymce.svg#tinymce') format('svg');
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: 'tinymce-small';
	src: url('../fonts/tinymce-small.eot');
	src: url('../fonts/tinymce-small.eot?#iefix') format('embedded-opentype'), url('../fonts/tinymce-small.woff') format('woff'), url('../fonts/tinymce-small.ttf') format('truetype'), url('../fonts/tinymce-small.svg#tinymce') format('svg');
	font-weight: normal;
	font-style: normal
}

.mce-ico {
	font-family: 'tinymce', Arial;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-size: 16px;
	line-height: 16px;
	speak: none;
	vertical-align: text-top;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-block;
	background: transparent center center;
	background-size: cover;
	width: 16px;
	height: 16px;
	color: #595959
}

.mce-btn-small .mce-ico {
	font-family: 'tinymce-small', Arial
}

.mce-i-save:before {
	content: "\e000"
}

.mce-i-newdocument:before {
	content: "\e001"
}

.mce-i-fullpage:before {
	content: "\e002"
}

.mce-i-alignleft:before {
	content: "\e003"
}

.mce-i-aligncenter:before {
	content: "\e004"
}

.mce-i-alignright:before {
	content: "\e005"
}

.mce-i-alignjustify:before {
	content: "\e006"
}

.mce-i-alignnone:before {
	content: "\e003"
}

.mce-i-cut:before {
	content: "\e007"
}

.mce-i-paste:before {
	content: "\e008"
}

.mce-i-searchreplace:before {
	content: "\e009"
}

.mce-i-bullist:before {
	content: "\e00a"
}

.mce-i-numlist:before {
	content: "\e00b"
}

.mce-i-indent:before {
	content: "\e00c"
}

.mce-i-outdent:before {
	content: "\e00d"
}

.mce-i-blockquote:before {
	content: "\e00e"
}

.mce-i-undo:before {
	content: "\e00f"
}

.mce-i-redo:before {
	content: "\e010"
}

.mce-i-link:before {
	content: "\e011"
}

.mce-i-unlink:before {
	content: "\e012"
}

.mce-i-anchor:before {
	content: "\e013"
}

.mce-i-image:before {
	content: "\e014"
}

.mce-i-media:before {
	content: "\e015"
}

.mce-i-help:before {
	content: "\e016"
}

.mce-i-code:before {
	content: "\e017"
}

.mce-i-insertdatetime:before {
	content: "\e018"
}

.mce-i-preview:before {
	content: "\e019"
}

.mce-i-forecolor:before {
	content: "\e01a"
}

.mce-i-backcolor:before {
	content: "\e01a"
}

.mce-i-table:before {
	content: "\e01b"
}

.mce-i-hr:before {
	content: "\e01c"
}

.mce-i-removeformat:before {
	content: "\e01d"
}

.mce-i-subscript:before {
	content: "\e01e"
}

.mce-i-superscript:before {
	content: "\e01f"
}

.mce-i-charmap:before {
	content: "\e020"
}

.mce-i-emoticons:before {
	content: "\e021"
}

.mce-i-print:before {
	content: "\e022"
}

.mce-i-fullscreen:before {
	content: "\e023"
}

.mce-i-spellchecker:before {
	content: "\e024"
}

.mce-i-nonbreaking:before {
	content: "\e025"
}

.mce-i-template:before {
	content: "\e026"
}

.mce-i-pagebreak:before {
	content: "\e027"
}

.mce-i-restoredraft:before {
	content: "\e028"
}

.mce-i-bold:before {
	content: "\e02a"
}

.mce-i-italic:before {
	content: "\e02b"
}

.mce-i-underline:before {
	content: "\e02c"
}

.mce-i-strikethrough:before {
	content: "\e02d"
}

.mce-i-visualchars:before {
	content: "\e02e"
}

.mce-i-visualblocks:before {
	content: "\e02e"
}

.mce-i-ltr:before {
	content: "\e02f"
}

.mce-i-rtl:before {
	content: "\e030"
}

.mce-i-copy:before {
	content: "\e031"
}

.mce-i-resize:before {
	content: "\e032"
}

.mce-i-browse:before {
	content: "\e034"
}

.mce-i-pastetext:before {
	content: "\e035"
}

.mce-i-rotateleft:before {
	content: "\eaa8"
}

.mce-i-rotateright:before {
	content: "\eaa9"
}

.mce-i-crop:before {
	content: "\ee78"
}

.mce-i-editimage:before {
	content: "\e915"
}

.mce-i-options:before {
	content: "\ec6a"
}

.mce-i-flipv:before {
	content: "\eaaa"
}

.mce-i-fliph:before {
	content: "\eaac"
}

.mce-i-zoomin:before {
	content: "\eb35"
}

.mce-i-zoomout:before {
	content: "\eb36"
}

.mce-i-sun:before {
	content: "\eccc"
}

.mce-i-moon:before {
	content: "\eccd"
}

.mce-i-arrowleft:before {
	content: "\edc0"
}

.mce-i-arrowright:before {
	content: "\e93c"
}

.mce-i-drop:before {
	content: "\e935"
}

.mce-i-contrast:before {
	content: "\ecd4"
}

.mce-i-sharpen:before {
	content: "\eba7"
}

.mce-i-resize2:before {
	content: "\edf9"
}

.mce-i-orientation:before {
	content: "\e601"
}

.mce-i-invert:before {
	content: "\e602"
}

.mce-i-gamma:before {
	content: "\e600"
}

.mce-i-remove:before {
	content: "\ed6a"
}

.mce-i-tablerowprops:before {
	content: "\e604"
}

.mce-i-tablecellprops:before {
	content: "\e605"
}

.mce-i-table2:before {
	content: "\e606"
}

.mce-i-tablemergecells:before {
	content: "\e607"
}

.mce-i-tableinsertcolbefore:before {
	content: "\e608"
}

.mce-i-tableinsertcolafter:before {
	content: "\e609"
}

.mce-i-tableinsertrowbefore:before {
	content: "\e60a"
}

.mce-i-tableinsertrowafter:before {
	content: "\e60b"
}

.mce-i-tablesplitcells:before {
	content: "\e60d"
}

.mce-i-tabledelete:before {
	content: "\e60e"
}

.mce-i-tableleftheader:before {
	content: "\e62a"
}

.mce-i-tabletopheader:before {
	content: "\e62b"
}

.mce-i-tabledeleterow:before {
	content: "\e800"
}

.mce-i-tabledeletecol:before {
	content: "\e801"
}

.mce-i-codesample:before {
	content: "\e603"
}

.mce-i-fill:before {
	content: "\e902"
}

.mce-i-borderwidth:before {
	content: "\e903"
}

.mce-i-line:before {
	content: "\e904"
}

.mce-i-count:before {
	content: "\e905"
}

.mce-i-translate:before {
	content: "\e907"
}

.mce-i-drag:before {
	content: "\e908"
}

.mce-i-home:before {
	content: "\e90b"
}

.mce-i-upload:before {
	content: "\e914"
}

.mce-i-bubble:before {
	content: "\e91c"
}

.mce-i-user:before {
	content: "\e91d"
}

.mce-i-lock:before {
	content: "\e926"
}

.mce-i-unlock:before {
	content: "\e927"
}

.mce-i-settings:before {
	content: "\e928"
}

.mce-i-remove2:before {
	content: "\e92a"
}

.mce-i-menu:before {
	content: "\e92d"
}

.mce-i-warning:before {
	content: "\e930"
}

.mce-i-question:before {
	content: "\e931"
}

.mce-i-pluscircle:before {
	content: "\e932"
}

.mce-i-info:before {
	content: "\e933"
}

.mce-i-notice:before {
	content: "\e934"
}

.mce-i-arrowup:before {
	content: "\e93b"
}

.mce-i-arrowdown:before {
	content: "\e93d"
}

.mce-i-arrowup2:before {
	content: "\e93f"
}

.mce-i-arrowdown2:before {
	content: "\e940"
}

.mce-i-menu2:before {
	content: "\e941"
}

.mce-i-newtab:before {
	content: "\e961"
}

.mce-i-a11y:before {
	content: "\e900"
}

.mce-i-plus:before {
	content: "\e93a"
}

.mce-i-insert:before {
	content: "\e93a"
}

.mce-i-minus:before {
	content: "\e939"
}

.mce-i-books:before {
	content: "\e911"
}

.mce-i-reload:before {
	content: "\e906"
}

.mce-i-toc:before {
	content: "\e901"
}

.mce-i-checkmark:before {
	content: "\e033"
}

.mce-i-format-painter:before {
	content: "\e909"
}

.mce-i-checkbox:before,
.mce-i-selected:before {
	content: "\e033"
}

.mce-i-insert {
	font-size: 14px
}

.mce-i-selected {
	visibility: hidden
}

i.mce-i-backcolor {
	text-shadow: none;
	background: #BBB
}

.mce-rtl .mce-filepicker input {
	direction: ltr
}
