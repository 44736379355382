.br-wrapper{
	text-align: center;
}
.br-theme-fontawesome-stars {

	.br-widget {
		height: 28px;
		white-space: nowrap;

		a {
			// font: normal normal normal 1rem/1 'Font Awesome 5 Free';
			@extend .fas;
			font-size: $font-size-base;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			text-decoration: none;
			margin-right: 2px;
		}

		a:after {
			content: '\f005';
			color: $star-default;
		}

		a.br-active:after {
			color: $star-active;
		}

		a.br-selected:after {
			color: $star-selected;
		}

		.br-current-rating {
			display: none;
		}
	}

	.br-readonly {
		a {
			cursor: default;
		}
	}

}

@media print {
	.br-theme-fontawesome-stars {

		.br-widget {
			a:after {
				content: '\f006';
				color: $black;
			}

			a.br-active:after,
			a.br-selected:after {
				content: '\f005';
				color: $black;
			}
		}

	}
}





.br-theme-fontawesome-stars-o {

	.br-widget {
		height: 28px;
		white-space: nowrap;

		a {
			// font: normal normal normal 1rem/1 'Font Awesome 5 Free';
			@extend .fas;
			font-size: $font-size-base;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			text-decoration: none;
			margin-right: 2px;
		}

		a:after {
			content: '\f005';
			color: $star-default;
		}

		a.br-active:after {
			content: '\f005';
			color: $orange-color;
		}

		a.br-selected:after {
			content: '\f005';
			color: $orange-color;
		}

		a.br-fractional:after {
			content: '\f5c0';
			color: $orange-color;
		}

		.br-current-rating {
			display: none;
		}
	}

	.br-readonly {
		a {
			cursor: default;
		}
	}

	.br-reverse {
		a.br-fractional {
			display: inline-block;
			transform: scaleX(-1);
			-moz-transform: scaleX(-1);
			-webkit-transform: scaleX(-1);
			filter: FlipH;
			-ms-filter: "FlipH";
		}
	}

}

@media print {
	.br-theme-fontawesome-stars-o {

		.br-widget {
			a:after {
				content: '\f005';
				color: $black;
			}

			a.br-active:after,
			a.br-selected:after {
				content: '\f005';
				color: $black;
			}

			a.br-fractional:after {
				content: '\f5c0';
				color: $black;
			}
		}

	}
}
