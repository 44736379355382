.footer{
	background-color: $primary-dark;
	.row{
		width: 100%;
		margin: 0;
	}
	.list-inline{
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
	}
	&-top, &-bottom{
		display: flex;
		align-items: center;
	}
	&-top{
		padding: 30px 0;
		.container{
			.row{
				div[class*="col"]{
					display: flex;
					align-items: center;
					@include media-breakpoint-down(md) {
						&:not(:last-of-type){
							margin-bottom: $grid-gutter-width;
						}
					}
				}
			}
		}
	}
	.navbar-brand{
		img{
			opacity: 0.5;
			transition: $transition-base;
		}
		&:hover{
			img{
				opacity: 1;
			}
		}
	}
	&-bottom{
		padding: 13px 0;
		background-color: darken($primary-dark, 5%);
		.container{
			.row{
				div{
					display: flex;
					align-items: center;
					justify-content: center;
					p{
						color: $white;
						text-align: center;
						margin: 0;
						font-size: $font-size-sm;
						font-family: inherit;
						font-weight: $font-weight-light;
						transition: $transition-base;
						opacity: 0.5;
						&:hover{
							opacity: 1;
						}

						a{
							&, &:hover{
								text-decoration: underline;
								color: $white;
								margin-left: 5px;
							}
						}
					}
				}
			}
		}
	}
	&-links{
		.list-inline-item{
			a{
				opacity: 0.5;
				transition: $transition-base;
				&:hover{
					opacity: 1;
				}
			}
			a, &:hover{
				font-size: $font-size-sm;
				font-weight: $font-weight-light;
				font-family: inherit;
				color: $white;
				text-align: center;
				text-transform: capitalize;
			}
			&:not(:last-of-type){
				margin-right: 20px;
				position: relative;
				&:after{
					content: '-';
					position: absolute;
					right: -12px;
					top: 50%;
					transform: translateY(-50%);
					font-size: $font-size-sm;
					color: $white;
					opacity: 0.5;
				}
			}
		}
	}
	&-social{
		.list-inline-item{
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: $border-width solid $white;
			@include border-radius(50%);
			opacity: 0.5;
			transition: $transition-base;
			&:hover{
				opacity: 1;
			}
			a{
				display: flex;
				align-items: center;
				justify-content: center;
				&, &:hover{
					color: $white;
					width: 100%;
					height: 100%;
					padding: 0;
				}
				i{
					font-size: $font-size-sm;
				}
			}
			&:not(:last-of-type){
				margin-right: 10px;
			}
		}
	}
	&-apps{
		.list-inline-item{
			a{
				img{
					opacity: 0.75;
					width: 100%;
					transition: $transition-base;
				}
			}
			&:hover{
				a{
					img{
						opacity: 1;
					}
				}
			}
		}

	}
}