//*********************************  accourdion  *********************************//
.accordion {
	overflow: hidden;
	.card {
		@include border-radius(0);
		&-header {
			padding: 0;
			.dropdown, .dropleft {
				.dropdown-menu{
					transform: translate3d(-150px, 24px, 0) #{'/*!rtl: translate3d(0, 24px, 0)*/'} !important;
				}
			}
			h6 {
				button {
					text-transform: capitalize;
					font-weight: $font-weight-bold;
					background-color: $white;
					width: 100%;
					position: relative;
					text-align: left;
					padding: 1rem 1.25rem;
					@include border-radius(0);
					&, &:hover {
						color: $black;
					}
					&:after {
						@include dropdown-arrow($danger);
						background-position-x: center !important;
						width: 23px;
						height: 10px;
						position: absolute;
						right: 20px;
						top: 50%;
						transform: translateY(-50%);
						transform: rotate(-90deg);
						transition: $transition-base;
					}
					&[aria-expanded="true"] {
						&:after {
							background-position-x: center !important;
							transform: rotate(0deg);
						}
					}
				}
			}
		}
		&-body{
			// this for multi level in.
			&>.accordion {
				&>.card {

					// margin-left: 1rem;
					&>.card-header {
						padding: 0;

						h6 {
							button {
								text-transform: uppercase !important;
								font-weight: $font-weight-normal !important;
								padding: 1rem 2rem !important;
							}
						}
					}

					&>.collapse,
					&>.collapsing {
						&>.card-body {
							.list-group {
								&-item {
									padding-left: 3rem !important;
								}
							}
						}
					}

				}
			}
		}
	}
	&-dark {
		.collapse, .collapsing {
			background-color: $primary-dark;
		}
		.card {
			&-body {
				background-color: inherit;
			}
			&-header {
				h6 {
					button {
						background-color: $primary-dark;
						color: $white;

						&:hover {
							color: $white;
						}

						&:after {
							@include dropdown-arrow($white);
							background-position-x: center !important;
							width: 23px;
							height: 10px;
							position: absolute;
							right: 20px;
							top: 50%;
							transform: translateY(-50%);
							transform: rotate(-90deg);
							transition: $transition-base;
						}
					}
				}
			}
		}
	}
}

//*********************************  inner content accourdion  *********************************//
.inner-content {
	.card {
		&,
		.accordion {
			overflow: initial;
		}
		&-header {
			background-color: $white;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:not(.accordion .card) {
				padding: 1rem;
			}
		}

		a.card-title {
			color: $black;
			width: 100%;
			text-transform: capitalize;
		}

		// &:not(:last-of-type) {
		// 	margin-bottom: $grid-gutter-width;
		// }

		&>.accordion {
			padding: 0 1.1875rem;

			&>.card {
				&>.card-header {
					h6 {
						button {
							font-weight: $font-weight-bold;

							&[aria-expanded="false"] {
								border-bottom: 0 !important;
							}
						}
					}
				}
			}
		}
	}

	.accordion {
		.card {
			&-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: $white;
				padding: 0px;

				h6 {
					width: 100%;

					button {
						padding-right: 0;
						font-weight: $font-weight-light;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						width: 98%;
						border-bottom: $border-width solid rgba($border-color, 0.1);

						&:after {
							content: none;
						}

						&:before {
							@include dropdown-arrow($danger);
							background-position-x: center !important;
							width: 23px;
							height: 10px;
							position: absolute;
							left: -5px;
							top: 45%;
							transform: translateY(-45%);
							transform: rotate(-90deg);
							transition: $transition-base;
						}

						&[aria-expanded="true"] {
							&:before {
								background-position-x: center !important;
								transform: rotate(0deg);
							}
						}
					}
				}
			}

			&-body {
				padding: 0px;

				.list-group {
					&-item {
						&.last-level {
							display: flex;
							align-items: center;
							justify-content: space-between;
							background-color: $white;
							& > a:not(.dropdown-item) {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								width: 95%;
							}
						}
						&:hover {
							border-left-color: transparent;
							background-color: $white;
						}
						&:not(:last-of-type){
							& > a{
								border-bottom-color: rgba($border-color, 0.1);
							}
						}
						&:last-of-type {
							& > a {
								border-bottom-color: transparent;
							}
							&>.card {
								&>.card-header {
									button[aria-expanded="false"] {
										border-bottom-color: transparent;
									}
								}
							}
						}

					}
				}
			}
		}
	}
}