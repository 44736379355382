// Windget variants
//
// Easily pump out default styles, as well as :hover,
// and disabled options for all Windgets

@mixin widget-variant($background, $border: $background, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%)) {
	color: color-yiq($background);
	@include gradient-bg($background);
	border-color: $border;
	@include box-shadow($widget-box-shadow);
	@include hover {
		color: color-yiq($hover-background);
		@include gradient-bg($hover-background);
		border-color: $hover-border;
	}
}

@mixin widget-outline-variant($background, $color, $color-hover: color-yiq($color), $hover-background: darken($background, 7.5%), $hover-border: darken($color, 10%)) {
	color: $color;
	background-color: transparent;
	background-image: none;
	border-color: $color;
	@include hover {
		color: color-yiq($hover-background);
		@include gradient-bg($hover-background);
		border-color: $hover-border;
		&>*{
			color: color-yiq($color) !important;
		}
	}
}

// widget sizes
@mixin widget-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
	padding: $padding-y $padding-x;
	font-size: $font-size;
	line-height: $line-height;

	// Manually declare to provide an override to the browser default
	@if $enable-rounded {
		border-radius: $border-radius;
	}

	@else {
		border-radius: 0;
	}
}


@mixin widget-text($font-weight, $color: inherit, $font-size: $font-size-base, $text-transform:none, $font-family: inherit, $display: inline-block) {
	color: $color;
	font-weight: $font-weight;
	text-transform: $text-transform;
	font-family: $font-family;
	font-size: $font-size;
	display: $display;
}