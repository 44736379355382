.ev_noti {
    a.btn.btn-danger {
        border-radius: 30rem;
        @media(max-width: 767px) {
            max-width: 25rem;
            margin-top: 1.5rem;
        }
    }

    .inner-content .accordion .card-header h6 button:before {
        display: none;
    }
}

.evNotiAdd {
    p.inner-form-title {
        color :#6F6F6F;
        margin-bottom: .3rem;
    }
}

// .custom-lang-select {
//     width: 100%;
//     height: 44px;
//     border-radius: 0.75rem;
//     padding: 0px 16px;
//     background-color: #EFF1F6;
//     outline: none;
//     border-color: #DFE0E1ff;
//     -moz-appearance: none;
//     -webkit-appearance: none;
// 	position: relative;
// 	color: #7D7D7E;

// }

// .custom-lang-select option {
// 	background-color: #EFF1F6;
// 	color: #7D7D7E;
//     padding: .5rem;
// }

// .custom-lang-arrow {
//     position: absolute;
//     z-index: 1;
//     right: 16px;
//     top: 16px;
// 	color: #7D7D7E;
// }

// .ar-item {
//     display: none;
// }