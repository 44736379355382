// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.widget {
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	justify-content: flex-start;
	font-weight: $widget-font-weight;
	text-transform: none;
	text-align: left;
	vertical-align: middle;
	user-select: none;
	border: $widget-border-width solid transparent;
	@include widget-size($widget-padding-y, $widget-padding-x, $font-size-base, $widget-line-height, $widget-border-radius);
	@include transition($widget-transition);
	@include box-shadow($widget-active-box-shadow);

	// Share hover and focus styles
	@include hover-focus {
		text-decoration: none;
	}

	+.widget:not(.widget-block) {
		margin-left: $widget-block-spacing-y;
	}

}

//
// Alternate Widgets
//

@each $color, $value in $theme-colors {
	.widget-#{$color} {
		@include widget-variant($value, transparent);
		.widget-title {
			@include widget-text($color: color-yiq($value), $font-size: $font-size-lg*1.5, $font-weight: $font-weight-bold, $text-transform: capitalize);
			+.widget-stat {margin-left: $widget-block-spacing-y;}
		}
		.widget-stat {
			@include widget-text($color: color-yiq($value), $font-weight: $font-weight-normal, $text-transform: capitalize);
		}
		.widget-text {
			@include widget-text($color: color-yiq($value), $font-weight: $font-weight-bold, $font-size: $font-size-sm, $text-transform: capitalize, $display: block);
			opacity: 0.75;
		}
	}
}

@each $color, $value in $theme-colors {
	.widget-outline-#{$color} {
		@include widget-outline-variant($value, $value);
		.widget-title {
			@include widget-text($color: $value, $font-size: $font-size-lg*1.5, $font-weight: $font-weight-bold, $text-transform: capitalize);
			+.widget-stat {margin-left: $widget-block-spacing-y;}
		}
		.widget-stat {
			@include widget-text($color: $value, $font-weight: $font-weight-normal, $text-transform: capitalize);
		}
		.widget-text {
			@include widget-text($color: $value, $font-weight: $font-weight-bold, $font-size: $font-size-sm, $text-transform: capitalize, $display: block);
			opacity: 0.75;
		}
	}
}


//
// widgets Sizes
//

.widget-lg {
	@include widget-size($widget-padding-y-lg, $widget-padding-x-lg, $font-size-lg, $widget-line-height-lg, $widget-border-radius-lg);
}

.widget-sm {
	@include widget-size($widget-padding-y-sm, $widget-padding-x-sm, $font-size-sm, $widget-line-height-sm, $widget-border-radius-sm);
}


//
// Block widgets
//

.widget-block {
	display: block;
	width: 100%;

	// Vertically space out multiple block widgets
	+.widget-block {margin-top: $widget-block-spacing-y;}
}