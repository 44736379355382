/*
      █████╗ ██╗     ███████╗██████╗ ████████╗███████╗
    ██╔══██╗██║     ██╔════╝██╔══██╗╚══██╔══╝██╔════╝
   ███████║██║     █████╗  ██████╔╝   ██║   ███████╗
  ██╔══██║██║     ██╔══╝  ██╔══██╗   ██║   ╚════██║
 ██║  ██║███████╗███████╗██║  ██║   ██║   ███████║
╚═╝  ╚═╝╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
*/

.alerts-wrapper {
	position: fixed;
	top: 100px;
	right: 90px;
	z-index: $zindex-alerts-wrapper + 10; // must be on top of everything
	max-width: calc(50% - 90px);
	width: calc(50% - 90px);
	height: auto;
	@include media-breakpoint-down(lg){
		max-width: calc(50% - 30px);
		width: calc(50% - 30px);
		right: 30px;
	}
	@include media-breakpoint-down(md){
		max-width: calc(50% - 15px);
		width: calc(50% - 15px);
		right: 15px;
	}
	@include media-breakpoint-down(sm){
		max-width: calc(100% - 20px);
		width: calc(100% - 20px);
		right: 10px;
		left: 10px;
	}
}
.alert{
	width: 100%;
	margin:0;

	&:not(:last-of-type){margin-bottom: $alert-margin-bottom}

	&-heading, p{
		color: $white;
		font-family: inherit;
		margin: 0;
	}
	&-heading{
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}
	p{
		font-weight: $font-weight-light;
		white-space: pre-line;
		max-width: 100%;
		font-size: $font-size-sm;
	}
	.close{
		&,
		&:not(:disabled):not(.disabled):hover,
		&:not(:disabled):not(.disabled):focus {
			color: $white;
			box-shadow: none;
			transition: $transition-fade;
		}
		@include hover{opacity: 1}
	}
}