.flatpickr-calendar {
	background: transparent;
	opacity: 0;
	display: none;
	text-align: center;
	visibility: hidden;
	padding: 0;
	-webkit-animation: none;
	animation: none;
	direction: ltr;
	border: 0;
	font-size: $font-size-sm;
	line-height: 24px;
	@include border-radius(5px);
	position: absolute;
	width: 307.875px;
	box-sizing: border-box;
	touch-action: manipulation;
	@include box-shadow(0 3px 13px rgba($black, 0.08));
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
	opacity: 1;
	max-height: 640px;
	visibility: visible;
}

.flatpickr-calendar.open {
	display: inline-block;
	z-index: $zindex-overlay-close+10;
}

.flatpickr-calendar.animate.open {
	-webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
	animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
	display: block;
	position: relative;
	top: 2px;
}

.flatpickr-calendar.static {
	position: absolute;
	top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
	z-index: 999;
	display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
	@include box-shadow(none !important);
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
	@include box-shadow(-2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6);
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
	border-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
	border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
	height: 40px;
	border-top: $border-width solid rgba($border-color, 0.1);
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-innerContainer {
	border-bottom: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
	border: $border-width solid rgba($border-color, 0.1);
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
	height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
	position: absolute;
	display: block;
	pointer-events: none;
	border: solid transparent;
	content: '';
	height: 0;
	width: 0;
	left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
	left: auto;
	right: 22px;
}

.flatpickr-calendar:before {
	border-width: 5px;
	margin: 0 -5px;
}

.flatpickr-calendar:after {
	border-width: 4px;
	margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
	bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
	border-bottom-color: rgba($border-color, 0.1);
}

.flatpickr-calendar.arrowTop:after {
	border-bottom-color: $primary;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
	top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
	border-top-color: rgba($border-color, 0.1);
}

.flatpickr-calendar.arrowBottom:after {
	border-top-color: $primary;
}

.flatpickr-calendar:focus {
	outline: 0;
}

.flatpickr-wrapper {
	position: relative;
	display: inline-block;
}

.flatpickr-months {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.flatpickr-months .flatpickr-month {
	@include border-radius(5px 5px 0 0);
	background: $primary;
	color: $white;
	fill: $white;
	height: 28px;
	line-height: 1;
	text-align: center;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	overflow: hidden;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
	text-decoration: none;
	cursor: pointer;
	position: absolute;
	top: 0px;
	line-height: 16px;
	height: 28px;
	padding: 10px;
	z-index: 3;
	color: $white;
	fill: $white;
}

.flatpickr-months .flatpickr-prev-month.disabled,
.flatpickr-months .flatpickr-next-month.disabled {
	display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
	position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
	left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
	right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
	color: $light;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
	fill: $white;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
	width: 14px;
	height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
	-webkit-transition: fill 0.1s;
	transition: fill 0.1s;
	fill: inherit;
}

.numInputWrapper {
	position: relative;
	height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
	display: inline-block;
}

.numInputWrapper input {
	width: 100%;
}

.numInputWrapper input::-ms-clear {
	display: none;
}

.numInputWrapper span {
	position: absolute;
	right: 0;
	width: 14px;
	padding: 0 4px 0 2px;
	height: 50%;
	line-height: 50%;
	opacity: 0;
	cursor: pointer;
	border: $border-width solid rgba($border-color, 0.1);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.numInputWrapper span:hover {
	background: rgba($black, 0.1);
}

.numInputWrapper span:active {
	background: rgba($black, 0.2);
}

.numInputWrapper span:after {
	display: block;
	content: "";
	position: absolute;
}

.numInputWrapper span.arrowUp {
	top: 0;
	border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
	border-left: $border-width*4 solid transparent;
	border-right: $border-width*4 solid transparent;
	border-bottom: $border-width*4 solid rgba($border-color, 0.1);
	top: 26%;
}

.numInputWrapper span.arrowDown {
	top: 50%;
}

.numInputWrapper span.arrowDown:after {
	border-left: $border-width*4 solid transparent;
	border-right: $border-width*4 solid transparent;
	border-top: $border-width*4 solid rgba($border-color, 0.1);
	top: 40%;
}

.numInputWrapper span svg {
	width: inherit;
	height: auto;
}

.numInputWrapper span svg path {
	fill: rgba(255, 255, 255, 0.5);
}

.numInputWrapper:hover {
	background: rgba($black, 0.05);
}

.numInputWrapper:hover span {
	opacity: 1;
}

.flatpickr-current-month {
	font-size: 135%;
	line-height: inherit;
	font-weight: $font-weight-light;
	color: inherit;
	position: absolute;
	width: 75%;
	left: 12.5%;
	padding: 6.16px 0 0 0;
	line-height: 1;
	height: 28px;
	display: inline-block;
	text-align: center;
	-webkit-transform: translate3d(0px, 0px, 0px);
	transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
	font-family: inherit;
	font-weight: $font-weight-bold;
	color: inherit;
	display: inline-block;
	margin-left: 0.5ch;
	padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
	background: rgba($black, 0.05);
}

.flatpickr-current-month .numInputWrapper {
	width: 6ch;
	width: 7ch\0;
	display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
	border-bottom-color: $border-color;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
	border-top-color: $border-color;
}

.flatpickr-current-month input.cur-year {
	background: transparent;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: inherit;
	cursor: text;
	padding: 0 0 0 0.5ch;
	margin: 0;
	display: inline-block;
	font-size: inherit;
	font-family: inherit;
	font-weight: $font-weight-light;
	line-height: inherit;
	height: auto;
	border: 0;
	@include border-radius(0);
	vertical-align: initial;
}

.flatpickr-current-month input.cur-year:focus {
	outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
	font-size: 100%;
	color: rgba(255, 255, 255, 0.5);
	background: transparent;
	pointer-events: none;
}

.flatpickr-weekdays {
	background: $primary;
	text-align: center;
	overflow: hidden;
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

span.flatpickr-weekday {
	cursor: default;
	font-size: 90%;
	background: $primary;
	color: $white;
	line-height: 1;
	margin: 0;
	text-align: center;
	display: block;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	font-weight: $font-weight-light;
}

.dayContainer,
.flatpickr-weeks {
	padding: 1px 0 0 0;
}

.flatpickr-days {
	position: relative;
	overflow: hidden;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 307.875px;
	border-left: $border-width solid rgba($border-color, 0.2);
	border-right: $border-width solid rgba($border-color, 0.2);
}

.flatpickr-days:focus {
	outline: 0;
}

.dayContainer {
	padding: 0;
	outline: 0;
	text-align: left;
	width: 307.875px;
	min-width: 307.875px;
	max-width: 307.875px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	display: -ms-flexbox;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-ms-flex-pack: justify;
	-webkit-justify-content: space-around;
	justify-content: space-around;
	-webkit-transform: translate3d(0px, 0px, 0px);
	transform: translate3d(0px, 0px, 0px);
	opacity: 1;
}

.dayContainer+.dayContainer {
	@include box-shadow(-1px 0 0 rgba($border-color, 0.2));
}

.flatpickr-day {
	background: none;
	border: 1px solid transparent;
	@include border-radius($border-radius);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #484848;
	cursor: pointer;
	font-weight: $font-weight-normal;
	width: 14.2857143%;
	-webkit-flex-basis: 14.2857143%;
	-ms-flex-preferred-size: 14.2857143%;
	flex-basis: 14.2857143%;
	max-width: 39px;
	height: 39px;
	line-height: 39px;
	margin: 0;
	display: inline-block;
	position: relative;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
	cursor: pointer;
	outline: 0;
	background: $body-bg;
	border-color: $border-color;
}

.flatpickr-day.today {
	border-color: $primary;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
	border-color: $primary;
	background: $body-bg;
	color: $primary;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
	background: $primary;
	@include box-shadow(none);
	color: $white;
	border-color: $primary;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
	@include border-radius(50px 0 0 50px);
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
	@include border-radius(0 50px 50px 0);
}

.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
	@include box-shadow(-10px 0 0 $primary);
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
	@include border-radius(50px);
}

.flatpickr-day.inRange {
	@include border-radius(0);
	@include box-shadow(-5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2);
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
	color: rgba($dark, 0.3);
	background: transparent;
	border-color: transparent;
	cursor: default;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
	cursor: not-allowed;
	color: rgba($dark, 0.1);
}

.flatpickr-day.week.selected {
	@include border-radius(0);
	@include box-shadow(-5px 0 0 $primary, 5px 0 0 $primary);
}

.flatpickr-day.hidden {
	visibility: hidden;
}

.rangeMode .flatpickr-day {
	margin-top: 1px;
}

.flatpickr-weekwrapper {
	display: inline-block;
	float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
	padding: 0 12px;
	border-left: 1px solid rgba($dark, 0.2);
}

.flatpickr-weekwrapper .flatpickr-weekday {
	float: none;
	width: 100%;
	line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
	display: block;
	width: 100%;
	max-width: none;
	color: rgba($dark, 0.3);
	background: transparent;
	cursor: default;
	border: none;
}

.flatpickr-innerContainer {
	display: block;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	background: $white;
	border-bottom: 1px solid rgba($dark, 0.2);
}

.flatpickr-rContainer {
	display: inline-block;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.flatpickr-time {
	text-align: center;
	outline: 0;
	display: block;
	height: 0;
	line-height: 40px;
	max-height: 40px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	background: $white;
	@include border-radius(0 0 5px 5px);
}

.flatpickr-time:after {
	content: "";
	display: table;
	clear: both;
}

.flatpickr-time .numInputWrapper {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	width: 40%;
	height: 40px;
	float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
	border-bottom-color: $border-color;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
	border-top-color: $border-color;
}

.flatpickr-time.hasSeconds .numInputWrapper {
	width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
	width: 49%;
}

.flatpickr-time input {
	background: transparent;
	@include box-shadow(none);
	border: 0;
	@include border-radius(0);
	text-align: center;
	margin: 0;
	padding: 0;
	height: inherit;
	line-height: inherit;
	color: #484848;
	font-size: $font-size-sm;
	position: relative;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.flatpickr-time input.flatpickr-hour {
	font-weight: $font-weight-bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
	font-weight: $font-weight-normal;
}

.flatpickr-time input:focus {
	outline: 0;
	border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
	height: inherit;
	display: inline-block;
	float: left;
	line-height: inherit;
	color: #484848;
	font-weight: $font-weight-bold;
	width: 2%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
	outline: 0;
	width: 18%;
	cursor: pointer;
	text-align: center;
	font-weight: $font-weight-normal;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
	background: #efefef;
}

.flatpickr-input[readonly] {
	cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fpFadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}



html[dir="rtl"] {

	.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
	.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
		left: initial !important;
		right: 0 !important;
	}

	.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
	.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
		right: initial !important;
		left: 0 !important;
	}

	.flatpickr-calendar:before,
	.flatpickr-calendar:after {
		left: initial !important;
		right: 22px;
	}

	.flatpickr-months .flatpickr-month {
		height: 40px;
	}
}