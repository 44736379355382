.nav-steps{
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	&:after {
		content: '';
		display: inline-block;
		width: 90%;
		height: $border-width;
		border-bottom: $border-width dotted $border-color;
		position: absolute;
		top: 25%;
		left: 50%;
		transform: translate(-50%, -25%);
	}
	.steps{
		&-single{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			i.material-icons {
				color: $white;
				background-color: $success;
				margin-top: -8px;
				font-size: $font-size-sm;
				@include border-radius(50%);
				z-index: 3;
				visibility: hidden;
			}
			&-completed{
				i.material-icons{
					visibility: visible;
				}
			}
		}
		&-link{
			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $primary;
			background-color: $body-bg;
			border: $border-width solid $primary;
			@include border-radius(50%);
			font-weight: $font-weight-normal;
			font-size: 1.25rem;
			font-family: inherit;
			z-index: 2;
			&.active{
				color: $white;
				background-color: $primary;
				font-weight: $font-weight-bold;
				border-color: transparent;
			}
		}
		&-title{
			font-family: inherit;
			font-weight: $font-weight-bold;
			font-size: 75%;
			color: $black;
			text-transform: uppercase;
			z-index: 4;
			text-align: center;
		}
	}
}