.home {
	&-sec-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: $grid-gutter-width;

		h2,
		h3 {
			color: $black;
			text-transform: uppercase;
			font-weight: $font-weight-bold;
		}

		h2 {
			width: 100%;
			text-align: center;
		}
	}

	&-main {
		background-color: rgba($gray, 0.6);
		min-height: calc(90vh);
		height: calc(90vh);

		.main-content{
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			h1 {
				color: $white;
				text-transform: uppercase;
				text-align: center;
				width: 35%;
				@include media-breakpoint-down(lg) {
					width: 50%;
				}
				@include media-breakpoint-down(xs) {
					width: 100%;
				}
			}
			.btn{
				@include border-radius($input-border-radius);
			}
		}
		.swiper{
			&-container, &-wrapper, &-slide{
				height: 100%;
			}
			&-slide{
				background-color: $black;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				.container{
					height: 100%;
					.row{
						height: 100%;
					}
				}
			}
			&-pagination {
				&-bullet {
					&:not(.swiper-pagination-bullet-active) {
						background-color: #838383;
					}
					&-active {
						background-color: $primary;
					}
				}
			}
		}
		@media screen and (max-height: 600px) {
			height: 700px;
		}
	}
}

section {
	&[class*="home"]:not(.home-main) {
		padding:80px 0 20px 0;
		background-color: rgba($gray, 0.6);

		&>.container-fluid {
			&>.row {
				&>div[class*="col-"] {
					padding-right: 0;
					padding-left: 0;
				}

				.swiper {
					&-container {
						padding: 0 5rem 5rem 5rem;
						@include media-breakpoint-down(sm) {
							padding: 0 1rem 5rem;
						}
					}

					&-pagination {
						&-bullet {
							&:not(.swiper-pagination-bullet-active) {
								background-color: $gray-500;
							}

							&-active {
								background-color: $primary;
							}
						}
					}
				}
			}
		}
	}

	&[class*="-picked"] {
		background-color: $gray-700;

		&>.container-fluid {
			&>.row {
				.swiper {
					&-pagination {
						&-bullet {
							&:not(.swiper-pagination-bullet-active) {
								background-color: $white;
							}

							&-active {
								background-color: $primary;
							}
						}
					}
				}
			}
		}
		.home-sec-head {
			h2,h3 {color: $white;}
		}
	}

	&[class*="-best-price"] {
		background-color: $black !important;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		.process-steps{
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 65%;
			margin: $grid-gutter-width auto;
			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}
			@include media-breakpoint-down(sm) {
				max-width: 75%;
			}
			h6, p{
				color: $white;
				font-family: inherit;
				font-weight: $font-weight-bold;
			}
			h6{
				font-size: 5.3125rem;
				flex: 3 0 25%;
				width: 25%;
				max-width: 25%;
				text-align: right;
				margin-right: 10px;
			}
			p {
				text-align: left;
				line-height: 22px;
				font-size: 1.1875rem;
				flex: 9 0 75%;
				width: 75%;
				max-width: 75%;
			}
		}
	}

}