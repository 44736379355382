/*
     ███╗   ██╗ █████╗ ██╗   ██╗██████╗  █████╗ ██████╗
    ████╗  ██║██╔══██╗██║   ██║██╔══██╗██╔══██╗██╔══██╗
   ██╔██╗ ██║███████║██║   ██║██████╔╝███████║██████╔╝
  ██║╚██╗██║██╔══██║╚██╗ ██╔╝██╔══██╗██╔══██║██╔══██╗
 ██║ ╚████║██║  ██║ ╚████╔╝ ██████╔╝██║  ██║██║  ██║
╚═╝  ╚═══╝╚═╝  ╚═╝  ╚═══╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝
*/
.navbar {
	color: $white;
	transition: $transition-base;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	@include border-bottom-radius($card-border-radius);
	border-bottom: $border-width solid $border-color;

	&.fixed-top {
		transition: $transition-base;
	}

	.row {
		margin-right: 0;
		margin-left: 0;
	}

	&-brand {
		width: 100%;
		height: 65px;
		max-height: 65px;

		img {
			width: auto;
			height: 100%;
			@include media-breakpoint-down(md) {
				width: auto;
			}
		}
	}

	&-menu,
	&-search,
	&-dropdown,
	&-auth {
		display: flex;
		align-items: center;
	}

	

	&-auth {
		justify-content: flex-end;

		a.btn {
			width: 120px;

			&:not(:last-of-type) {
				margin-right: 10px;
			}

			&-white {
				color: $primary;
				border-color: transparent;

				&:hover {
					color: $white;
					border-color: $white;
					background-color: transparent;
				}
			}

			&-outline-white {
				&:hover {
					color: $primary;
				}
			}
		}
	}

	&-dropdown {
		justify-content: flex-end;

		.dropdown {
			width: 100%;

			&-toggle {
				width: 100%;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				border: 0;
				background-color: $primary !important;
				@include border-radius($border-radius-lg);
				transition: $transition-base;
				.user-img-sm {
					padding: 2px;
				}
				span {
					font-size: $font-size-sm;
					color: $white;
					text-transform: capitalize;
					text-align: center;
					margin-left: 0;
					margin-right: 30px;
					font-weight: $font-weight-light;
					@extend .text-truncate;
				}
				&:after {
					@include dropdown-arrow($white);
					position: absolute;
					right: 0;
				}
			}
			&-menu {
				background-color: rgba($black, 0.75);
				@include border-radius($card-border-radius/2);
				overflow: hidden;
				border: 0;
				padding-right: 0.75rem;
				padding-left: 0.75rem;
				width: 100%;
			}
			&-item {
				color: $white;
				font-size: $font-size-base;
				text-transform: capitalize;
				text-align: left;
				font-weight: $font-weight-light;
				padding-left: 0;
				padding-right: 0;
				position: relative;
				span.badge {
					color: $white;
					font-weight: $font-weight-light;
					position: absolute;
					top: 10px;
					padding-right: 0.4rem;
					padding-left: 0.4rem;
					font-size: $font-size-sm*0.75;
				}
				&:not(:last-of-type) {
					border-bottom: $border-width solid rgba($white, 0.3);
				}
				&:hover {
					background-color: transparent;
				}
			}
		}
	}

	// NOTE: Uncomment this to add scrolling feature.
	// &.home-navbar {
	// 	.navbar-dropdown {
	// 		.dropdown {
	// 			&-toggle {
	// 				background-color: rgba($white, 0.5) !important;
	// 			}
	// 		}
	// 	}
	// 	.navbar-search {
	// 		visibility: hidden;
	// 	}
	// 	&.scrolling {
	// 		.navbar-dropdown {
	// 			.dropdown {
	// 				&-toggle {
	// 					background-color: $gray-400 !important;
	// 				}
	// 			}
	// 		}
	// 		.navbar-search {
	// 			visibility: visible;
	// 		}
	// 	}
	// }
	// &.inner-navbar {
	// 	@include box-shadow($box-shadow-sm);
	// }

	&-search {
		form.form-inline {
			width: 100%;
			overflow: hidden;
			.input-group {
				width: 100%;
				.form-control {
					color: $input-color;
					font-weight: $font-weight-light;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					position: relative;
					border: 0 ;
					margin: 0 !important;
					@extend .text-truncate;
					&::placeholder {
						color: $input-color;
						text-transform: capitalize;
						@extend .text-truncate;
					}
					&:first-of-type{
						@include border-left-radius($border-radius-lg);
					}
					&.location-search{
						& ~ label{
							background-color: $input-bg;

							// NOTE: Uncomment this to add arrow for header location seach.
							// &:before {
							// 	@include dropdown-arrow($input-color);
							// 	background-position-x: 0.2rem !important;
							// }
						}
					}
				}
				label{
					&:not(:last-of-type){
						position: relative;
						&:after {
							content: '';
							width: $border-width;
							height: 50%;
							background-color: rgba($input-color, 0.4);
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
							z-index: 4;
						}
					}
				}
				&-append{
					margin: 0 !important;
				}
				.btn[type="submit"] {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					@include border-right-radius($border-radius-lg);
					background-color: $input-bg;
					border: 0;
					padding: 0.7rem $input-btn-padding-x 0.7rem $input-btn-padding-x;
					display: flex;
					align-items: center;
					justify-content: center;
					i {
						color: $primary;
						font-size: $font-size-lg !important;
					}
				}
			}

		}
	}

	&-menu {
		justify-content: flex-end;
		padding-right: 0;
	}
}



/*
     ███████╗██╗██████╗ ███████╗    ███╗   ███╗███████╗███╗   ██╗██╗   ██╗
    ██╔════╝██║██╔══██╗██╔════╝    ████╗ ████║██╔════╝████╗  ██║██║   ██║
   ███████╗██║██║  ██║█████╗      ██╔████╔██║█████╗  ██╔██╗ ██║██║   ██║
  ╚════██║██║██║  ██║██╔══╝      ██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║   ██║
 ███████║██║██████╔╝███████╗    ██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╔╝
╚══════╝╚═╝╚═════╝ ╚══════╝    ╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝
*/
.menu{
	&-open {
		font-size: $font-size-base*2;
		cursor: pointer;
		color: $primary;
	}
	&-close {
		position: absolute;
		top: 2.5vh;
		right: 2vw;
		font-size: $font-size-base*2;
		color: $white;
		cursor: pointer;
		z-index: $zindex-overlay-close+50;
		@include media-breakpoint-down(sm) {
			font-size: $font-size-base * 1.5;
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	right: 0;
	z-index: $zindex-overlay-menu;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	min-height: 100vh;
	width: 100vw;
	min-height: 100vh;
	@include box-shadow($box-shadow-lg);
	@include media-breakpoint-down(sm){ // same breakpoint for nav--sub & nav--main
		flex-wrap: wrap;
	}
	&:not(.open) {
		display: none;
	}

	&.open {
		overflow-y: scroll;
		height: 100%;

		/* width */
		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	//  Navs
	&__nav {
		padding: 40px 80px;
		@include media-breakpoint-down(md) {
			padding: 20px 40px;
		}
		width: 100%;
		height: 100vh;
		max-height: 100vh;
		@media screen and (max-height: 700px) {
			height: 100%;
		}
		ul {
			margin: 0;
			padding: 0;
			li {
				list-style: none;

				&:not(:last-of-type) {
					margin-bottom: 2.5rem;
				}

				a {
					color: $white;
					font-weight: $font-weight-light;
					text-transform: uppercase;
					font-size: $font-size-base;
					text-align: left;
					width: 100%;
					display: block;
				}
			}
		}


		&--main {
			flex: 8 0 #{(100%/$grid-columns*8)};
			width: #{(100%/$grid-columns*8)};
			max-width: 100%;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: flex-start;
			padding: 0;
			@include media-breakpoint-down(sm) {
				flex: 12 0 #{(100%/$grid-columns*12)};
				width: #{(100%/$grid-columns*12)};
				height: 60vh;
				min-height: 400px;
				max-height: 60vh;
			}
		}

		&--sub {
			flex: 4 0 #{(100%/$grid-columns*4)};
			width: #{(100%/$grid-columns*4)};
			max-width: 100%;
			background-color: $primary;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: space-between;
			@include media-breakpoint-down(sm) {
				flex: 12 0 #{(100%/$grid-columns*12)};
				width: #{(100%/$grid-columns*4)};
				height: 100%;
				max-height: 75vh;
				min-height: 450px;
				flex-direction: column-reverse;
			}
			ul{
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}
		}

		&--dropdowns {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			&:not(:last-of-type){
				margin-bottom: $grid-gutter-width / 2;
			}

			p {
				color: $white;
				font-size: $font-size-base;
				text-transform: capitalize;
				font-weight: $font-weight-light;
				text-align: left;
				margin-bottom: 0;

			}

			.dropdown, .dropleft {
				width: 100%;
				.dropdown-toggle {
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background-color: transparent !important;
					@include border-radius(0);
					padding: 0;
					margin-left: $grid-gutter-width / 2;
					border: 0;
					text-transform: uppercase;
					font-weight: $font-weight-light;
					color: $white;
					&:after {
						z-index: $zindex-dropdown;
						@include dropdown-arrow($white);
						position: absolute;
						right: 50%;
						transform: translateX(50%);
					}
				}
				.dropdown-menu {
					width: 100%;
					transform: translate3d(0, 24px, 0) !important;
				}

				&-item:not(.rtl) {
					text-transform: uppercase;
					text-align: left;
				}
			}
		}
	}
	&--main__sub{
		min-height: 50%;
		max-height: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		.main__sub--content{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 75%;
			@include media-breakpoint-down(md) {
				width: 90%;
			}
			@include media-breakpoint-down(sm) {
				align-items: flex-start;
			}
			& > * {
				z-index: $zindex-overlay-content;
			}
			img{
				width: 4em;
				height: auto;
				margin-right: 8px;
				@include media-breakpoint-down(sm){
					width: 2em;
				}
			}
			&>div {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				p, strong{
					color: $white;
					font-family: inherit;
					text-align: left;
					margin: 0;
					line-height: 26px;
					@include media-breakpoint-down(sm) {
						line-height: 20px;
					}
				}
				p {
					text-transform: lowercase;
					font-size: $font-size-lg * 1.25;
					font-weight: $font-weight-light;
					@include media-breakpoint-down(sm) {
						font-size: $font-size-base;
					}
				}
				strong{
					font-size: $font-size-lg * 1.4;
					text-transform: uppercase;
					@include media-breakpoint-down(sm) {
						font-size: $font-size-lg;
					}
				}
			}
		}
		&> a{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: $zindex-overlay-content + 50;
		}
		&:nth-of-type(1){
			background-image: url('../images/backgrounds/menu-bg-1.jpg');
		}
		&:nth-of-type(2) {
			background-image: url('../images/backgrounds/menu-bg-2.jpg');
		}
		&:after{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			background-color: rgba($primary-dark, 0.80);
			width: 100%;
			height: 100%;
			z-index: $zindex-overlay-content - 2;
		}
	}

	@include media-breakpoint-down(sm) {
		width: 100vw;
	}

	@include media-breakpoint-down(xs) {
		width: 100vw;

		.menu-close {
			right: 4vw;
		}
	}
	@media screen and (max-height: 500px) {
		height: 750px;
		overflow-y: scroll;
	}
}
/*New updates of header*/
.header-data-wrap {
	.navbar-data-item {
		margin-left: 48px;
		&:first-child {
			margin-left: 0px;
		}
	}
}




@media (max-width: 992px) {
	.header-data-wrap {
		.navbar-data-item {
			margin-left: 32px;
			.header-btn {
				font-size: 0.8em;
				width: 80px;
			}
			.header-form {
				width: 80%;
			}
		}
	}
	
	
}
@media (max-width: 780px) {
	.header-data-wrap {
		.navbar-search {
			display: none !important;
		}
	}
	
	
}
@media (max-width: 400px) {
	.header-data-wrap {
		.navbar-data-item {
			margin-left: 16px;
			
		}
		.login-wrap {
			margin-left: 0px;
		}
	}
	.logo-wrap {
		.navbar-brand {
			img {
				width: 54px;
			}
		}
	} 
	
	
}

body {
	position: relative;
	.bodyOverlay {
		content: "";
		background: rgba(0,0,0,0.6);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1040;
		display: none;
	}
}

@media (max-width: 768px){
	.overlay__nav.overlay__nav--sub .overlay__nav--dropdowns {
		display: none;
	}
}

.overlay__nav.overlay__nav--sub .phoneDropDown {
    display: flex;
    align-items: center;
	justify-content: flex-start;
	
	@media (min-width: 767px) {
    	& {
			display: none;
		}
	}

	p {
		color: #fff;
		font-size: 1rem;
		text-transform: capitalize;
		font-weight: 300;
		text-align: left;
		margin-bottom: 0;
	}

	select {
		background-color: transparent !important;
		padding: 0;
		border: 0;
		text-transform: uppercase;
		font-weight: 300;
		margin-left: 1rem;
		font-size: 1rem;
		color: #fff;
		max-width: 7rem;

		option {
			color : black
		}
	}
}