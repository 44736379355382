//*********************************  List Group  *********************************//
.list-group {
	&-item {
		background-color: $white;
		padding: 1px;
		border: 0;
		border-left: 4px solid transparent;
		font-size: $font-size-base;
		.dropdown,
		.dropleft {
			.dropdown-menu {
				transform: translate3d(-140px, 24px, 0) #{'/*!rtl: translate3d(0, 24px, 0)*/'} !important;
			}
		}

		&,
		a:not(.dropdown-item) {
			font-weight: $font-weight-light;
			text-transform: capitalize;
			color: $dark;
			width: 100%;
			padding-left: $card-spacer-x;
			transition: $transition-base;
		}

		a:not(.dropdown-item) {
			display: block;
			padding: $list-group-item-padding-y * 1.3 $list-group-item-padding-x;
			border-top: $border-width solid transparent;
			border-bottom: $border-width solid transparent;
		}

		&:hover,
		&.active {
			border-left-color: $primary;
			background-color: darken($white, 5%);

			a {
				color: $dark;
				border-bottom: $border-width solid rgba($border-color, 0.1);
			}
		}

		a:not(.dropdown-item) {
			display: block;
			width: 100%;
		}

		&:not(:last-of-type) {
			a:not(.dropdown-item) {
				border-bottom: $border-width solid rgba($border-color, 0.1);
			}
		}
	}
}

.list-group-dark,
.accordion-dark .list-group {
	.list-group-item {
		position: relative;
		background-color: $primary-dark;
		padding: 1px;
		border: 0;
		border-left: 4px solid transparent;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&,
		a {
			font-weight: $font-weight-light;
			text-transform: capitalize;
			color: $white;
			width: 100%;
			padding-left: $card-spacer-x;
			transition: $transition-base;
		}

		a {
			display: block;
			padding: $list-group-item-padding-y * 1.3 $list-group-item-padding-x;
			border-top: $border-width solid transparent;
			border-bottom: $border-width solid transparent;
			span.badge{
				margin-left: 12px;
				padding-right: 0.4rem;
				padding-left: 0.4rem;
				font-size: 65%;
			}
		}
		& > span.badge{
			position: absolute;
			top: 50%;
			right: $card-spacer-x/2;
			transform: translateY(-50%);
			background-color: rgba($white, 0.2);
			color: rgba($white, 0.6);
		}
		&:hover,
		&.active {
			border-left-color: $primary;
			background-color: darken($primary-dark, 5%);

			a {
				color: $white;
				border-bottom: $border-width solid rgba($white, 0.2);
			}
		}

		a {
			display: block;
			width: 100%;
		}

		&:not(:last-of-type) {
			a {
				border-bottom: $border-width solid rgba($white, 0.2);
			}
		}
	}
}


.modal{
	.list-group{
		&-item{
			padding-left: 0;
			&:hover,
			&.active {
				border-left-color: transparent;
				background-color: darken($white, 5%);
				a {
					color: $dark;
					border-bottom: $border-width solid rgba($border-color, 0.1);
				}
			}

			a{
				&:after{
					content: '';
					background: str-replace(url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px'%3E%3Cg%3E%3Cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='#{$primary}'/%3E%3C/g%3E%3C/svg%3E%0A"), "#", "%23") right 50% center no-repeat !important;
					background-size: 0.875rem !important;
					border: 0;
					z-index: $zindex-dropdown;
					display: block;
					width: 34px;
					height: 8px;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%) rotate(-90deg);
				}
			}
		}
	}
}
