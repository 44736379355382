/*
       ██████╗ █████╗ ██████╗ ██████╗ ███████╗
     ██╔════╝██╔══██╗██╔══██╗██╔══██╗██╔════╝
    ██║     ███████║██████╔╝██║  ██║███████╗
   ██║     ██╔══██║██╔══██╗██║  ██║╚════██║
  ╚██████╗██║  ██║██║  ██║██████╔╝███████║
  ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝
*/



//********************************* List All cards Names  *********************************//
/*
	.card-inner-side
	.card-profile
	.card-social
	.card-map
	.card-date-range
	.card-service-vertical
	.card-service-horizontal
	.card-service-single
	.card-working-hours
	.card-reviews
	.card-review
	.card-review-single
	.card-reply
	.card-category
	.card-collapse
	.card-message-horizontal
	.card-message-single
	.card-package-vertical
	.card-package-horizontal
	.card-offer-vertical
	.card-proposal-horizontal
	.card-proposed-horizontal
	.card-description
	.card-request-horizontal
	.card-request-single
	.card-order-horizontal
	.card-order-single
	.card-banner
	.card-user
	.card-statuses
	.card-RFPs-horizontal
	.card-chart
	.cards-collection
	.cards-table
	.card-payments
	.card-earnings
	.card-settings
*/
//*********************************  cards  *********************************//
.card-inner-side,
.card-profile,
.card-map,
.card-social,
.card-date-range,
.card-service-vertical,
.card-service-horizontal,
.card-service-single,
.card-working-hours,
.card-reviews,
.card-review,
.card-review-single,
.card-reply,
.card-job-vertical,
.card-category,
.card-collapse,
.card-message-horizontal,
.card-message-single,
.card-package-vertical,
.card-package-horizontal,
.card-offer-vertical,
.card-proposal-horizontal,
.card-proposed-horizontal,
.card-description,
.card-request-horizontal,
.card-request-single,
.card-order-horizontal,
.card-order-single,
.cards-collection,
.card-banner,
.card-user,
.card-RFPs-horizontal,
.card-statuses,
.card-payments,
.card-earnings,
.card-chart,
.card-settings {
	@extend .card;
}

.card {

	&-footer,
	&-body,
	&-header {
		background-color: inherit;
	}

	&>div:first-of-type {
		@include border-top-radius($card-border-radius);
	}

	&>div:last-of-type {
		@include border-bottom-radius($card-border-radius);
	}

	&-title,
	&-subtitle {
		font-family: inherit;
		font-weight: $font-weight-bold;
		margin-bottom: $grid-gutter-width/2;
		margin-top: 0;
	}

	&-text {
		color: $black;
		font-size: $font-size-sm;
		font-weight: $font-weight-light;
	}

	&-img-top {
		max-height: 138px;
		min-height: 138px;
		object-fit: cover;
		object-position: center;

		@include media-breakpoint-down(sm) {
			max-height: 250px;
			min-height: 250px;
		}

		@include media-breakpoint-down(xs) {
			max-height: initial;
		}
	}

	&-img-left {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;

		@include media-breakpoint-down(md) {
			min-width: 100%;
			max-width: 100%;
			min-height: 250px;
			max-height: 250px;
			object-position: center;
		}
	}

	&-img-overlay {
		display: flex;
		flex-direction: column;
	}
}

.cards-collection {
	@include border-radius($card-border-radius);

	&-header {
		padding: $card-spacer-x;
		@include border-top-radius($card-border-radius);
		background-color: inherit;
	}

	&-title {
		font-family: inherit;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		margin: 0;
	}

	&>.row {
		margin-right: 0;
		margin-left: 0;

		&>div[class*="col-"] {
			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				padding-right: 0;
			}

			@include media-breakpoint-down(md) {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}

	&>.row,
	&>div[class*="card"]:not(.cards-collection-header) {
		&:not(:last-of-type) {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				background-color: rgba($border-color, 0.1);
				height: $border-width;
				width: 95%;

			}
		}
	}

	&>div[class*="card"]:not(.cards-collection-header) {
		margin: 0 !important;
		@include border-radius(0);

		&:first-of-type {

			&,
			&>:first-child {
				@include border-top-radius($card-border-radius !important);
			}

			&>div:not(:first-child) {
				@include border-radius(0);
			}
		}

		&:last-of-type {

			&,
			&>:last-child {
				@include border-bottom-radius($card-border-radius !important)
			}

			&>div:not(:last-child) {
				@include border-radius(0);
			}
		}
	}
}

.cards-table {
	@extend .customized-scroll;
	max-height: 600px;
	overflow-y: scroll;
	@include border-top-radius($card-border-radius);

	&-header {
		background-color: $gray-dark;
		color: color-yiq($gray-dark); // this must take same color as background-color
		@include border-radius($card-border-radius);
		padding: $card-spacer-x;
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		position: sticky;
		top: 0;
		z-index: $zindex-dropdown - 10;

		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
		}
	}

	&-body {
		padding: $card-spacer-x 0;
		background: transparent;

		.card {
			&:not(:last-of-type) {
				margin-bottom: $card-spacer-x;
			}

			&-body {
				display: flex;
				position: relative;

				@include media-breakpoint-down(md) {
					flex-wrap: wrap;
				}

				&>a {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: #{($zindex-dropdown - 10)};
				}
			}
		}
	}

	.table {

		&-header,
		&-body {

			&-title,
			&-userInfo,
			&-data {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			&-data {
				@include media-breakpoint-down(md) {
					flex-wrap: wrap;
					margin-top: 1rem;
				}
			}

			&-title {
				flex: 4 0 #{(100%/$grid-columns*4)};
				width: #{(100%/$grid-columns*4)};
				max-width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;

				@include media-breakpoint-down(md) {
					flex: 12 0 100%;
					width: 100%;
				}

				+.table-header-data,
				+.table-body-data {
					flex: 8 0 #{(100%/$grid-columns*8)};
					width: #{(100%/$grid-columns*8)};
					max-width: 100%;
					display: flex;
					align-items: stretch;
					justify-content: center;
					text-align: center;

					@include media-breakpoint-up(lg) {
						.table-data {
							&:first-of-type {
								&:before {
									content: none;
								}
							}
						}
					}
				}
			}

			&-userInfo {
				flex: 2.5 0 #{(100%/$grid-columns*2.5)};
				width: #{(100%/$grid-columns*2.5)};
				max-width: 100%;
				position: relative;

				@include media-breakpoint-down(md) {
					flex: 12 0 100%;
					width: 100%;
				}

				+.table-header-data,
				+.table-body-data {
					flex: 9.5 0 #{(100%/$grid-columns*9.5)};
					width: #{(100%/$grid-columns*9.5)};
					max-width: 100%;
					display: flex;
					align-items: stretch;
					justify-content: center;

					@include media-breakpoint-down(md) {
						flex: 12 0 100%;
						width: 100%;
						margin-top: 2rem;
					}

					.table-data {
						flex: 1;
						max-width: 100%;
						justify-content: flex-start;
						text-align: left;
						padding-right: 0.5rem;
						padding-left: 0.5rem;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;

						span {
							@include media-breakpoint-up(lg) {
								display: none;
							}
						}
					}
				}

				.user {
					width: 100%;

					&-info {
						width: 100%;
						display: block;

						.card-rating {
							margin-bottom: 0.5rem;
							flex-direction: column;
							align-items: flex-start;

							.br-wrapper {
								margin: 0;
							}
						}
					}

					&-name {
						display: block;
						margin-bottom: 0.5rem;
						@extend .text-truncate;
					}
				}
			}
		}

		&-header {

			&-title,
			&-userInfo,
			&-data {
				color: inherit; // this must take same color as background-color
			}

			&-data {
				@include media-breakpoint-down(md) {
					flex-wrap: wrap;
				}
			}
		}

		&-body {

			&-title,
			&-userInfo,
			&-data {
				color: inherit; // this must take same color as background-color
			}

			@include media-breakpoint-down(md) {
				flex-wrap: wrap;
			}
		}

		&-title,
		&-userInfo {
			font-size: $font-size-base;
			font-weight: $font-weight-bolder;
			width: 100%;
		}

		&-title,
		&-data,
		&-userInfo {
			margin: 0;
			font-family: inherit;
			text-transform: capitalize;
			color: inherit;
		}

		&-data {
			font-weight: $font-weight-light;
			font-size: $font-size-sm * 0.85;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			max-height: 7em;
			flex: 1;
			padding: 0 0.5rem;

			@include media-breakpoint-down(md) {
				flex: 4 0 #{(100%/$grid-columns*3)} !important;
				width: #{(100%/$grid-columns*3)} !important;
				max-width: 100%;
				margin-bottom: 0.5rem;
				text-align: left;
				justify-content: flex-start;
			}

			&:before {
				content: '';
				height: 100%;
				width: $border-width;
				background-color: currentColor;
				opacity: 0.2;
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}
	}
}

.card-inner-side {
	overflow: hidden;

	@include media-breakpoint-up(lg) {
		width: 90%;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: $grid-gutter-width;
	}

	.card-header {
		background-color: $primary;
		color: $white;
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;

		.badge {
			white-space: inherit;
			word-break: break-word;
		}

		.user-img-md {
			width: 75%;
			margin-right: auto;
			margin-left: auto;

			.user-img-name {
				p {
					font-size: 1.5em;
					font-weight: $font-weight-light;
					color: $white;
					text-align: center;
					margin-bottom: 0 !important;
					margin-top: 10px !important;
				}
			}
		}
	}

	.card-body {
		background-color: $dark;
		color: $white;
		padding: 0;

		.accordion {
			.card {
				background-color: $dark;

				&-header {
					background-color: transparent;
					padding-left: 0;
					padding-right: 0;

				}
			}
		}
	}
}

.card-inner-filter {
	@extend .card-inner-side;
	width: 100%;
	margin-top: $grid-gutter-width;
	@include border-radius($border-radius-sm*0.75);

	&>.card {
		&-header {
			background-color: $white;
			border-bottom: $border-width solid rgba($border-color, 0.1);

			.card-title {
				margin-bottom: 0;
				font-size: 0.9375rem;
			}
		}

		&-body {
			.accordion {
				.card {
					.collapse {
						background-color: $white;

						&.show {
							.card-body {
								border-bottom: $border-width solid rgba($border-color, 0.1);
							}
						}
					}

					&-header {
						h6 {
							button {
								color: $gray-500;
								font-size: 0.875rem;
								font-weight: $font-weight-normal;
								padding: 0.875rem 0.8125rem;
								border-bottom: $border-width solid rgba($border-color, 0.1);

								&:before {
									content: none;
								}

								&:after {
									@include dropdown-arrow($gray-500);
									background-position-x: center !important;
									width: 23px;
									height: 10px;
									position: absolute;
									right: 0.8125rem;
									top: 45%;
									transform: translateY(-45%);
									transform: rotate(-90deg);
									transition: $transition-base;
								}

								&[aria-expanded="true"] {
									border-bottom: $border-width solid transparent;

									&:after {
										background-position-x: center !important;
										transform: rotate(-180deg);
									}
								}
							}
						}
					}

					&-body {
						background-color: $white;
						padding: 0 0.8125rem 0.875rem 0.8125rem;

						.custom-control {
							&-label {
								color: $gray-500;
								font-size: 0.875rem;
								text-transform: capitalize;
							}
						}
					}
				}
			}
		}
	}
}


.card-statuses {
	.list-group {
		@include border-radius($card-border-radius);
		background-color: inherit;
		padding: 0 1.5rem;

		&-item {
			border-radius: 0;
			padding: 1rem;
			font-family: inherit;
			font-size: 1rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-transform: capitalize;
			text-align: left;
			font-weight: $font-weight-normal;
			position: relative;
			margin: 0;
			border: 0;

			&.active {
				&:after {
					@include dropdown-arrow(currentColor);
					background-position-x: center !important;
					width: 23px;
					height: 10px;
					position: absolute;
					left: -6px;
					top: 50%;
					transform: translateY(-50%) rotate(-90deg);
					transition: $transition-base;
				}
			}

			&,
			&:hover {
				background-color: inherit;
			}

			&:before {
				content: '';
				position: absolute;
				left: -1.5rem; // this equal to list-group padding-left
				top: 0;
				bottom: 0;
				color: inherit;
				background-color: currentColor; // to inherit background color from list-item color
				width: 1em;
				border-radius: 0;
			}

			span {
				color: inherit;
				text-align: right;
				font-weight: $font-weight-bold;
			}

			&:not(:last-child) {
				border-bottom: $border-width solid rgba($border-color, 0.1);
			}

			&:first-child {
				@include border-top-radius($card-border-radius);

				&:before {
					@include border-radius($card-border-radius 0 0 0);
				}
			}

			&:last-child {
				@include border-bottom-radius($card-border-radius);

				&:before {
					@include border-radius(0 0 0 $card-border-radius);
				}
			}

			&:not(:first-child) {
				&:before {
					top: (- $border-width);
				}
			}
		}
	}
}

.card-social {
	.card-body {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;
		padding: 0;
		background-color: inherit;
		overflow: hidden;
	}

	&-icon {
		flex: 1;
		text-align: center;
		padding: 1.25rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $font-size-lg;
		background-color: inherit;

		i {
			transform: scale(1);
			transition: $transition-base;
		}

		&:hover {
			i {
				transition: $transition-base;
				transform: scale(1.4);
			}
		}

		&:active,
		&:focus {
			i {
				transition: $transition-base;
				transform: scale(1.2);
			}
		}

		&:not(:last-of-type) {
			border-right: $border-width solid rgba($border-color, 0.1);
		}
	}
}

.badges-group {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	.badge,
	.label {
		text-transform: capitalize;
		margin-bottom: 0.625rem;

		&:not(:last-of-type) {
			margin-right: 0.625rem;
		}
	}

}

.status-list {
	padding: 0;
	margin: 0;

	li {
		list-style: none;
		font-family: inherit;
		color: $black;
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		text-transform: capitalize;
		padding-left: 1.5rem;
		position: relative;

		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}

		&:not(:first-of-type) {
			&:after {
				content: '';
				height: 1.5rem;
				width: $border-width;
				background: transparent;
				border-right-style: dotted;
				border-right-width: $border-width*2;
				border-color: inherit;
				position: absolute;
				left: 6px;
				top: -100%;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 6px;
			left: 2px;
			width: 10px;
			height: 10px;
			display: block;
			border-radius: 50%;
			background-color: currentColor;
			color: inherit;
		}
	}
}

.subscriptions-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	background-color: inherit;

	dl {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0;
		max-width: 100%;
		width: 100%;

		&:not(:last-of-type) {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				height: $border-width;
				width: 100%;
				background-color: rgba($border-color, 0.1);
			}
		}

		&:not(:last-of-type):not(:first-of-type) {
			padding: 1rem 0;
		}

		&:first-of-type {
			padding-bottom: 1rem;
		}

		&:last-of-type {
			padding-top: 1rem;
		}

		&,
		&>dt,
		&>dd {
			background-color: inherit;
		}

		dt,
		dd {
			margin-bottom: 0;
			font-family: inherit;
			font-weight: $font-weight-bold;
		}

		dt {
			flex: 6 0 #{(100%/$grid-columns*6)};
			width: #{(100%/$grid-columns*6)};
			max-width: 100%;
			text-align: left;
			text-transform: uppercase;
			color: $gray-500;
			font-size: $font-size-base;
		}

		dd {
			flex: 6 0 #{(100%/$grid-columns*6)};
			width: #{(100%/$grid-columns*6)};
			max-width: 100%;
			text-align: right;
			color: $primary;
			text-transform: uppercase;
		}
	}

	&-lg {
		dl {
			dt {
				font-size: $font-size-lg;
				color: $black;
			}

			dd {
				font-weight: $font-weight-normal;
			}
		}
	}
}

.info-list,
.desc-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: 100%;
	width: 100%;

	dl {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 0.3125rem;
		margin-top: 0.3125rem;
		max-width: 100%;
		width: 100%;

		// &:last-of-type {
		// 	margin-bottom: 0;
		// }
		dt,
		dd {
			margin-bottom: 0;
			text-align: left;
		}

		dt {
			margin-right: 10px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: $font-size-lg;
		}

		dd {
			color: $gray-800;
			font-weight: $font-weight-light;
			font-size: $font-size-sm;
			display: inline-flex;
			flex-wrap: wrap;
			text-transform: capitalize;
		}
	}
}

.info-list {
	&:not(:last-of-type) {
		border-bottom: $border-width solid rgba($border-color, 0.1);
		padding-bottom: 10px;
	}

	&:not(:first-of-type) {
		padding-top: 10px;
	}

	dl {
		dt {
			flex: 4 0 #{(100%/$grid-columns*4)};
			width: #{(100%/$grid-columns*4)};
			max-width: 100%;
			font-weight: $font-weight-normal;
			font-size: $font-size-sm + 0.1;
			text-transform: capitalize;
			color: $black;
		}

		dd {
			flex: 8 0 #{(100%/$grid-columns*8)};
			width: #{(100%/$grid-columns*8)};
			max-width: 100%;
		}

		dt,
		dd {
			@include media-breakpoint-down(sm) {
				flex: 6 0 #{(100%/$grid-columns*6)};
				width: #{(100%/$grid-columns*6)};
				align-items: flex-start
			}
		}

		&.flex-column {

			dt,
			dd {
				@include media-breakpoint-down(sm) {
					flex: 12 0 #{(100%/$grid-columns*12)};
					width: #{(100%/$grid-columns*12)};
					align-items: flex-start
				}
			}
		}
	}
}

.desc-list {
	dl {
		dt {
			color: $primary;

			i {
				font-size: $font-size-base*1.4;
			}
		}
	}
}

.user-img {

	&-md,
	&-sm,
	&-xs,
	&-lg {
		color: $white;
		display: flex;
		flex-direction: column;

		img {
			@include border-radius(50%);
			object-fit: scale-down;
		}
	}

	&-lg {
		margin-bottom: 1rem;

		img {
			width: 100px;
			height: 100px;
		}
	}

	&-md {
		img {
			width: 63px;
			height: 63px;
		}
	}

	&-sm {
		img {
			width: 35px;
			height: 35px;
		}
	}

	&-xs {
		img {
			width: 25px;
			height: 25px;
		}
	}

	&-name {
		text-transform: capitalize;
		color: $white !important;
	}
}


.card-RFPs {
	&-horizontal {
		.card {
			&-header {
				position: relative;
				background-color: inherit;

				&>a {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: $zindex-dropdown - 5;
				}

				padding: 0.675rem 1rem;
				@include border-radius($card-border-radius $card-border-radius 0 0 !important);

				.dropleft,
				.dropdown {
					z-index: $zindex-dropdown - 3;

					.dropdown-toggle {
						@include border-radius(50%);
						background-color: transparent;
						padding: 0.25rem;

						&.bg-white {
							i {
								color: $gray-400;
							}
						}

						i {
							color: $white;
						}
					}

					.dropdown-menu {
						transform: translate3d(-140px, 32px, 0) #{'/*!rtl: translate3d(0, 32px, 0)*/'} !important;
					}
				}

				.card-promoted {
					align-items: center;
					justify-content: center;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					background-color: $danger;
					color: $white;
					font-family: inherit;
					font-weight: $font-weight-normal;
					padding: 0 1rem;
					text-transform: capitalize;
					display: none;
					visibility: hidden;
					@include border-radius($card-border-radius 0 0 0);
				}

				.card-subtitle {
					color: $white !important;
					margin: 0;
					font-size: $font-size-sm;
					color: $white;
					font-weight: $font-weight-bold;
					text-transform: uppercase;
				}
			}

			&-body {
				display: flex;
				flex-direction: column;
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;

				.RFPs-info {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;

					&>div {
						flex: 4 0 #{(100%/$grid-columns*4)};
						max-width: 100%;
						width: #{(100%/$grid-columns*4)};
					}

					.categories-list {
						&:last-of-type {
							margin-bottom: 0.5rem;
						}
					}

					@include media-breakpoint-down(md) {
						flex-direction: column;

						&>div {
							flex: 1 0 100%;
							max-width: 100%;
							width: 100%;
							padding-bottom: 1rem;
							border-bottom: $border-width solid rgba($border-color, 0.1);

							&:not(:last-of-type) {
								margin-bottom: 1rem;
							}
						}
					}
				}
			}

			&-footer {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: flex-start;

				@include media-breakpoint-up(md) {
					padding-top: 0;
				}

				&>div {
					display: flex;
					align-items: flex-end;
					justify-content: space-between;

					@include media-breakpoint-down(md) {
						align-items: center;
					}

					&>a.btn {
						padding: 0;
					}

					.card-subtitle {
						font-weight: $font-weight-light;
						margin-bottom: 0.25rem;
					}

					span {
						text-transform: capitalize;
						font-weight: $font-weight-bold;
					}
				}

				&>a.btn {
					margin-top: 0.5rem;

					@include media-breakpoint-up(sm) {
						align-self: flex-start;
					}
				}
			}

			&-title {
				font-size: 1rem;
				text-transform: capitalize;
			}
		}

		&.RFP-promoted {
			.card {
				&-header {
					.card-promoted {
						display: flex;
						visibility: visible;

						&+.card-subtitle {
							margin-left: 6rem;
						}
					}
				}
			}
		}
	}
}



.card-profile {
	&>div[class*="card"] {
		border-radius: 0;

		&:first-of-type {
			@include border-top-radius($card-border-radius);
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}

		&:last-of-type {
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
			@include border-bottom-radius($card-border-radius);
		}
	}

	.card {

		&-body,
		&-footer,
		&-header {
			background-color: inherit;
			position: relative;
		}

		&-header {
			background-color: inherit !important;

			.dropleft,
			.dropdown {
				position: absolute;
				top: 10px;
				right: 10px;

				.dropdown-toggle {
					background-color: inherit !important;
					color: $gray-400 !important;
				}

				.dropdown-menu {
					transform: translate3d(-166px, 24px, 0) #{"/*rtl: translate3d(0, 24px, 0)*/"} !important;
				}
			}
		}

		&-body {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&>div {
				flex: 1;

				&:not(:last-of-type) {
					position: relative;

					&:after {
						content: '';
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						background-color: rgba($border-color, 0.1);
						height: 100%;
						width: $border-width;

						@include media-breakpoint-down(md) {
							content: none;
						}
					}
				}
			}

			@include media-breakpoint-only(md) {
				flex-direction: column;

				&>div {
					&:first-of-type {
						margin-bottom: 2.5rem;
					}
				}
			}

			@include media-breakpoint-down(xs) {
				flex-direction: column;

				&>div {
					&:first-of-type {
						margin-bottom: 2.5rem;
					}
				}
			}
		}

		&-footer {
			.card-social {
				background-color: inherit;

				.card-body {
					@include border-radius(0);

					@include media-breakpoint-down(md) {
						flex-direction: row !important;
					}
				}
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				height: $border-width;
				background-color: rgba($border-color, 0.1);
				width: 95%;
			}
		}

		&-content {

			&-basic,
			&-stat {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 0 1rem;

				.desc-list {
					dl {
						justify-content: center;
					}
				}
			}

			&-basic {
				.user {
					&-img-lg {
						.img-thumbnail {
							background-color: inherit;
						}
					}

					&-data {

						&,
						&>* {
							text-align: center;
							font-family: inherit;
						}

						h4 {
							font-weight: $font-weight-bold;
							text-transform: capitalize;

							img {
								margin-left: 1rem;
							}
						}

						p {
							text-transform: capitalize;
							margin: 0;
							color: $gray-500;

							&:last-of-type {
								margin-bottom: 1rem;
							}
						}
					}
				}
			}
		}
	}
}


.card-offer-vertical {
	overflow: hidden !important;
	height: 100%;
	position: relative;

	&>a {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: $zindex-dropdown - 10;
	}

	&>div {
		&:not(:last-of-type) {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				height: $border-width;
				width: 90%;
				background-color: rgba($border-color, 0.1);
			}
		}
	}

	.card {
		&-header {
			background-color: inherit !important;
			color: $black;
			padding-top: 1.5rem !important;
			padding-bottom: 1.5rem !important;
			border-radius: 0 !important;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: nowrap;

			.dropleft,
			.dropdown {
				z-index: $zindex-dropdown - 5;

				.dropdown-toggle {
					background-color: inherit !important;
					border: 0;
					padding: 0;

					i {
						color: $gray-500 !important;
					}
				}

				.dropdown-menu {
					transform: translate3d(-140px, 24px, 0) #{'/*!rtl: translate3d(0, 24px, 0) */'} !important;
				}
			}
		}

		&-title {
			color: $black;
			text-transform: capitalize;
			width: 90%;
			margin-bottom: 0;
			font-size: $font-size-base;
			font-family: inherit;
		}

		&-body {
			&>div {
				&:not(:last-of-type) {
					position: relative;
					margin-bottom: 1rem;

					&:after {
						content: '';
						position: absolute;
						bottom: -10px;
						left: 50%;
						transform: translateX(-50%);
						height: $border-width;
						width: 100%;
						background-color: rgba($border-color, 0.1);
					}
				}
			}
		}

		&-footer {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			background-color: inherit;

			&>div {
				flex: 1;
				display: flex;
				flex-wrap: nowrap;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.offer {
		&-percentage {
			background-color: $primary;
			color: $white;
			padding: 14px 0;
			margin-top: -1px;

			p {
				color: inherit;
				font-family: inherit;
				font-weight: $font-weight-light;
				text-align: center;
				margin-bottom: 0;
				font-size: $font-size-lg;
			}
		}

		&-info {
			h5 {
				font-family: inherit;
				font-weight: $font-weight-bold;
				font-size: $font-size-base;
				text-transform: capitalize;
				text-align: left;
				color: $black;
			}

			p {
				font-size: $font-size-sm - 0.1;
				font-family: inherit;
				font-weight: $font-weight-light;
				text-transform: capitalize;
				color: $gray-600;
			}
		}

		&-description {
			h6 {
				font-family: inherit;
				font-size: $font-size-sm;
				color: rgba($black, 0.56);
				text-transform: capitalize;
				text-align: left;
				font-weight: $font-weight-bold;
				margin-bottom: 0.5625rem;
			}
		}

		&-date {
			background-color: inherit;

			&:not(:last-of-type) {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					height: 100%;
					width: $border-width;
					background-color: rgba($border-color, 0.1);
				}
			}

			p,
			strong {
				font-family: inherit;
				font-weight: $font-weight-bold;
				text-transform: capitalize;
				text-align: center;
			}

			p {
				color: rgba($black, 0.56);
				margin-bottom: 0.5625rem;
				font-size: $font-size-base - 0.2;
			}

			strong {
				color: $primary;
				font-size: $font-size-base;
			}
		}
	}
}

.card-proposed {
	&-horizontal {
		.card {
			&-header {
				position: relative;
				background-color: inherit;

				&>a {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: $zindex-dropdown - 5;
				}

				padding: 0.875rem 1rem;
				@include border-radius($card-border-radius $card-border-radius 0 0 !important);

				.dropleft,
				.dropdown {
					z-index: $zindex-dropdown - 3;

					.dropdown-toggle {
						@include border-radius(50%);
						background-color: transparent;
						padding: 0.25rem;

						&.bg-white {
							i {
								color: $gray-400;
							}
						}

						i {
							color: $white;
						}
					}
				}

				.card-subtitle {
					color: $white !important;
					margin: 0;
					font-size: $font-size-sm;
					color: $white;
					font-weight: $font-weight-bold;
					text-transform: uppercase;
				}
			}

			&-body {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;

				&>div {
					flex: 4 0 #{(100%/$grid-columns*4)};
					max-width: 100%;
					width: #{(100%/$grid-columns*4)};
				}

				@include media-breakpoint-down(md) {
					flex-wrap: wrap;

					&>div {
						flex: 12 0 #{(100%/$grid-columns*12)};
						max-width: 100%;
						width: #{(100%/$grid-columns*12)};

						&:not(:last-child) {
							margin-bottom: 1rem;
						}
					}
				}

				p {
					text-transform: capitalize;
					font-size: $font-size-sm;
					color: $black;
					text-align: left;
					font-weight: $font-weight-normal;
				}

				.desc-list {
					dl {
						justify-content: space-between;
						margin-top: 0;

						dt {
							color: $black;
							font-weight: $font-weight-normal + 100;
							text-transform: capitalize;
							font-size: $font-size-sm;
						}

						dd {
							color: $black;
							font-weight: $font-weight-light;
							text-transform: uppercase;
							font-size: $font-size-sm;
						}
					}
				}
			}
		}
	}
}


.card-proposal {
	&-horizontal {
		&.proposal-withdrawn {
			position: relative;

			&:after {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba($black, 0.4);
				z-index: $zindex-dropdown - 6;
				@include border-radius($card-border-radius);
			}

			.card {
				&-header {

					.dropleft,
					.dropdown {
						.dropdown-toggle {
							i {
								color: rgba($white, 0.4);
							}
						}
					}
				}

				&-footer {
					a.btn.btn-link {
						opacity: 0.8;
					}
				}
			}
		}

		.card {
			&-header {
				position: relative;
				background-color: inherit;

				&>a {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: $zindex-dropdown - 5;
				}

				padding: 0.875rem 1rem;
				@include border-radius($card-border-radius $card-border-radius 0 0 !important);

				.dropleft,
				.dropdown {
					z-index: $zindex-dropdown - 3;

					.dropdown-toggle {
						@include border-radius(50%);
						background-color: transparent;
						padding: 0.25rem;

						&.bg-white {
							i {
								color: $gray-400;
							}
						}

						i {
							color: $white;
						}
					}

					.dropdown-menu {
						transform: translate3d(-153px, 32px, 0) #{'/*!rtl: translate3d(-2px, 32px, 0)*/'} !important;
					}
				}

				.card-subtitle {
					color: $white !important;
					margin: 0;
					font-size: $font-size-sm;
					color: $white;
					font-weight: $font-weight-bold;
					text-transform: uppercase;
				}
			}

			&-body {
				display: flex;
				flex-direction: row;
				padding-bottom: 0;

				@include media-breakpoint-down(lg) {
					flex-direction: column;
					padding-bottom: 1rem;
				}

				.proposal-info {
					flex: 3 0 #{(100%/$grid-columns*3)};
					width: #{(100%/$grid-columns*3)};
					max-width: 100%;
					z-index: $zindex-dropdown - 5;

					@include media-breakpoint-down(lg) {
						flex: 1 0 100%;
						max-width: 100%;
						width: 100%;
						padding-bottom: 1rem;
					}
				}

				.user {
					flex: 2.5 0 #{(100%/$grid-columns*2.5)};
					width: #{(100%/$grid-columns*2.5)};
					max-width: 100%;
					flex-direction: column;

					&-name {
						@extend .text-truncate;
						width: 100%;
						display: block;
					}

					@include media-breakpoint-down(lg) {
						flex: 1 0 100%;
						max-width: 100%;
						width: 100%;
						flex-direction: row;
						padding-bottom: 1rem !important;
					}
				}

				.desc-list {
					flex: 3.5 0 #{(100%/$grid-columns*3.5)};
					width: #{(100%/$grid-columns*3.5)};
					max-width: 100%;
					display: flex;
					flex-direction: row;

					@include media-breakpoint-down(lg) {
						flex: 1 0 100%;
						max-width: 100%;
						width: 100%;
						padding-bottom: 1rem;
						flex-direction: column;
					}

					dl {
						flex-direction: column;
						height: 100%;
						justify-content: center;

						&,
						& dt {
							margin: 0;
						}

						&:not(:last-of-type) {
							border-right: $border-width solid rgba($border-color, 0.1);
						}

						@include media-breakpoint-down(lg) {
							justify-content: space-between;
							flex-direction: row;

							dt {
								margin-right: 1rem;
							}

							&:not(:last-of-type) {
								border-right: 0;
							}
						}
					}
				}

				.proposal-price {
					display: flex;
					flex-direction: column;
					align-items: stretch;
					justify-content: center;

					.card-subtitle {
						font-weight: $font-weight-normal;
						color: $black;
						margin-bottom: 0;

						&:not(:last-of-type) {
							margin-bottom: 0.25rem;
						}
					}
				}

				&>div {
					&:not(:last-of-type) {
						border-right: $border-width solid rgba($border-color, 0.1);

						@include media-breakpoint-down(lg) {
							border-right: 0;
						}
					}

					&:not(:first-of-type):not(:last-of-type):not(.desc-list) {
						padding: 0 0.625rem;
					}

					&:last-of-type {
						padding-left: 0.625rem;
					}

					&:first-of-type {
						padding-right: 0.625rem;
					}

					@include media-breakpoint-down(lg) {
						padding-right: 0 !important;
						padding-left: 0 !important;
					}
				}
			}

			&-footer {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				padding-top: 0;

				a.btn.btn-link {
					padding: 0;
					z-index: $zindex-dropdown - 5;
				}

				.card-subtitle {
					font-weight: $font-weight-light;
					margin-bottom: 0.25rem;
				}

				span {
					text-transform: capitalize;
					font-weight: $font-weight-bold;
				}
			}
		}
	}
}


.card-request,
.card-order {

	&-horizontal,
	&-single {

		min-height: 100px;
		height: 100%;

		@include media-breakpoint-down(md) {
			height: initial;
		}

		.card {
			&-header {
				position: relative;

				&>a {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: $zindex-dropdown - 5;
				}

				padding: 1.25rem 1rem;
				@include border-radius($card-border-radius $card-border-radius 0 0 !important);

				.dropleft,
				.dropdown {
					z-index: $zindex-dropdown - 3;

					.dropdown-toggle {
						@include border-radius(50%);
						background-color: transparent;
						padding: 0.25rem;

						&.bg-white {
							i {
								color: $gray-400;
							}
						}

						i {
							color: $white;
						}
					}

					.dropdown-menu {
						transform: translate3d(-132px, 30px, 0) #{'/*!rtl: translate3d(0, 30px, 0)*/'} !important;
					}
				}
			}

			&-title,
			&-subtitle {
				font-weight: $font-weight-bold;
				font-size: $font-size-base;
				margin: 0;
			}

			&-title {
				color: $black;
				text-transform: capitalize;
			}

			&-subtitle {
				color: $white !important;
				text-transform: uppercase;
			}

			&-body {
				padding: 0;
				display: flex;
				height: 100%;
				@include border-radius(0 0 $border-radius-sm $border-radius-sm);

				&>div {
					height: 100%;
				}

				.desc-list {
					flex-direction: row;
					padding: 0;

					dl {
						flex-direction: column;
						align-items: stretch;
						justify-content: flex-start;
						padding: 0.75rem;
						height: 100%;
						margin: 0;

						&:not(:last-of-type) {
							position: relative;

							&:after {
								content: '';
								position: absolute;
								height: 75%;
								width: $border-width;
								background-color: rgba($border-color, 0.1);
								right: 0;
								top: 50%;
								transform: translateY(-50%);
							}
						}

						dd {
							color: $black;
							font-size: $font-size-sm;
							margin-top: 10px;
							font-weight: $font-weight-normal;
						}

						@include media-breakpoint-down(md) {
							padding: 0.75rem 1rem;
						}
					}
				}
			}

			&-info {
				.card-subtitle {
					color: $black !important;
					font-size: $font-size-sm;
					text-transform: capitalize;
					font-weight: $font-weight-light;
				}
			}

			&-price {
				p {
					margin: 0;
					text-transform: capitalize;
					color: $gray-400;
					font-size: $font-size-sm;
				}
			}
		}

		.price {
			color: $danger;
			text-transform: uppercase;
			font-size: $font-size-base;
		}
	}

	&-horizontal {
		.card {
			&-body {
				align-items: flex-start;
				justify-content: flex-start;

				@include media-breakpoint-down(md) {
					flex-direction: column;
				}

				&>div {
					padding: 0.75rem 1rem;

					&:not(:last-of-type) {
						position: relative;

						&:after {
							content: '';
							position: absolute;
							height: 75%;
							width: $border-width;
							background-color: rgba($border-color, 0.1);
							right: 0;
							top: 50%;
							transform: translateY(-50%);

							@include media-breakpoint-down(md) {
								height: $border-width;
								width: 95%;
								top: initial;
								bottom: 0;
								left: 50%;
								transform: translateX(-50%);
							}
						}
					}
				}

				.card-info {
					flex: 5 0 #{(100%/$grid-columns*5)};
					width: #{(100%/$grid-columns*5)};
					max-width: 100%;

					@include media-breakpoint-down(md) {
						flex: 12 0 100% !important;
						width: 100% !important;
						max-width: 100% !important;
					}
				}

				.desc-list {
					@include media-breakpoint-down(md) {
						flex: 12 0 100% !important;
						width: 100% !important;
						max-width: 100% !important;
					}

				}

				.card-price {
					flex: 2 0 #{(100%/$grid-columns*2)};
					width: #{(100%/$grid-columns*2)};
					max-width: 100%;

					@include media-breakpoint-down(md) {
						flex: 12 0 100% !important;
						width: 100% !important;
						max-width: 100% !important;
					}
				}
			}
		}
	}

	&-single {
		.card {
			&-body {
				padding: 0.875rem 1rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 100%;

				@include media-breakpoint-down(md) {
					flex-direction: column;
					align-items: flex-start;
				}
			}

			&-price {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.price {
					font-weight: bold;
				}
			}
		}
	}
}

.card-package {

	&-vertical,
	&-horizontal {
		.card {
			&-header {
				padding-right: $card-spacer-y * 2;
				padding-top: 1rem !important;
				padding-bottom: 1rem !important;
				position: relative;

				.dropleft,
				.dropdown {
					position: absolute;
					top: 50%;
					right: 0.5rem;
					transform: translateY(-50%);

					.dropdown-toggle {
						background-color: transparent !important;
					}

					.dropdown-menu {
						transform: translate3d(-138px, 24px, 0) #{'/*!rtl: translate3d(0, 24px, 0)*/'} !important;
					}
				}
			}
		}
	}

	&-vertical {
		.card {
			&-header {
				background-color: $gray !important;
				color: $white;

				.dropleft,
				.dropdown {
					.dropdown-toggle {
						i {
							color: $white;
						}
					}
				}
			}

			&-title {
				color: $white;
				font-size: $font-size-lg;
				font-family: inherit;
				margin-bottom: 0;
				width: 90%;
			}

			&-body {
				background-color: inherit;

				.desc-list {
					dl {
						margin: 0;

						dd {
							color: $black;
							font-size: $font-size-base;
							text-transform: capitalize;
						}
					}
				}
			}

			&-price {
				background-color: $primary;
				color: $white;
				padding-top: 1rem;
				padding-bottom: 1rem;

				p {
					color: inherit;
					margin: 0;
					font-family: inherit;
					font-weight: $font-weight-light;
					font-size: 1.25rem;
					text-align: center;
					width: 100%;
				}
			}
		}

		&-basic {
			.card {
				&-header {
					@include gradient-x($gray-400, $gray-100);
				}
			}
		}

		&-gold {
			.card-header {
				@include gradient-x($yellow-stars, $yellow-stars-dark);
			}
		}

		&-premium {
			.card-header {
				@include gradient-x-three-colors($red, $pink, 50%, $yellow-stars);
			}
		}
	}

	&-horizontal {
		.card {
			&-header {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding-bottom: 0 !important;
				background-color: inherit;

				.user {
					flex: 6 0 #{(100%/$grid-columns*6)};
					width: #{(100%/$grid-columns*6)};
					max-width: 100%;
				}

				.desc-list {
					flex: 5.5 0 #{(100%/$grid-columns*5.5)};
					width: #{(100%/$grid-columns*5.5)};
					max-width: 100%;

					dl {
						justify-content: space-between;

						dt,
						dd {
							margin: 0;
							color: $black;
							text-transform: capitalize;
							font-size: $font-size-sm;
							@extend .text-truncate;
							display: inline-block;
						}

						dt {
							flex: 5 0 #{(100%/$grid-columns*5)};
							width: #{(100%/$grid-columns*5)};
							max-width: 100%;
						}

						dd {
							text-align: right;
							flex: 7 0 #{(100%/$grid-columns*7)};
							width: #{(100%/$grid-columns*7)};
							max-width: 100%;
						}
					}
				}

				@include media-breakpoint-down(lg) {
					flex-wrap: wrap;

					.user,
					.desc-list {
						flex: 1 0 100%;
						width: 100%;
						max-width: 100%;
					}

					.desc-list {
						margin-top: 0.5rem;
					}
				}

			}

			&-body {
				.desc-list {
					flex-direction: row;
					flex-wrap: wrap;

					dl {
						margin-top: 0;
						flex-direction: column;
						align-items: flex-start;
						// flex: 2 0 #{(100%/$grid-columns*2)};
						width: #{(100%/$grid-columns*2)};
						max-width: 100%;
						padding: 0 0.5rem;

						&:first-of-type,
						&:nth-of-type(6n+7) {
							padding-left: 0;
						}

						&:not(:last-of-type):not(:nth-of-type(6n+6)) {
							border-right: $border-width solid rgba($border-color, 0.1);
						}

						@include media-breakpoint-down(lg) {
							&:not(:last-of-type):not(:nth-of-type(6n+6)) {
								border-right: 0;
							}

							flex: 3 0 25%;
							max-width: 25%;
							width: 25%;

							&:not(:last-of-type):not(:nth-of-type(4n+4)) {
								border-right: $border-width solid rgba($border-color, 0.1);
							}
						}

						@include media-breakpoint-down(md) {

							&:not(:last-of-type):not(:nth-of-type(6n+6)),
							&:not(:last-of-type):not(:nth-of-type(4n+4)) {
								border-right: 0;
							}

							flex: 4 0 #{(100%/$grid-columns*4)};
							max-width: 100%;
							width: #{(100%/$grid-columns*4)};
							padding: 0 0.25rem;

							&:not(:last-of-type):not(:nth-of-type(3n+3)) {
								border-right: $border-width solid rgba($border-color, 0.1);
							}
						}

						dt,
						dd {
							font-weight: $font-weight-normal;
							font-size: $font-size-sm;
							text-transform: capitalize;
							@extend .text-truncate;
							display: inline-block;
							width: 100%;
						}

						dt {
							color: $black;
						}

						dd {
							color: $danger;
						}
					}
				}
			}
		}
	}
}

.card-rating {

	&,
	&-lg {
		display: flex;
		flex-direction: row;

		p {
			font-size: $font-size-sm;
			text-transform: capitalize;
			color: $gray-500;
			font-weight: $font-weight-light;
			margin-bottom: 0;
			text-align: center;
		}

		.br-wrapper {
			text-align: left;

			&:not(:first-of-type) {
				margin-left: 30px;
			}

			.br-widget {
				height: 16px;
				display: flex;
				align-items: flex-start;
				justify-content: center;

				.br-current-rating {
					display: inline-block;
					color: $gray-400;
					font-size: 0.75rem;
					margin-left: 6px;
				}

				a {
					font-size: $font-size-sm;
				}
			}
		}

		&.justify-content-start {
			.br-widget {
				justify-content: flex-start !important;
			}
		}

		&.justify-content-end {
			.br-widget {
				justify-content: flex-end !important;
			}
		}

		&.justify-content-center {
			.br-widget {
				justify-content: center !important;
			}
		}
	}

	&-lg {
		.br-wrapper {
			.br-widget {
				height: 24px;

				.br-current-rating {
					font-size: $font-size-base;
				}

				a {
					font-size: $font-size-lg*1.25;
				}
			}
		}
	}
}

.card-user {
	.card {
		&-body {
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;

			.dropdown,
			.dropleft {
				position: absolute;
				top: $card-spacer-y;
				right: $card-spacer-x/2;

				.dropdown-menu {
					transform: translate3d(-140px, 24px, 0) #{'/*!rtl: translate3d(0, 24px, 0)*/'} !important;
				}

				.dropdown-toggle {
					background-color: inherit !important;
					color: $gray-400;
				}
			}
		}

		&-footer {
			padding: 0;
			background-color: inherit;
			@include border-bottom-radius($card-border-radius);
			overflow: hidden;
			display: flex;
			align-items: stretch;
			justify-content: flex-start;

			.user-status {
				background-color: $danger;
				flex: 3 0 #{(100%/$grid-columns*3)};
				width: #{(100%/$grid-columns*3)};
				max-width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				visibility: visible;

				p {
					color: $white;
					padding: 0.4rem 0;
					margin: 0;
					text-transform: capitalize;
					font-weight: $font-weight-bold;
					font-family: inherit;
					font-size: $font-size-sm;
				}

				@include media-breakpoint-down(sm) {
					flex: 5 0 #{(100%/$grid-columns*5)};
					width: #{(100%/$grid-columns*5)};
					max-width: 100%;

					p {
						padding: 0.5rem 0;
					}
				}
			}

			.desc-list {
				flex: 9 0 #{(100%/$grid-columns*9)};
				width: #{(100%/$grid-columns*9)};
				max-width: 100%;
				flex-direction: row;
				margin-left: 15px;

				@include media-breakpoint-down(sm) {
					flex: 7 0 #{(100%/$grid-columns*7)};
					width: #{(100%/$grid-columns*7)};
				}

				dl {
					width: auto;

					&:not(:last-of-type) {
						margin-right: 10px;
					}

					dt {
						margin-right: 0;
					}
				}
			}
		}
	}

	&-verified {
		.card {
			&-footer {
				.user-status {
					visibility: hidden;
				}
			}
		}
	}
}

.user {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	div[class*="user-img"] {
		margin-right: 0.75rem;
	}

	&-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		p {
			color: $gray-700;
			font-family: inherit;
			font-size: $font-size-sm;
			font-weight: $font-weight-light;
			text-transform: capitalize;
			margin: 0;
		}
	}

	&-name {
		font-weight: $font-weight-bold;
		font-size: $font-size-base;
		text-transform: capitalize;
		text-align: left;
		font-family: inherit;
		color: $black;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		img {
			width: 18px;
			height: 18px;
			object-fit: contain;
			object-position: center;
			margin-left: 5px;
		}
	}


	&-date {
		color: $gray-400;
		font-weight: $font-weight-bold;
		font-size: $font-size-sm;
		font-family: inherit;
		text-transform: capitalize;
		text-align: right;
	}
}

.card-map {
	@include border-radius(0 !important);
	overflow: hidden !important;

	img {
		max-height: 250px;
		object-fit: cover;
		object-position: center;
		width: 1005;
		@include border-radius($border-radius-sm*0.75);
	}

	div[class*="map"],
	div[id*="map"] {
		height: 225px;
		width: 100%;
		@include border-radius(0);
	}
}

.card-reviews {

	// overflow: hidden !important;
	.card {
		&-header {
			background-color: inherit !important;
			position: relative;
			padding-bottom: 0 !important;

			.dropdown,
			.dropleft {
				position: absolute;
				top: $card-spacer-y;
				right: $card-spacer-x/2;

				.dropdown-menu {
					transform: translate3d(-140px, 20px, 0) !important;
				}

				.dropdown-toggle {
					background-color: inherit !important;
					color: $gray-400;
				}
			}
		}

		&-footer {
			position: relative;
			background-color: inherit;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 0;

			.btn {
				flex: 1;
				@include border-radius(0);
				font-size: $font-size-base;

				&:not(:last-of-type) {
					position: relative;

					&:after {
						content: '';
						background-color: rgba($border-color, 0.1);
						height: 80%;
						width: 1px;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						display: block;
					}
				}
			}
		}
	}

	&-overal {
		@include border-radius(0 !important);
		background-color: inherit;
		margin-bottom: $grid-gutter-width;

		.card {

			&-header,
			&-body {
				padding: 0.25rem 0 !important;
				background-color: inherit;
			}

			&-body {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;

				&>div:not(:last-of-type) {
					margin-right: 1.25rem;
				}
			}
		}

		.overal {
			&-rating {
				flex: 2 0 #{(100%/$grid-columns*2)};
				width: #{(100%/$grid-columns*2)};
				max-width: 100%;
				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: space-between;

				p,
				h5 {
					color: $black;
					font-family: inherit;
					text-transform: capitalize;
					text-align: center;
					margin-bottom: 0;
				}

				p {
					font-size: $font-size-sm;
					font-weight: $font-weight-normal;
				}

				h5 {
					font-size: 3.375rem;
					font-weight: $font-weight-light;
				}

				.card-rating {
					justify-content: center;

					.br-current-rating {
						display: none;
					}
				}

				@include media-breakpoint-down(sm) {
					flex: 3 0 25%;
					width: 25%;
				}
			}

			&-reviews {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				flex: 8 0 #{(100%/$grid-columns*8)};
				width: #{(100%/$grid-columns*8)};
				max-width: 100%;

				@include media-breakpoint-down(sm) {
					flex: 9 0 #{(100%/$grid-columns*9)};
					width: #{(100%/$grid-columns*9)};
				}

			}
		}
	}
}

.card-review {
	@include border-radius(0 !important);
	margin-bottom: 0 !important;
	background-color: inherit;
	position: relative;

	&:not(:last-of-type) {
		border-bottom: $border-width solid rgba($border-color, 0.1);
		margin-bottom: $grid-gutter-width/3 !important;

		.card {
			&-text {
				margin-bottom: $card-spacer-y;
			}
		}
	}

	.card {
		&-reply {
			border-top: $border-width solid rgba($border-color, 0.1);
		}

		&-header,
		&-body {
			padding: 0.25rem 0 !important;
			background-color: inherit !important;
			@include border-radius(0 !important);
		}

		&-header {
			display: flex;
			align-items: flex-start !important;
			justify-content: space-between;
			position: initial;

			.dropdown,
			.dropleft {
				position: absolute;
				top: 5px;
				right: -10px;

				.dropdown-menu {
					transform: translate3d(-140px, 20px, 0) #{'/*!rtl: translate3d(0, 20px, 0)*/'} !important;
				}

				.dropdown-toggle {
					background-color: inherit !important;
					color: $gray-400;
				}
			}
		}

		&-text {
			font-size: $font-size-sm;
			text-align: left;
			color: $gray-700;
			font-family: inherit;
			font-weight: $font-weight-light;

			+.card-reply {
				&:first-of-type {
					margin-top: 1rem;
				}
			}
		}
	}
}

.card-review-single {
	background-color: $white;

	&>.card {

		&-body,
		&-header,
		&-footer {
			background-color: inherit;
		}

		&-header {
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			position: relative;
			border-bottom: $border-width solid rgba($border-color, 0.1);

			.dropdown,
			.dropleft {
				position: absolute;
				top: 50%;
				right: 0;
				margin: 0;
				transform: translateY(-50%);

				.dropdown-menu {
					transform: translate3d(-140px, 20px, 0) #{'/*!rtl: translate3d(0, 20px, 0)*/'} !important;
				}

				.dropdown-toggle {
					background-color: inherit !important;
					color: $gray-400;
				}
			}
		}

		&-body {
			border-bottom: $border-width solid rgba($border-color, 0.1);
		}

		&-text {
			color: $gray-600;
		}

		&-footer {
			@include border-bottom-radius($card-border-radius);
		}

		&-header,
		&-footer,
		&-body {
			padding-right: 0;
			padding-left: 0;
			margin-right: 1.25rem;
			margin-left: 1.25rem;
		}
	}

	.card {

		&-subtitle,
		&-title {
			margin: 0;
		}

		&-review {
			padding-right: 0 !important;

			.card-header {

				.dropdown,
				.dropleft {
					top: 0;
					right: 0;
					transform: translateY(0);
				}
			}
		}
	}
}


.card-reply {
	@include border-radius(0 !important);
	position: initial !important;
	background-color: inherit;

	.card {

		&-header,
		&-body {
			background-color: inherit;
			padding: 0;
		}
	}

	.reply {
		margin-left: 5rem;
		padding: 1rem 0;
		position: relative;

		&:not(:last-of-type) {
			border-bottom: $border-width solid rgba($border-color, 0.1);
		}

		.dropdown,
		.dropleft {
			position: absolute;
			top: 15%;
			right: 0;
			transform: translateY(-15%);

			.dropdown-menu {
				transform: translate3d(-140px, 20px, 0) #{'/*!rtl: translate3d(0, 20px, 0)*/'} !important;
			}

			.dropdown-toggle {
				background-color: inherit !important;
				color: $gray-400;
			}
		}

		&-title,
		&-content {
			text-align: left;
			font-family: inherit;
			margin: 0;
		}

		&-title {
			color: $black;
			font-size: $font-size-base;
			font-weight: $font-weight-normal;
			text-transform: capitalize;
		}

		&-content {
			color: $gray-600;
			font-size: $font-size-sm;
			font-weight: $font-weight-light;
			max-height: 100px;
			overflow-y: scroll;
			@extend .customized-scroll;
		}

		@include media-breakpoint-down(sm) {
			margin-left: 3rem;

			&-content {
				max-height: 200px;
			}
		}
	}
}

.single-progress {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 50%;

	span {
		color: $black;
		font-size: $font-size-sm;
		text-transform: capitalize;
		font-family: inherit;
		font-weight: $font-weight-light;
	}

	.progress {
		flex: 5;
		min-width: 100px;
		max-width: 100px;
		height: 10px;
	}

	&>* {
		&:nth-child(1) {
			flex: 1;
		}

		&:nth-child(2) {
			flex: 7;
		}

		&:nth-child(3) {
			flex: 3;
		}

		&:not(:last-child) {
			margin-right: 0.5rem;
		}
	}

	@include media-breakpoint-down(md) {
		width: 75%;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.categories-list {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	span {
		color: $gray-600;
		margin-bottom: 0;
		font-size: $font-size-sm;
		text-transform: capitalize;
		max-width: 40%;
		@extend .text-truncate;

		&:before {
			margin: 0 0.2em;
			color: inherit;
			font-size: $font-size-sm;
		}

		&:not(:nth-of-type(1)):not(:nth-of-type(2)) {
			&:before {
				content: '-';
			}
		}

		&:nth-of-type(2) {
			&:before {
				content: '/';
			}
		}
	}
}

.logos-list {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	img {
		min-width: 40px;
		width: auto;
		height: auto;
		object-fit: contain;
		background-color: transparent;
		margin-bottom: 0.5rem;

		&:not(:last-of-type) {
			margin-right: 1rem;
		}
	}
}

.card-working-hours {
	overflow: hidden !important;

	.card {

		&-header,
		&-body {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		&-header {
			background-color: $primary !important;
			@include border-radius(0 !important);
			padding: 0.5625rem 1.5rem !important;
		}

		&-body {
			background-color: $primary-dark;
			padding: 0.625rem 0.75rem;

			p {
				color: $white;
				font-family: inherit;
				font-weight: $font-weight-normal;
				font-size: $font-size-sm;
				text-align: center;
				text-transform: capitalize;
				margin-bottom: 0;
			}
		}

		&-title {
			color: $white;
			text-align: center;
			font-family: inherit;
			font-weight: $font-weight-normal;
			font-size: $font-size-sm;
			text-transform: capitalize;
			margin-bottom: 0 !important;
		}
	}
}

.card-message {
	&-horizontal {
		position: relative;
		background-color: $white;

		&>a {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		a {
			z-index: 2;
		}

		.user {
			&-info {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
		}

		.card {
			background-color: inherit;

			&-title {
				@extend .my-2;
				font-weight: $font-weight-normal;
				text-transform: capitalize;
			}

			&-text {
				@include text-truncate();
				color: $gray-400;
			}
		}
	}

	&-unread {
		background-color: lighten($black, 80%);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			background-color: $danger;
			width: 0.75em;
			@include border-left-radius($card-border-radius);
		}
	}

	&-single {
		.card {
			&-header {
				.dropdown-menu {
					transform: translate3d(-90%, 24px, 0) #{'/*!rtl: translate3d(0, 24px, 0px)*/'} !important;
				}
			}

			&-body {
				position: relative;
				padding-top: 0;
				padding-bottom: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 50%;
					width: calc(100% - 2.5rem);
					height: 4em; // equivelant to .messages-container padding-top
					transform: translateX(-50%);
					background: linear-gradient(to bottom, $gray-200, rgba($gray-200, 0));
					display: block;
					z-index: $zindex-dropdown - 100;
				}
			}

			&-footer {
				form {
					.form-row {
						margin: 0;

						&>div[class*="col-"] {
							&:first-of-type {
								padding-left: 0;
							}

							&:last-of-type {
								padding-right: 0;
							}
						}
					}
				}

				.input-group {
					textarea {
						min-height: 45px;
					}

					&-append {
						background-color: $input-bg;
						margin-left: 0;
						@include border-right-radius($input-border-radius);

						button {
							padding-top: 0;
							padding-bottom: 0;
							display: flex;
							align-items: center;
							justify-content: center;

							i.material-icons {
								font-size: $font-size-lg + 0.25;
							}
						}
					}
				}

				.custom-file {
					&-label {
						text-transform: none;
						height: 100%;
						@extend .text-truncate;
						font-size: $font-size-sm;
						display: inline-block;
						margin-top: 10px;
					}
				}
			}
		}
	}
}

.messages {
	&-container {
		@extend .customized-scroll;
		min-height: 500px;
		max-height: 500px;
		overflow-y: scroll;
		background-color: $gray-200;
		padding: 4em 0.75rem;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
		flex: 1 1 100%;
		width: 100%;
		max-width: 100%;
	}

	&-single {
		flex: 1 1 100%;
		width: 100%;
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}

		.message {
			background-color: $white;
			max-width: 75%;
			height: 100%;
			padding: $card-spacer-y/2 $card-spacer-x/2;
			@include border-radius(0 $card-border-radius $card-border-radius);
			@include box-shadow($box-shadow-sm);
			position: relative;

			&:after {
				content: '';
				position: absolute;
				top: -5px;
				left: 0;
				width: 0;
				height: 0;
				border: 5px solid transparent;
				border-bottom-color: $white;
				border-top: 0;
				border-left: 0;
			}

			&-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&-body {
				padding-top: $card-spacer-y/2;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;

				.card-text {
					font-size: $font-size-sm;
					color: $gray-500;
					text-align: left;
					white-space: pre-line;

					&:not(:last-of-type) {
						margin-bottom: 0.5rem;
					}
				}

				.user {
					&>div[class*="user-img"] {
						margin-right: $card-spacer-x/2;
					}
				}

				a {
					color: $danger;
					font-size: $font-size-sm;
					font-weight: $font-weight-normal;
					margin-bottom: 1rem;

					&:not(:last-of-type) {
						margin-right: 1rem;
					}
				}
			}

			&-date {
				font-family: inherit;
				font-weight: $font-weight-light;
				color: $gray-400;
			}

			@include media-breakpoint-down(md) {
				max-width: 90%;
			}
		}

		&.justify-content-end {
			.message {
				@include border-radius($card-border-radius 0 $card-border-radius $card-border-radius);

				&:after {
					content: '';
					position: absolute;
					top: -5px;
					left: initial;
					right: 0;
					width: 0;
					height: 0;
					border: 5px solid transparent;
					border-bottom-color: $white;
					border-top: 0;
					border-right: 0;
				}
			}
		}
	}
}

.card-price {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	p {
		font-family: inherit;
		font-size: $font-size-sm;
		font-weight: $font-weight-normal;
		color: $gray-400;
		text-align: left;
		margin-bottom: 5px;
		text-transform: capitalize;

		&.price {
			margin-bottom: 0;
			font-size: $font-size-sm+0.2;
			color: $danger;
			text-transform: uppercase;
		}
	}
}

//*********************************  services cards  *********************************//
//* .card-service-vertical used in services-listing.html page
//* .card-service-horizontal used in service-manager-listing.html page
//* .card-service-single used in service-single.html page

.card-service {

	// common style for three service cards
	&-horizontal,
	&-vertical,
	&-single {
		// margin-bottom: $grid-gutter-width;
		position: relative;

		.card {
			&-body {
				padding: 0.9375rem;

				.card-info>*:not(.categories-list) {
					margin-bottom: 4px !important;
				}

				&>div {
					&:not(.dropleft):not(.dropdown) {
						padding: 0.625rem 0;
					}

					&:not(.dropleft):not(.dropdown):not(:last-of-type) {
						border-bottom: $border-width solid rgba($border-color, 0.1)
					}
				}

				@include media-breakpoint-down(md) {
					padding: 0.9375rem;
				}

				.dropdown,
				.dropleft {
					position: absolute;
					top: 1.2rem;
					right: 0.3rem;
					margin: 0;
					border-bottom: 0 !important;

					.dropdown-toggle {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						background-color: transparent;
						@include border-radius(0);
						padding: 0;
						margin-left: 5px;
						border: 0;
						font-weight: $font-weight-light;
						color: $gray-400;
						text-transform: lowercase;

						&:focus,
						&:active,
						&:hover,
						&[aria-expanded="true"] {
							background-color: transparent !important;
							color: $gray-400 !important;
						}

						&:before,
						&:after {
							content: none;
						}
					}

					.dropdown-menu {
						transform: translate3d(-140px, 20px, 0) #{"/*rtl: translate3d(0, 24px, 0)*/"} !important;
					}

					&-item {
						&:not(.rtl) {
							text-align: left;
							text-transform: capitalize;
						}
					}
				}
			}

			&-title {
				display: inline-block;
				line-height: 20px;
				font-size: $font-size-base;
				font-weight: $font-weight-bold;
				word-break: break-all;
			}

			&-subtitle {
				font-family: inherit;
				font-weight: $font-weight-normal;
				font-size: $font-size-sm+0.1;
				margin-top: 4px;
			}

			&-rating {
				.br-wrapper {
					text-align: left;

					.br-widget {
						height: 16px;
						align-items: center;
						justify-content: start;

						a {
							font-size: $font-size-sm;
						}
					}
				}
			}

			&-img,
			&-slider {
				position: relative;
				background-color: $primary-dark;

				&>a {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: $zindex-dropdown - 7;
				}

				.featured {
					display: none;
					background-color: $danger;
					position: absolute;
					bottom: 0;
					left: 0;
					align-items: center;
					justify-content: center;
					color: $white;
					font-family: inherit;
					font-weight: $font-weight-bold;
					font-size: $font-size-sm;
					@include border-radius(0 $card-border-radius 0 $card-border-radius);
					margin-bottom: 0;
					padding: 0.3125rem 0.75rem;
					text-transform: capitalize;
					z-index: 1;

					@include media-breakpoint-down(md) {
						font-size: $font-size-lg;
					}

				}

				.card-status {
					display: none;
					background-color: rgba($black, 0.7);
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					align-items: center;
					justify-content: center;
					flex-direction: column;

					p {
						font-family: inherit;
						font-weight: $font-weight-bold;
						font-size: $font-size-sm;
						color: $white;
						text-transform: capitalize;
						margin-bottom: 0;

						&:not(:last-of-type) {
							margin-bottom: 10px;
						}

						@include media-breakpoint-down(md) {
							font-size: $font-size-lg;
						}
					}

				}

				.inactive {
					display: none;
				}

				.unpublished {
					display: none;
				}
			}
		}

		.user {
			&-name {
				font-weight: $font-weight-normal;
				color: black;
			}
		}

		.desc-list {
			dl {
				margin-bottom: 0.3125rem;
			}
		}
	}

	&-vertical,
	&-single {
		height: 100%;

		.card {
			&-img {
				overflow: hidden;

				img {
					object-fit: contain;
					object-position: center;
				}

				.featured {
					border-bottom-left-radius: 0;
					@include border-radius(0 $card-border-radius 0 0);
				}
			}

			&-footer {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				background-color: inherit;
				padding-top: 0.75rem;
				padding-bottom: 0.75rem;

				a.btn {
					flex: 1;
					padding: 0;

					&:not(:last-of-type) {
						position: relative;

						&:after {
							content: '';
							position: absolute;
							height: 50%;
							width: $border-width;
							background-color: rgba($border-color, 0.1);
							right: 0;
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}

				&:before {
					content: '';
					width: 94%;
					height: $border-width;
					background-color: rgba($border-color, 0.1);
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}

	// horizontal service card
	&-horizontal {
		flex-direction: row;
		// max-height: 300px;
		min-height: 150px;

		.card {
			&-title {
				font-size: $font-size-lg;
			}

			&-img {
				overflow: hidden;
				@include border-radius($card-border-radius 0 0 $card-border-radius !important);
				flex: 3 0 25%;
				width: 25%;
				max-width: 25%;
				display: flex;
				align-items: center;
				justify-content: center;

				&-left {
					max-height: 200px;
					min-height: 200px;
				}

				.featured {
					@include border-radius(0 0 0 $card-border-radius);
				}

				.card-status {
					@include border-left-radius($card-border-radius);
				}

				@include media-breakpoint-down(md) {
					flex: 12 0 100%;
					width: 100%;
					max-width: 100%;
					@include border-radius($card-border-radius $card-border-radius 0 0 !important);

					.featured {
						@include border-radius(0 $card-border-radius 0 0);
					}
				}
			}

			&-subtitle {
				font-size: $font-size-sm;
				margin-top: 0;
				margin-bottom: 0;
				@extend .customized-scroll;
				max-height: 65px;
				min-height: 22px;
			}

			&-title {
				@include media-breakpoint-down(md) {
					padding-right: 20px;
				}
			}

			&-body {
				display: flex;
				flex-direction: row;
				align-items: stretch;
				justify-content: flex-start;
				flex-wrap: wrap;
				padding-bottom: 0;
				position: relative;
				flex: 9 0 #{(100%/$grid-columns*9)};
				width: #{(100%/$grid-columns*9)};
				max-width: 100%;
				padding: 0.9375rem 1.875rem;
				@include border-right-radius($card-border-radius);

				@include media-breakpoint-down(md) {
					flex: 12 0 100%;
					width: 100%;
					max-width: 100%;
					padding: 0.9375rem;
				}

				&>div {
					&:not(:last-of-type) {
						border-bottom: $border-width solid rgba($border-color, 0.1);
					}
				}

				.card-info {
					flex: 9 0 #{(100%/$grid-columns*9)};
					width: #{(100%/$grid-columns*9)};
					max-width: 100%;
					padding-top: 0 !important;

					@include media-breakpoint-down(lg) {
						flex: 7 0 #{(100%/$grid-columns*7)};
						width: #{(100%/$grid-columns*7)};
						max-width: 100%;
					}

					@include media-breakpoint-down(md) {
						flex: 12 0 100%;
						width: 100%;
						max-width: 100%;
						border-bottom: 0 !important;
					}
				}

				.card-footer {
					padding-bottom: 0 !important;
				}

				.badges-group {
					flex-wrap: nowrap;
					overflow-x: scroll;
					padding-right: 1.875rem;
					max-width: 100%;
					@extend .customized-scroll;

					.badge {
						margin-bottom: 0;
					}

					&:after {
						content: '';
						position: absolute;
						bottom: 25px;
						right: 1.875rem;
						height: 10%;
						width: 15%;
						background: linear-gradient(to right, transparent, $white);
					}
				}
			}

			&-price {
				flex: 3 0 #{(100%/$grid-columns*3)};
				width: #{(100%/$grid-columns*3)};
				max-width: 100%;
				align-items: flex-end;
				padding-top: 0 !important;

				p {
					text-align: right;
				}

				@include media-breakpoint-down(lg) {
					flex: 4 0 #{(100%/$grid-columns*4)};
					width: #{(100%/$grid-columns*4)};
					max-width: 100%;
				}

				@include media-breakpoint-down(md) {
					flex: 12 0 100%;
					width: 100%;
					max-width: 100%;
					align-items: flex-start;
				}
			}

			&-footer {
				display: flex;
				align-items: center;
				justify-content: stretch;
				flex: 12 0 100%;
				width: 100%;
				background-color: inherit;

				.user {
					padding: 0;
					width: 100%;

					&-info {

						&,
						& a {
							@extend .text-truncate;
							width: 100%;
						}
					}
				}

				@include media-breakpoint-up(lg) {
					padding: 1rem 0;
				}

				@include media-breakpoint-down(md) {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
			max-height: initial;
		}
	}

	// vertical and single service cards common style
	&-vertical,
	&-single {
		overflow: initial;
		flex-direction: column;

		.card {

			&-fav,
			&-share {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 10px;
				width: 30px;
				height: 30px;
				background-color: $primary;
				@include border-radius($card-border-radius);
				z-index: $zindex-dropdown - 2;
				transition: $transition-base;
				transform: scale(1.1);

				@include box-shadow($box-shadow-lg);

				&:active {
					@include box-shadow($box-shadow);

					transition: $transition-base;
					transform: scale(1.05);
				}

				i {
					font-size: $font-size-sm;
					color: $white;
				}
			}

			&-status {
				@include border-top-radius($card-border-radius);
			}

			&-fav {
				right: 10px;
			}

			&-share {
				left: 10px;
			}
		}
	}

	// single service card
	&-single {
		.card {
			&-body {
				padding-left: 1.375rem;
				padding-right: 1.875rem;
				display: flex;
				flex-direction: row;
				position: relative;

				&>div:not(:last-of-type) {
					border-bottom: 0 !important;
				}

				.card-info {
					flex: 10 0 #{(100%/$grid-columns*10)};
					width: #{(100%/$grid-columns*10)};
					max-width: 100%;

					@include media-breakpoint-down(md) {
						flex: 8 0 #{(100%/$grid-columns*8)};
						width: #{(100%/$grid-columns*8)};

					}
				}
			}

			&-img,
			&-slider {
				min-height: 450px;
				max-height: 450px;
				width: 100%;
				overflow: hidden;

				.card-img-top,
				video,
				iframe {
					min-height: 450px;
					max-height: 450px;
					width: 100%;
					object-fit: contain;
				}
			}

			&-info {
				width: 100%;
			}

			&-price {
				flex: 2 0 #{(100%/$grid-columns*2)};
				width: #{(100%/$grid-columns*2)};
				max-width: 100%;
				align-items: flex-end;

				.price {
					font-weight: $font-weight-bold;
					font-size: 1.25rem !important;
				}

				p,
				strong {
					text-align: right;
					margin: 0 !important;
				}

				@include media-breakpoint-down(md) {
					flex: 4 0 #{(100%/$grid-columns*4)};
					width: #{(100%/$grid-columns*4)};
				}
			}

			&-title {
				font-size: 1.25rem;
				font-weight: $font-weight-bold;
			}

			&-fav,
			&-share {
				width: 40px;
				height: 40px;
				top: 20px;

				i {
					font-size: $font-size-base;
				}
			}

			&-fav {
				right: 20px;
			}

			&-share {
				left: 20px;
			}
		}
	}

	// favourite utility class
	&-favourite {
		.card {
			&-fav {
				i {
					@extend .fas;
				}
			}
		}
	}

	// inactive service card status
	&-inactive {
		.card {

			&-img,
			&-slider {
				.card-status {
					display: flex;

					.inactive {
						display: inline-block;
					}
				}
			}
		}
	}

	// unpublished service card status
	&-unpublished {
		.card {

			&-img,
			&-slider {
				.card-status {
					display: flex;

					.unpublished {
						display: inline-block;
					}
				}
			}
		}
	}

	// featured service card featured
	&-featured {
		.card {

			&-img,
			&-slider {
				.featured {
					display: flex;
				}
			}
		}
	}
}

.card-job {
	&-vertical {
		@extend .card-service-vertical;

		.featured {
			visibility: hidden;
			color: $cyan;
			font-family: inherit;
			font-weight: $font-weight-bold;
			font-size: $font-size-sm;
			text-transform: capitalize;
			margin-bottom: 0;
		}
	}

	&-featured {
		.featured {
			visibility: visible;
		}
	}
}

.card-collapse {
	overflow: hidden !important;

	.accordion,
	.card-header,
	.card-body,
	.card {
		background-color: inherit !important;
	}

	.accordion {
		overflow: hidden;

		.card {
			@include border-radius(0);

			.collapse.show {
				@extend .pb-3;
			}

			&-header {
				padding: 0;

				h6 {
					button {
						text-transform: capitalize;
						font-weight: $font-weight-bold;
						background-color: inherit;
						width: 100% !important;
						position: relative;
						text-align: left;
						color: $secondary-light;
						padding: 1rem 0;
						@include border-radius(0);
						border: 0 !important;
						display: flex;
						align-items: center;
						justify-content: flex-start;

						i {
							margin-right: 10px;
							font-size: 1.75rem;
						}

						&:hover {
							color: $secondary-light;
						}

						&:before {
							@include dropdown-arrow($secondary-light);
							background-position-x: center !important;
							width: 23px;
							height: 10px;
							position: absolute;
							right: 0 !important;
							top: 50%;
							left: initial !important;
							transform: translateY(-50%) rotate(0deg) !important;
							transition: $transition-base;
						}

						&[aria-expanded="true"] {
							&:before {
								background-position-x: center !important;
								transform: translateY(-50%) rotate(-180deg) !important;
							}
						}
					}
				}
			}
		}
	}

	&[class*="bg-"] {
		.accordion {
			.card {
				&-text {
					color: $white !important;
				}

				&-header {
					h6 {
						button {
							color: $white !important;

							&:before {
								@include dropdown-arrow($white);
								background-position-x: center !important;
								width: 23px;
								height: 10px;
								position: absolute;
								right: 0 !important;
								top: 50%;
								left: initial !important;
								transform: translateY(-50%) rotate(0deg) !important;
								transition: $transition-base;
							}

							&[aria-expanded="true"] {
								&:before {
									background-position-x: center !important;
									transform: translateY(-50%) rotate(-180deg) !important;
								}
							}
						}
					}
				}
			}
		}
	}
}

.card-banner,
.card-category {
	position: relative;
	overflow: hidden !important;

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.65);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}

	.card {
		&-img {
			object-fit: cover;
			object-position: center;
			min-height: inherit;
			transform: scale(1.3);
			transition: $transition-base-slow;
		}

		&-title {

			&,
			&>a,
			button.btn {
				font-family: inherit;
				font-weight: $font-weight-bold;
				font-size: 1.5rem;
				text-transform: uppercase;
				color: $white;
				width: 100%;
				white-space: pre-line;

				@include media-breakpoint-down(sm) {
					font-size: 32px;
				}
			}
		}

		&-img-overlay {
			z-index: 11;
		}
	}

	&:hover {
		.card-img {
			transform: scale(1);
		}
	}
}

.card-banner {
	min-height: 280px;
	max-height: 280px;

	.card {
		&-img-overlay {
			align-items: center;
			justify-content: center;
			width: 100%;
			margin: 0 auto;
			text-align: center;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}

			a.btn {
				@extend .px-3;
				font-weight: $font-weight-normal;
			}
		}
	}
}

.card-category {
	min-height: 110px;
	max-height: 110px;

	.card {
		&-img-overlay {
			align-items: stretch;
			justify-content: center;
		}

		&-title {
			margin-bottom: 0;

			a,
			button {
				width: 100%;
				font-size: 1.5rem;
				font-weight: $font-weight-bold !important;
				text-align: left;
			}
		}
	}
}


//*********************************  card service  *********************************//
//* used in RFPs posting pages (add, edit)
.card-date-range {
	background-color: lighten($gray, 15%);
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: flex-start;
	overflow: hidden !important;
	position: relative;
	transition: $transition-base;

	&[data-initialized-timeline="false"] {
		display: none;
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;
	}

	&:not(:last-of-type) {
		margin-bottom: $grid-gutter-width/2;
	}

	.card-subtitle {
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		margin-bottom: $grid-gutter-width/2;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;

		@include media-breakpoint-down(lg) {
			margin-bottom: $grid-gutter-width;
		}

		span {
			background-color: rgba($black, 0.11);
			@include border-radius($border-radius-sm/3);
			padding: 0.1rem 0.3rem;
			margin-left: 0.4rem;
			font-family: inherit;
			font-weight: $font-weight-normal;
			color: rgba($black, 0.56);
			font-size: $font-size-sm;
			display: inline-block;
			@extend .text-truncate;
			width: fit-content;

			@include media-breakpoint-down(sm) {
				font-size: 0.75rem;
			}

			@include media-breakpoint-only(md) {
				margin-left: 0;
			}

			@include media-breakpoint-only(lg) {
				margin-left: 0;
			}

			@include media-breakpoint-up(xl) {
				max-width: 50%;
			}
		}
	}

	&-addNew {
		float: right;
		color: $danger;
		text-align: right;
		font-weight: $font-weight-bold;
		font-size: $font-size-sm;
		margin-top: $grid-gutter-width/2;
		text-transform: uppercase;
		cursor: pointer;
		transition: $transition-base;
	}

	&-remove {
		position: absolute;
		right: 5px;
		top: 5px;
		color: $danger;
		cursor: pointer;
		font-size: $font-size-sm;
		z-index: $zindex-dropdown;
		@include border-radius(50%);
		transition: $transition-base;
		transform: scale(1);

		&:hover {
			background-color: $danger;
			color: $white;
			transform: scale(1.3);

			@include box-shadow($box-shadow-sm);
		}

		&:active,
		&:focus {
			transform: scale(1.1);
		}
	}
}





/*
* chart cards
*/

.card-chart {
	.chart {
		&-container {
			@include size($width: 100%);
		}
	}
}

.chart-widget {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: stretch;
	margin-bottom: $grid-gutter-width/2;

	h3 {
		font-size: $font-size-lg*2.5;
		font-weight: $font-weight-bolder;
		margin-bottom: 0;
		line-height: 40px;

		&,
		+span {
			font-family: inherit;
		}

		+span {
			margin-left: $grid-gutter-width/2;
			color: $gray-500;
			text-transform: capitalize;
			font-size: $font-size-base;
		}
	}

	p {
		font-size: $font-size-base;
		font-weight: $font-weight-bold;
		text-transform: capitalize;
		color: inherit;
		font-family: inherit;
	}
}

.card-payments,
.card-earnings {
	.card {
		&-body {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			@include media-breakpoint-down(md) {
				flex-wrap: wrap;
			}

			p,
			.price {
				text-align: center;
			}

			.price {
				font-weight: $font-weight-bold;
				color: $danger;
				font-size: $font-size-base;
			}

			&>*:not(a) {
				flex: 3 0 #{(100%/$grid-columns*3)};
				width: #{(100%/$grid-columns*3)};
				max-width: 100%;
				margin: 0;

				@include media-breakpoint-down(md) {
					flex: 4 0 #{(100%/$grid-columns*4)};
					width: #{(100%/$grid-columns*4)};

					&:nth-child(2) {
						text-align: left !important;
					}

					&:last-child {
						text-align: right !important;
					}
				}
			}

		}

		&-title {
			font-weight: $font-weight-bolder;
			text-transform: capitalize;
			font-size: $font-size-base;

			@include media-breakpoint-down(md) {
				flex: 12 0 #{(100%/$grid-columns*12)};
				width: #{(100%/$grid-columns*12)};
				margin-bottom: $card-spacer-y / 2;
			}
		}
	}
}

.card-payments {
	.card {
		&-body {
			position: relative;

			&>a {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				@include border-radius($card-border-radius);
			}
		}
	}
}


.card-settings {
	position: relative;

	&>a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		display: block;
		z-index: 10;
	}

	.card {
		&-header {
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
		}

		&-title {
			margin-bottom: 0;
			font-size: $font-size-base;
			font-family: inherit;
			font-weight: $font-weight-bolder;
			text-transform: capitalize;
			position: relative;
			width: 100%;

			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				background-image: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 477.175 477.175' style='enable-background:new 0 0 477.175 477.175;' xml:space='preserve' width='512px' height='512px'%3E%3Cg%3E%3Cpath d='M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5 c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z ' fill='#{$danger}'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A"), "#", "%23");
				width: 16px;
				height: 30px;
				background-size: 16px 30px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
}

.service-card-single {
	object-fit: contain
}


// IE general fix
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.overlay__nav--sub ul {
		height :auto!important;
	}
	.overlay.open {
		overflow: hidden;
	}
	.overlay__nav--dropdowns .dropdown .dropdown-toggle::after, .overlay__nav--dropdowns .dropleft .dropdown-toggle::after {
		top: 5px;
	}
	.navbar-dropdown .dropdown-toggle::after {
		top: 13px;
		right : -5px;
	}

	.footer-social .list-inline-item {
		min-height: unset;
		height: 30px;
	}

	.selectize-input,.form-float-label-group textarea,
	.selectize-select .selectize-dropdown{
		// scrollbar-base-color : #efefef;
		// scrollbar-face-color : #666666;
		// scrollbar-3dlight-color : #666666;
		// scrollbar-highlight-color : #666666;
		// scrollbar-track-color : #2f2c29;
		// scrollbar-arrow-color : #2f2c29;
		// scrollbar-shadow-color : #666666;
		// scrollbar-dark-shadow-color : #666666;
		-ms-overflow-style: none;
	}
}

// edge general fix
@supports (-ms-ime-align: auto) {	
	.card-description {
		.description-content.audioContent {
			-ms-overflow-style: none!important;
		}
	}
}
