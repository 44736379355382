.inner {
	&-wrapper {
		background-color: rgba($gray, 0.5);
		padding: 120px 0 75px 0;
		min-height: calc(100vh - 163px);
	}

	&-header {
		margin-bottom: $grid-gutter-width*0.75;

		&>div[class*="col-md"] {
			display: flex;
			align-items: center;
		}
	}

	&-title {

		h3,
		h6,
		p {
			// title and subtitles
			text-align: left;
			margin-bottom: 0;
		}

		h3,
		h6 {
			font-weight: $font-weight-bold;

			@include media-breakpoint-down(md) {
				margin-bottom: $grid-gutter-width / 8;
			}
		}

		h3 {
			text-transform: uppercase;
			color: $black;
		}

		h6 {
			text-transform: capitalize;
			color: $gray-500;
		}

		p {
			color: $dark;
			font-size: $font-size-base;
			font-weight: $font-weight-normal;
			@include media-breakpoint-up(md) {
				margin-top: $grid-gutter-width / 4;
			}
		}
	}

	&-btns {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		a.btn {
			width: 50%;

			&:not(:first-of-type) {
				margin-left: 10px;
			}
		}

		@include media-breakpoint-down(md) {
			margin-bottom: $grid-gutter-width*0.75;
			justify-content: center;

			a.btn {
				width: 100% !important;
			}
		}
	}

	&-filter-select {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
		margin-top: $grid-gutter-width*0.75;
	}

	&-filter-search {
		&>.card {
			margin-top: $grid-gutter-width*0.75;
		}
	}

	&-noResult {
		margin: $grid-gutter-width*0.75 0;

		h5 {
			font-family: inherit;
			font-weight: $font-weight-bold;
			font-size: 22px;
			text-align: left;
			text-transform: uppercase;
			color: $primary;
			margin-bottom: 0.75rem;
		}

		p {
			color: rgba($black, 0.56);
			font-family: inherit;
			font-weight: $font-weight-normal;
			font-size: 0.875rem;
		}
	}

	&-select {
		&-title {
			font-family: inherit;
			font-weight: $font-weight-bold;
			color: $gray-600;
			text-transform: capitalize;
			margin-bottom: 0;
		}
		&-filter{
			padding-right: 0;
			padding-left: 0;
		}

		&-single {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			&:not(:last-of-type) {
				margin-right: 20px;

				@include media-breakpoint-down(sm) {
					margin-right: 0;
					margin-bottom: $grid-gutter-width*0.75;
				}
			}

			p {
				color: $gray-800;
				font-size: $font-size-base;
				text-transform: capitalize;
				font-weight: $font-weight-light;
				text-align: left;
				margin: 0;
			}

			.dropdown,
			.dropleft {
				button {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background-color: transparent;
					@include border-radius(0);
					padding: 0;
					margin-left: 5px;
					border: 0;
					font-weight: $font-weight-light;
					color: $gray-800;
					text-transform: lowercase;

					&:focus,
					&:active,
					&:hover,
					&[aria-expanded="true"] {
						background-color: transparent !important;
						color: $gray-800 !important;
					}

					&:before {
						content: none;
					}

					&:after {
						@include dropdown-arrow($primary)
					}
				}
				.dropdown-menu {
					transform: translate3d(-84px, 26px, 0) #{'/*!rtl: translate3d(0, 26px, 0)*/'} !important;
				}

				&-item {
					color: $black;

					&:not(.rtl) {
						text-align: left;
						text-transform: capitalize;
					}

					&:focus {
						color: $white;
					}

				}
			}
		}
	}
}