/*
** 		   fonts
** =======================
*/
@import url('https://fonts.googleapis.com/css?family=Cairo:200,300,400,600,700,900|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=arabic');


/*
** 		 bootstrap
** =======================
*/
@import 'bootstrap';


/*
** 		   icons
** =======================
*/
@import 'icons/font-awesome/fontawesome';
@import 'icons/material-icons/material_icons';


/*
** 		   helpers
** =======================
*/
@import 'helpers/mixins';
@import 'helpers/helper_classes';
@import 'helpers/animate';


/*
** 		   libs
** =======================
*/
@import 'libs/swiper/swiper';
@import 'libs/selectize/selectize';
@import 'libs/bar-rating/bar_rating';
@import 'libs/flat-pickr/flatpicker';
@import 'libs/intel-tel-input/intl_tel_input';
@import 'libs/tinycme/content';
@import 'libs/tinycme/skin';


/*
** 		   sections
** =======================
*/
@import 'sections/preloader';
@import 'sections/overlayMenu';
@import 'sections/inner_pages';
@import 'sections/footer';

/*
** 		   compoents
** =======================
*/
@import 'components/form';
@import 'components/accordion';
@import 'components/pagination';
@import 'components/alerts';
@import 'components/breadcrumb';
@import 'components/list_group';
@import 'components/file_preview';
@import 'components/steps_nav';
@import 'components/cards';

/*
** 		   pages
** =======================
*/
@import 'pages/index';
@import 'pages/about';
@import 'pages/login_register';
@import 'pages/service';
@import 'pages/info_pages';
@import 'pages/eventsNotifications';

/*
** 	 overwriting style
** =======================
*/
@import 'custom_style';
@import 'helpers/debug';

